/**
 * @generated SignedSource<<e83dba05022fc1d8a66669b691ad0ce1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueTableHideDoneToggle_HideDoneItemsToggle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableHideDoneToggle_HideDoneItemsToggle">;
  readonly " $fragmentType": "src_issueTableHideDoneToggle_HideDoneItemsToggle";
};
export type src_issueTableHideDoneToggle_HideDoneItemsToggle$key = {
  readonly " $data"?: src_issueTableHideDoneToggle_HideDoneItemsToggle$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueTableHideDoneToggle_HideDoneItemsToggle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "src_issueTableHideDoneToggle_HideDoneItemsToggle",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "staticViewInput",
          "variableName": "staticViewInput"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ui_issueTableHideDoneToggle_HideDoneItemsToggle"
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};

(node as any).hash = "e95333f36c81e4bc86fe59f9dcf1304b";

export default node;
