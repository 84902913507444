import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	onboardingStatisticsDescription: {
		id: 'issue-navigator-action-meatball-menu.onboarding-statistics-description',
		defaultMessage: 'You can now create charts from filtered issues for quick and useful insights.',
		description: 'Text for the statistics onboarding popup from meatball menu button spotlight',
	},
	onboardingStatisticsDescriptionIssueTermRefresh: {
		id: 'issue-navigator-action-meatball-menu.onboarding-statistics-description-issue-term-refresh',
		defaultMessage:
			'You can now create charts from filtered work items for quick and useful insights.',
		description: 'Text for the statistics onboarding popup from meatball menu button spotlight',
	},
	tryItOut: {
		id: 'issue-navigator-action-meatball-menu.try-it-out',
		defaultMessage: 'Try it out',
		description: 'Text for the "Try it out" button in the statistics onboarding popup',
	},
	dismiss: {
		id: 'issue-navigator-action-meatball-menu.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Text for the "Dismiss" button in the statistics onboarding popup',
	},
	getWorkInsights: {
		id: 'issue-navigator-action-meatball-menu.get-work-insights',
		defaultMessage: 'Get work insights',
		description: 'Text for the "Get work insights" title in the statistics onboarding popup',
	},
});

export default messages;
