import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	windowTitleMigrationIssueTermRefresh: {
		id: 'project-issue-navigator.window-title-migration-issue-term-refresh',
		defaultMessage: '{projectName} - All work',
		description: 'Window title of the project issue navigator page',
	},
	windowTitleMigration: {
		id: 'project-issue-navigator.window-title-migration',
		defaultMessage: '{projectName} - Issues',
		description: 'Window title of the project issue navigator page',
	},
});

export default messages;
