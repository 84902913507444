import mainGroupByDropdownContentQuery from '@atlassian/jira-relay/src/__generated__/mainGroupByDropdownContentQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const groupByDropdownEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-issue-table-group-by-dropdown" */ './index'),
	),
	getPreloadProps: ({
		nodeId,
		selectedFieldId,
		issueSearchInput,
	}: {
		nodeId: string;
		selectedFieldId?: string | null;
		issueSearchInput?: { jql?: string; filterId?: string };
	}) => ({
		queries: {
			groupByDropdownQuery: {
				parameters: mainGroupByDropdownContentQuery,
				variables: {
					nodeId,
					issueSearchInput,
				},
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
			},
		},
		extraProps: {
			selectedFieldId,
		},
	}),
});
