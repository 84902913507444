/**
 * @generated SignedSource<<188813c08a6b767043dfdfcd6e9e6e61>>
 * @relayHash 4269726e8ba7ceef0720803c06ec9dc3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4da1e676fb4717f68009f63522b10789960d60fc3543044cb90995abbb254a51

import type { ConcreteRequest, Query } from 'relay-runtime';
export type documentTitleQuery$variables = {
  cloudId: string;
  projectKey: string;
};
export type documentTitleQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly __typename: "JiraProject";
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
};
export type documentTitleQuery = {
  response: documentTitleQuery$data;
  variables: documentTitleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "documentTitleQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "documentTitleQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4da1e676fb4717f68009f63522b10789960d60fc3543044cb90995abbb254a51",
    "metadata": {},
    "name": "documentTitleQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "37cf341ecbc6674a0e4301d1a119d06d";

export default node;
