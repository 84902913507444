import React, { Component, type ReactNode, useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button, { type Appearance } from '@atlaskit/button';
import DropdownMenu, { DropdownItemRadioGroup, DropdownItemRadio } from '@atlaskit/dropdown-menu';
import ChevronDown from '@atlaskit/icon/utility/migration/chevron-down';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { IntlShapeV2 as Intl } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	getSupportedFieldForAlias,
	supportedOrderFields,
} from '../../../../../common/constants.tsx';
import { isNonNullish } from '../../../../../common/utils/index.tsx';
import { useOrderByOpenState } from '../../../../../controllers/order-by-open-state/index.tsx';
import { useSortFieldAndDirection } from '../../../../../controllers/sort-field-and-direction-state/index.tsx';
import messages, { messageLookup } from './messages.tsx';

type OrderDropdownProps = {
	isOpen?: boolean;
	className?: string;
	orderField: string | undefined;
	onChange: (newOrder: string, aliases: string[]) => void;
	onFocus: () => void;
	onBlur: () => void;
	intl: Intl;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const kButtonProps: { appearance: Appearance; 'data-testid': any } = {
	appearance: 'subtle',
	'data-testid':
		'issue-navigator.ui.issue-results.detail-view.card-container.order-dropdown.button',
};

const customFieldIdPattern = /^cf\[\d+\]$/;

const DropdownItemRadioWithAnalytics = ComponentWithAnalytics('dropdownItemRadio', {
	onClick: 'clicked',
})(DropdownItemRadio);

// eslint-disable-next-line jira/react/no-class-components
export class OrderDropdown extends Component<OrderDropdownProps> {
	static defaultProps = {
		onChange: noop,
		onFocus: noop,
		onBlur: noop,
	};

	onItemClick(order: string, aliases: string[]) {
		const { onChange, onBlur } = this.props;
		onChange(order, aliases);
		onBlur();
	}

	onOpenChange = ({ isOpen }: { isOpen: boolean } = { isOpen: false }) => {
		if (isOpen) {
			this.props.onFocus();
		} else {
			this.props.onBlur();
		}
	};

	renderItems() {
		const {
			intl: { formatMessage },
			orderField,
		} = this.props;
		return Object.values(supportedOrderFields)
			.filter(isNonNullish)
			.map<ReactNode>(({ jqlTerm, aliases }) => {
				let jqlTermForMessage = jqlTerm;

				if (['issuetype'].includes(jqlTermForMessage)) {
					jqlTermForMessage = `${jqlTerm}${
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? 'IssueTermRefresh'
							: ''
					}`;
				}
				const messageDescriptor = messageLookup[jqlTermForMessage];
				const fieldName = messageDescriptor ? formatMessage(messageDescriptor) : jqlTerm;
				const props = {
					id: jqlTerm,
					onClick: () => {
						this.onItemClick(jqlTerm, aliases);
					},
					isSelected: jqlTerm === orderField || (orderField && aliases.includes(orderField)),
				};
				return (
					/* @ts-expect-error - Type '{ children: string; id: string; onClick: () => void; isSelected: boolean | "" | undefined; key: string; }' is not assignable to type 'Omit<{ children: ReactNode; description?: string | Element | undefined; isDisabled?: boolean | undefined; shouldTitleWrap?: boolean | undefined; shouldDescriptionWrap?: boolean | undefined; ... 5 more ...; testId?: string | undefined; }, keyof WithAnalyticsEventsProps>'. */
					<DropdownItemRadioWithAnalytics key={jqlTerm} {...props}>
						{fieldName}
					</DropdownItemRadioWithAnalytics>
				);
			});
	}

	render() {
		const {
			intl: { formatMessage },
			orderField,
			isOpen,
			className,
		} = this.props;
		let fallbackMessage = '';
		let fieldName = '';

		if (typeof orderField === 'string') {
			const isCustomFieldId = customFieldIdPattern.test(orderField);
			const expectedOrderField =
				supportedOrderFields[getSupportedFieldForAlias(orderField) ?? orderField];
			const fallbackMessageDescriptor = expectedOrderField
				? messageLookup[expectedOrderField.jqlTerm]
				: null;

			if (isCustomFieldId) {
				fallbackMessage = formatMessage(messages.customField);
			} else if (expectedOrderField && fallbackMessageDescriptor) {
				fallbackMessage = formatMessage(fallbackMessageDescriptor);
			} else {
				fieldName = orderField;
			}
		} else {
			fallbackMessage = formatMessage(messages.defaultLabel);
		}

		return (
			<StyledContainer
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={className}
			>
				<DropdownMenu
					isOpen={isOpen}
					onOpenChange={this.onOpenChange}
					testId="issue-navigator.ui.issue-results.detail-view.card-container.order-dropdown"
					trigger={({ triggerRef, ...props }) => (
						<StyledButtonContainer>
							<Button
								{...props}
								appearance="subtle"
								ref={triggerRef}
								iconAfter={<ChevronDown label={formatMessage(messages.defaultLabel)} />}
							>
								{fieldName || fallbackMessage}
							</Button>
						</StyledButtonContainer>
					)}
					shouldFlip
					placement="bottom-start"
				>
					<StyledDropdownMenuContainer>
						<DropdownItemRadioGroup
							id="sort-field-group"
							title={formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.fieldGroupIssueTermRefresh
									: messages.fieldGroup,
							)}
						>
							{this.renderItems()}
						</DropdownItemRadioGroup>
					</StyledDropdownMenuContainer>
				</DropdownMenu>
			</StyledContainer>
		);
	}
}

export const OrderDropdownWithState = () => {
	const [{ isOpen }, { open, close }] = useOrderByOpenState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();

	const [{ field, direction }, { setFieldAndDirection }] = useSortFieldAndDirection();

	const handleOnChange = useCallback(
		(orderField: string, aliases: string[]) => {
			setFieldAndDirection(orderField, direction, aliases);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItemRadio',
			});
			const { fieldType = null } =
				supportedOrderFields[getSupportedFieldForAlias(orderField) ?? orderField] ?? {};

			fireUIAnalytics(analyticsEvent, 'sortFieldDropdownItemRadio', {
				fieldType,
				order: direction,
			});
		},
		[createAnalyticsEvent, direction, setFieldAndDirection],
	);

	return (
		<OrderDropdown
			orderField={field}
			onChange={handleOnChange}
			isOpen={isOpen}
			onFocus={open}
			onBlur={close}
			intl={intl}
		/>
	);
};

export default OrderDropdownWithState;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const StyledButtonContainer = styled.div(`
    button:focus-visible {
        outline-offset: -2px;
    }
`);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledDropdownMenuContainer = styled.div({
	height: '320px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	flexGrow: 1,
	overflow: 'hidden',
});
