import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_StatisticsSidebarQuery$parameters';

export const issueFieldStatisticsStatisticsSidebarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-issue-field-statistics-statistics-sidebar" */ './ui/index'
			),
	),
	getPreloadProps: ({
		cloudId,
		jql,
		view,
		namespace,
	}: {
		cloudId: string;
		jql: string;
		view: string;
		namespace: string;
	}) => ({
		queries: {
			issueFieldStatistics: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					cloudId,
					jql,
					view,
					namespace,
				},
			},
		},
	}),
});
