import debounce from 'lodash/debounce';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, GetState, SetState } from '@atlassian/react-sweet-state';
import { views } from '../../common/constants.tsx';
import {
	type ActionType,
	DEFAULT_FIRST,
	FORCE_FIRST,
	FORCE_LAST,
	type PropType,
	type StoreType,
} from './types.tsx';

type SideEffectUpdateArgs = {
	setState: SetState<StoreType>;
	getState: GetState<StoreType>;
	onChange?: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
	shouldNotifyOnChange: boolean;
	isSelectedByUserInteraction: boolean;
};

const doSideEffectUpdate = ({
	setState,
	getState,
	onChange,
	shouldNotifyOnChange,
	isSelectedByUserInteraction,
}: SideEffectUpdateArgs): void => {
	const { selectedIssue } = getState();
	setState({ _rememoize: Date.now() });
	const key = selectedIssue[1];
	shouldNotifyOnChange && onChange && onChange(key, isSelectedByUserInteraction);
};
const doDebouncedSideEffectUpdate = debounce(doSideEffectUpdate, 600);

const doSideEffectUpdates = (
	shouldDebounce: boolean,
	setState: SetState<StoreType>,
	getState: GetState<StoreType>,
	onChange: ((issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void) | undefined,
	shouldNotifyOnChange: boolean,
	isSelectedByUserInteraction: boolean,
) => {
	if (shouldDebounce) {
		doDebouncedSideEffectUpdate({
			setState,
			getState,
			onChange,
			shouldNotifyOnChange,
			isSelectedByUserInteraction,
		});
	} else {
		doSideEffectUpdate({
			setState,
			getState,
			onChange,
			shouldNotifyOnChange,
			isSelectedByUserInteraction,
		});
	}
};

const setSelectedIssueByKey =
	(
		issueKey: IssueKey,
		shouldDebounce = false,
		shouldNotifyOnChange = true,
		isSelectedByUserInteraction = true,
	): Action<StoreType, PropType> =>
	({ setState, getState }, { onChange, issueKeys }) => {
		if (!issueKey.length) {
			return;
		}
		const index = issueKey.length ? issueKeys?.findIndex((key) => key === issueKey) : -1;

		if (index !== -1) {
			setState({ selectedIssue: [index, issueKey] });
		} else {
			setState({ selectedIssue: [null, issueKey] });
		}

		doSideEffectUpdates(
			shouldDebounce,
			setState,
			getState,
			onChange,
			shouldNotifyOnChange,
			isSelectedByUserInteraction,
		);
	};

const setSelectedIssueByIndex =
	(
		issueIndex: number,
		shouldDebounce = false,
		shouldNotifyOnChange = true,
		isSelectedByUserInteraction = true,
	): Action<StoreType, PropType> =>
	({ setState, getState }, { onChange, issueKeys }) => {
		if (issueIndex >= issueKeys.length) {
			return;
		}
		const key = issueKeys[issueIndex];
		setState({ selectedIssue: [issueIndex, key] });

		doSideEffectUpdates(
			shouldDebounce,
			setState,
			getState,
			onChange,
			shouldNotifyOnChange,
			isSelectedByUserInteraction,
		);
	};

/**
 * Return the position of the currently selected issue in the search results or `null` if the selected issue could not
 * be found.
 */
const getSelectedIssuePosition =
	(): Action<StoreType, PropType, number | null> =>
	(
		{ getState },
		{
			firstIssuePosition,
			lastIssuePosition,
			firstIssueKeyFromNextPage,
			lastIssueKeyFromPreviousPage,
		},
	) => {
		// Incomplete page info from our search results so don't compute the position
		if (firstIssuePosition === null || lastIssuePosition === null) {
			return null;
		}

		const { selectedIssue, nextResetStrategy } = getState();
		const [selectedIssueIndex, selectedIssueKey] = selectedIssue;

		// If the first issue key from next page is selected or if we are forcing selection of the first issue on
		// pagination then we optimistically compute the issue position.
		if (selectedIssueKey === firstIssueKeyFromNextPage || nextResetStrategy === FORCE_FIRST) {
			return lastIssuePosition + 1;
		}
		// If the last issue key from previous page is selected or if we are forcing selection of the last issue on
		// pagination then we optimistically compute the issue position.
		if (selectedIssueKey === lastIssueKeyFromPreviousPage || nextResetStrategy === FORCE_LAST) {
			return firstIssuePosition - 1;
		}
		if (selectedIssueIndex !== null) {
			return selectedIssueIndex + firstIssuePosition;
		}

		return null;
	};

/**
 * Returns true if the currently selected issue is within the page of issues.
 */
const isSelectedIssueOnPage =
	(): Action<StoreType, PropType, boolean> =>
	({ dispatch }, { firstIssuePosition, lastIssuePosition }) => {
		const selectedIssuePosition = dispatch(getSelectedIssuePosition());
		return (
			selectedIssuePosition !== null &&
			firstIssuePosition !== null &&
			lastIssuePosition !== null &&
			selectedIssuePosition >= firstIssuePosition &&
			selectedIssuePosition <= lastIssuePosition
		);
	};

/**
 * Returns true if the currently selected issue is the first issue on the page.
 */
const isFirstIssueOnPage =
	(): Action<StoreType, PropType, boolean> =>
	({ dispatch }, { firstIssuePosition }) => {
		const selectedIssuePosition = dispatch(getSelectedIssuePosition());
		return selectedIssuePosition !== null && selectedIssuePosition === firstIssuePosition;
	};

/**
 * Returns true if the currently selected issue is the last issue on the page.
 */
const isLastIssueOnPage =
	(): Action<StoreType, PropType, boolean> =>
	({ dispatch }, { lastIssuePosition }) => {
		const selectedIssuePosition = dispatch(getSelectedIssuePosition());
		return selectedIssuePosition !== null && selectedIssuePosition === lastIssuePosition;
	};

/**
 * Return the next issue in the search results from the currently selected issue. If the selected issue is the last item
 * in the page of search results then the first key from the next page is returned with a boolean indicating the issue
 * belongs to the next page.
 */
const getNextIssue =
	(): Action<
		StoreType,
		PropType,
		{ issueKey: IssueKey; issueIndex: number | null; isOnNextPage: boolean }
	> =>
	({ getState, dispatch }, { firstIssueKeyFromNextPage, issueKeys }) => {
		const { selectedIssue } = getState();
		const [selectedIssueIndex] = selectedIssue;

		let nextIssueKey = '';
		let nextIssueIndex = null;
		let isOnNextPage = false;

		if (dispatch(isLastIssueOnPage())) {
			nextIssueKey = firstIssueKeyFromNextPage ?? '';
			isOnNextPage = true;
		} else if (selectedIssueIndex !== null && dispatch(isSelectedIssueOnPage())) {
			nextIssueIndex = selectedIssueIndex + 1;
			nextIssueKey = issueKeys[nextIssueIndex];
		}

		return {
			issueKey: nextIssueKey,
			issueIndex: nextIssueIndex,
			isOnNextPage,
		};
	};

/**
 * Return the previous issue in the search results from the currently selected issue. If the selected issue is the first
 * item in the page of search results then the last key from the previous page is returned with a boolean indicating the
 * issue belongs to the previous page.
 */
const getPreviousIssue =
	(): Action<
		StoreType,
		PropType,
		{ issueKey: IssueKey; issueIndex: number | null; isOnPreviousPage: boolean }
	> =>
	({ getState, dispatch }, { lastIssueKeyFromPreviousPage, issueKeys }) => {
		const { selectedIssue } = getState();
		const [selectedIssueIndex] = selectedIssue;

		let previousIssueKey = '';
		let previousIssueIndex = null;
		let isOnPreviousPage = false;
		const selectedIssuePosition = dispatch(getSelectedIssuePosition());

		// Ignore the first issue on the first page, which always has position '1'
		if (selectedIssuePosition === 1) {
			return {
				issueKey: previousIssueKey,
				issueIndex: previousIssueIndex,
				isOnPreviousPage,
			};
		}

		if (dispatch(isFirstIssueOnPage())) {
			previousIssueKey = lastIssueKeyFromPreviousPage ?? '';
			isOnPreviousPage = true;
		} else if (selectedIssueIndex !== null && dispatch(isSelectedIssueOnPage())) {
			previousIssueIndex = selectedIssueIndex - 1;
			previousIssueKey = issueKeys[previousIssueIndex];
		}

		return {
			issueKey: previousIssueKey,
			issueIndex: previousIssueIndex,
			isOnPreviousPage,
		};
	};

/**
 * Emit an event to fetch the next page of issues and force the first issue on the page (including null issues) to
 * be selected when the new connection of search results is loaded.
 */
const selectFirstIssueOnNextPage =
	(): Action<StoreType, PropType> =>
	({ setState }, { onNextPage }) => {
		setState({ nextResetStrategy: FORCE_FIRST });
		const revertStrategy = () => setState({ nextResetStrategy: DEFAULT_FIRST });

		// Revert the reset strategy if the pagination request was interrupted (e.g. toggling view) or failed.
		const handled = onNextPage({ onError: revertStrategy, onUnsubscribe: revertStrategy });

		// Revert the reset strategy if the page was unchanged
		if (!handled) {
			revertStrategy();
		}
	};

/**
 * Emit an event to fetch the previous page of issues and force the last issue on the page (including null issues)
 * to be selected when the new connection of search results is loaded.
 */
const selectLastIssueOnPreviousPage =
	(): Action<StoreType, PropType> =>
	({ setState }, { onPreviousPage }) => {
		setState({ nextResetStrategy: FORCE_LAST });
		const revertStrategy = () => setState({ nextResetStrategy: DEFAULT_FIRST });

		// Revert the reset strategy if the pagination request was interrupted (e.g. toggling view) or failed.
		const handled = onPreviousPage({ onError: revertStrategy, onUnsubscribe: revertStrategy });

		// Revert the reset strategy if the page was unchanged
		if (!handled) {
			revertStrategy();
		}
	};

const selectIssueOnPage =
	(after: string, shouldSelectLastIssue: boolean): Action<StoreType, PropType> =>
	({ setState }, { onPageChange }) => {
		setState({ nextResetStrategy: shouldSelectLastIssue ? FORCE_LAST : FORCE_FIRST });
		const revertStrategy = () => setState({ nextResetStrategy: DEFAULT_FIRST });

		// Revert the reset strategy if the pagination request was interrupted (e.g. toggling view) or failed.
		onPageChange(after, {
			onError: revertStrategy,
			onUnsubscribe: revertStrategy,
		});
	};

/**
 * Select the next issue in the search results (if there is a valid selection). Returns `true` if an issue was selected,
 * otherwise `false`.
 */
const selectNextIssue =
	(shouldDebounce?: boolean): Action<StoreType, PropType, boolean> =>
	({ dispatch }) => {
		const { issueKey, issueIndex, isOnNextPage } = dispatch(getNextIssue());
		if (issueIndex !== null) {
			dispatch(setSelectedIssueByIndex(issueIndex, shouldDebounce));
			return true;
		}
		if (issueKey !== '') {
			dispatch(setSelectedIssueByKey(issueKey, shouldDebounce));
			return true;
		}
		// If we do not have an issue key but our issue to select is on the next page (i.e. deleted issue) then we
		// dispatch an action to fetch the next page (rather than relying on the container update action).
		if (isOnNextPage) {
			dispatch(selectFirstIssueOnNextPage());
			return true;
		}
		return false;
	};

/**
 * Select the previous issue in the search results (if there is a valid selection). Returns `true` if an issue was
 * selected, otherwise `false`.
 */
const selectPreviousIssue =
	(shouldDebounce?: boolean): Action<StoreType, PropType, boolean> =>
	({ dispatch }) => {
		const { issueKey, issueIndex, isOnPreviousPage } = dispatch(getPreviousIssue());
		if (issueIndex !== null) {
			dispatch(setSelectedIssueByIndex(issueIndex, shouldDebounce));
			return true;
		}
		if (issueKey !== '') {
			dispatch(setSelectedIssueByKey(issueKey, shouldDebounce));
			return true;
		}
		// If we do not have an issue key but our issue to select is on the previous page (i.e. deleted issue) then we
		// dispatch an action to fetch the previous page (rather than relying on the container update action).
		if (isOnPreviousPage) {
			dispatch(selectLastIssueOnPreviousPage());
			return true;
		}
		return false;
	};

const setFocusedIssueByIndex =
	(issueIndex: number): Action<StoreType, PropType> =>
	({ setState }) => {
		setState({ focusedIssue: issueIndex });
	};

const resetFocusedIssue =
	(): Action<StoreType, PropType> =>
	({ setState }) => {
		setState({ focusedIssue: null });
	};

const deselectIssue =
	(
		shouldDebounce = false,
		shouldNotifyOnChange = true,
		isSelectedByUserInteraction = true,
	): Action<StoreType, PropType> =>
	({ setState, getState }, { onChange }) => {
		setState({ selectedIssue: [null, toIssueKey('')] });
		doSideEffectUpdates(
			shouldDebounce,
			setState,
			getState,
			onChange,
			shouldNotifyOnChange,
			isSelectedByUserInteraction,
		);
	};

const enterFullPageIssueAppMode =
	(): Action<StoreType, PropType> =>
	({ setState }) =>
		setState({ isFullPageIssueAppMode: true });

const exitFullPageIssueAppMode =
	(): Action<StoreType, PropType> =>
	({ setState }) =>
		setState({ isFullPageIssueAppMode: false });

const getDefaultIssueKey =
	(): Action<StoreType, PropType, string | undefined> =>
	(_, { issueKeys }) =>
		issueKeys.find((nonEmptyKey) => Boolean(nonEmptyKey));

/**
 * Return true if we're in default list view mode without the full page issue app visible.
 */
const isListViewWithoutIssueApp =
	(): Action<StoreType, PropType, boolean> =>
	({ getState }, { view }) => {
		const { isFullPageIssueAppMode } = getState();
		return view === views.list && !isFullPageIssueAppMode;
	};

/**
 * Return true if we're in default list view mode with the full page issue app visible and a selected issue key.
 */
const isListViewWithIssueAppAndKey =
	(): Action<StoreType, PropType, boolean> =>
	({ getState }, { view }) => {
		const { isFullPageIssueAppMode, selectedIssue } = getState();
		return view === views.list && isFullPageIssueAppMode && !!selectedIssue[1];
	};

/**
 * Reset the selected issue based on the current reset strategy.
 *
 * @param hasNextIssueKey `true` if a selected issue key is specified in the URL
 */
const resetSelectedIssue =
	(hasNextIssueKey: boolean): Action<StoreType, PropType> =>
	({ dispatch, getState, setState }, { issueKeys }) => {
		const { nextResetStrategy } = getState();

		// Update to default reset strategy
		setState({
			nextResetStrategy: DEFAULT_FIRST,
		});

		// We only want to update issue key in the URL if our view mode uses external issue key selection, i.e. detail
		// view or full page issue app in list view. For the default list view mode we want to keep the selected issue
		// state internal.
		const shouldNotifyOnChange = !dispatch(isListViewWithoutIssueApp());

		// If there is a selected issue specified in the URL, e.g. pagination, then `isSelectedByUserInteraction` should
		// be `true` to push a new entry in the browser history stack. Otherwise, if there is no selected issue in the
		// URL, e.g. new search, then `isSelectedByUserInteraction` should be false to replace the existing entry.
		const isSelectedByUserInteraction = hasNextIssueKey;

		// Index of the last issue on the issue list
		const lastIssueIndex = issueKeys.length - 1;

		switch (nextResetStrategy) {
			case FORCE_LAST:
				dispatch(
					setSelectedIssueByIndex(
						lastIssueIndex,
						false,
						shouldNotifyOnChange,
						isSelectedByUserInteraction,
					),
				);
				dispatch(setFocusedIssueByIndex(lastIssueIndex));
				break;
			case FORCE_FIRST:
				dispatch(
					setSelectedIssueByIndex(0, false, shouldNotifyOnChange, isSelectedByUserInteraction),
				);
				dispatch(setFocusedIssueByIndex(0));
				break;
			case DEFAULT_FIRST:
			default:
				dispatch(
					setSelectedIssueByIndex(0, false, shouldNotifyOnChange, isSelectedByUserInteraction),
				);
				break;
		}
	};

export const actions: ActionType = {
	setSelectedIssueByKey,
	setSelectedIssueByIndex,
	getSelectedIssuePosition,
	getNextIssue,
	getPreviousIssue,
	selectNextIssue,
	selectPreviousIssue,
	setFocusedIssueByIndex,
	deselectIssue,
	selectFirstIssueOnNextPage,
	selectLastIssueOnPreviousPage,
	selectIssueOnPage,
	enterFullPageIssueAppMode,
	exitFullPageIssueAppMode,
	resetFocusedIssue,
};

export const privateActions = {
	getDefaultIssueKey,
	isListViewWithoutIssueApp,
	isListViewWithIssueAppAndKey,
	resetSelectedIssue,
};

export default actions;
