import React, { useEffect, useState, type RefObject } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createStore,
	createStateHook,
	type Action,
} from '@atlassian/react-sweet-state';
import type { ElementContainerProps, Props, Size, State } from './types.tsx';

export const DEFAULT_SIZE: Size = {
	height: 0,
	width: 0,
	scrollHeight: 0,
	scrollWidth: 0,
};

export const ElementSizeContainer = createContainer<Props>({
	getIsConcurrentSafe: () => fg('jira-concurrent-issue-table-rss-fix'),
});

export const INITIAL_ELEMENT_STATE: State = {
	height: 0,
	width: 0,
};

const actions = {
	update:
		(newState: Partial<State>): Action<State> =>
		({ setState, getState }) => {
			const state = getState();
			setState({
				...state,
				...newState,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions, Props>({
	containedBy: ElementSizeContainer,
	initialState: INITIAL_ELEMENT_STATE,
	name: 'ElementSize',
	actions,
	handlers: {
		onInit:
			() =>
			({ setState, getState }, { width, height }) => {
				const state = getState();
				setState({
					...state,
					...{
						width,
						height,
					},
				});
			},
		onContainerUpdate:
			() =>
			({ setState, getState }, { width, height }) => {
				const state = getState();
				setState({
					...state,
					...{
						width,
						height,
					},
				});
			},
	},
});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const useElementSizeSelector = createStateHook(Store, {
	selector: (state, cb: Function) => cb(state),
}) as unknown as <T>(cb: (state: State) => T) => T;

export const useElementSize = (elementRef: RefObject<HTMLElement>) => {
	const [size, setSize] = useState(DEFAULT_SIZE);

	useEffect(() => {
		const element = elementRef.current;

		if (element) {
			const resizeObserver = new ResizeObserver(() => {
				setSize({
					height: element.clientHeight,
					width: element.clientWidth,
					scrollHeight: element.scrollHeight,
					scrollWidth: element.scrollWidth,
				});
			});

			resizeObserver.observe(element);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [elementRef]);

	return size;
};

export const ElementSizeProvider = ({ elementRef, children }: ElementContainerProps) => {
	const { width, height } = useElementSize(elementRef);
	return (
		<ElementSizeContainer width={width} height={height}>
			{children}
		</ElementSizeContainer>
	);
};
