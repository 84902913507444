import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useElementSizeSelector } from '@atlassian/jira-native-issue-table/src/controllers/element-size/index.tsx';
import {
	useScrollStateSelector,
	useScrollElement,
} from '@atlassian/jira-native-issue-table/src/controllers/scroll-state/index.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LoadingIndicator } from './loading-indicator/index.tsx';
import messages from './messages.tsx';

type Props = {
	isLoadingPrevious?: boolean;
	isLoadingNext?: boolean;
	onLoadingThresholdCheck?: (scrollElement: {
		clientHeight: number;
		scrollHeight: number;
		scrollTop: number;
	}) => void;
};

export const LoadingManager = ({
	isLoadingPrevious,
	isLoadingNext,
	onLoadingThresholdCheck,
}: Props) => {
	const { formatMessage } = useIntl();
	// List has height 0 during first render due to virtualization. Some checks need to wait for the list to have a height.
	const hasListHeight = useElementSizeSelector((listElm) => Boolean(listElm.height));
	const scrollContainerHeight = useScrollStateSelector((scrollState) => scrollState.height);
	const scrollElement = useScrollElement();

	// #region After-load Handler
	/**
	 * After initial render and any subsequent loading events, make sure that we continue loading in more issues if
	 * necessary because a single load may not have enough issues to fill the scroll container.
	 */
	useEffect(() => {
		if (
			scrollElement &&
			onLoadingThresholdCheck &&
			hasListHeight &&
			!isLoadingPrevious &&
			!isLoadingNext
		) {
			onLoadingThresholdCheck(scrollElement);
		}
	}, [hasListHeight, isLoadingPrevious, isLoadingNext, onLoadingThresholdCheck, scrollElement]);
	// #endregion

	// #region Resize Handler
	/**
	 * Check the loading boundaries when resizing the scroll container, as the new size may require loading more issues.
	 */
	const prevHeight = usePreviousWithInitial(scrollContainerHeight);
	useEffect(() => {
		if (
			scrollElement &&
			onLoadingThresholdCheck &&
			hasListHeight &&
			scrollContainerHeight > prevHeight
		) {
			onLoadingThresholdCheck(scrollElement);
		}
	}, [hasListHeight, scrollContainerHeight, prevHeight, onLoadingThresholdCheck, scrollElement]);
	// #endregion

	// #region Top Scroll Handler
	/**
	 * A component that ensures that the scroll position is kept stable when loading previous issues.
	 * This is necessary because sometimes the browser does scroll to keep existing rows in view when new rows are prepended.
	 *
	 * Without this, we may see issues like continuously loading all previous issues until there are none left.
	 */
	const prevIsLoadingPrevious = usePreviousWithInitial(isLoadingPrevious);
	const hasStartedLoadingPrevious = !prevIsLoadingPrevious && isLoadingPrevious;
	const hasFinishedLoadingPrevious = prevIsLoadingPrevious && !isLoadingPrevious;

	// Hook's scrollHeight doesn't update the height information when the scroll height changes due to DOM mutations
	// Need to track this separately when loading starts
	const scrollHeightRef = useRef(0);

	useEffect(() => {
		if (scrollElement && hasStartedLoadingPrevious) {
			scrollHeightRef.current = scrollElement.scrollHeight;
		}
	}, [hasStartedLoadingPrevious, scrollElement]);

	// Ensure that the scroll is kept in place after loading previous issues - browser doesn't always handle this properly
	// Using a layout effect to make sure this happens as quickly as possible to reduce visible movement
	useLayoutEffect(() => {
		if (scrollElement && hasFinishedLoadingPrevious) {
			const newScrollHeight = scrollElement.scrollHeight;

			if (newScrollHeight > scrollHeightRef.current) {
				const heightIncrease = newScrollHeight - scrollHeightRef.current;
				scrollElement.scrollBy(0, heightIncrease);
			}

			scrollHeightRef.current = newScrollHeight;
		}
	}, [hasFinishedLoadingPrevious, scrollElement]);
	// #endregion

	// #region Loading A11y
	const prevIsLoadingNext = usePreviousWithInitial(isLoadingNext);
	const hasFinishedLoadingNext = prevIsLoadingNext && !isLoadingNext;
	const loadingCompleteLiveMessage = useRef<MessageDescriptor | undefined>(undefined);

	if (isLoadingPrevious) {
		loadingCompleteLiveMessage.current = fg('jira-issue-terminology-refresh-m3')
			? messages.loadStartA11YMessageTopIssueTermRefresh
			: messages.loadStartA11YMessageTop;
	} else if (hasFinishedLoadingPrevious) {
		loadingCompleteLiveMessage.current = fg('jira-issue-terminology-refresh-m3')
			? messages.loadEndA11YMessageTopIssueTermRefresh
			: messages.loadEndA11YMessageTop;
	} else if (isLoadingNext) {
		loadingCompleteLiveMessage.current = fg('jira-issue-terminology-refresh-m3')
			? messages.loadStartA11YMessageBottomIssueTermRefresh
			: messages.loadStartA11YMessageBottom;
	} else if (hasFinishedLoadingNext) {
		loadingCompleteLiveMessage.current = fg('jira-issue-terminology-refresh-m3')
			? messages.loadEndA11YMessageBottomIssueTermRefresh
			: messages.loadEndA11YMessageBottom;
	}
	// #endregion

	return (
		<>
			{isLoadingPrevious && <LoadingIndicator position="top" />}
			{isLoadingNext && <LoadingIndicator position="bottom" />}
			{loadingCompleteLiveMessage.current && (
				<ScreenReaderText role="alert" aria-live="polite">
					{formatMessage(loadingCompleteLiveMessage.current)}
				</ScreenReaderText>
			)}
		</>
	);
};
