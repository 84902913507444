import React, { useCallback, useEffect, useMemo, type MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_FullPageIssueApp_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_FullPageIssueApp_issueResults.graphql';
import type { main_issueNavigator_FullPageIssueApp_view$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_FullPageIssueApp_view.graphql';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import {
	getEditionForProject,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { toProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { JiraProjectType } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_project.graphql';
import { ANALYTICS_LIST_VIEW_SOURCE_NAME } from '../../common/constants.tsx';
import IssueApp from '../../common/ui/issue-app/main.tsx';
import {
	useIsForcedSelectionOnReset,
	useSelectedIssueStateOldActions,
} from '../../controllers/selected-issue-state-old/index.tsx';
import { useSelectedIssueKey } from '../../controllers/selected-issue/facade.tsx';
import { useSelectedIssueActions } from '../../controllers/selected-issue/hooks.tsx';
import { useInfiniteScroll } from '../../services/infinite-scroll/index.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query/index.tsx';
import { useIsFetching } from '../../services/issue-search/selectors.tsx';
import messages from './messages.tsx';

type Props = {
	issueResults: IssueResultsFragment | null;
	view: ViewFragment | null;
	onChangeIssue: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
	projectType?: JiraProjectType | null;
};

const useAnalyticsAttributes = (type: JiraProjectType | null | undefined) => {
	const appEditions = useAppEditions();

	return useMemo(() => {
		if (!type) return;

		const projectType = toProjectType(type.toLowerCase());
		if (!projectType) return;

		const edition = getEditionForProject(projectType, appEditions);

		if (!edition) return;

		return { edition };
	}, [appEditions, type]);
};

const FullPageIssueApp = ({ onChangeIssue, issueResults, view, projectType }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	let isFetching;
	let isForcedSelectionOnReset;
	let exitFullPageIssueAppMode;
	let selectNextIssue;
	let selectPreviousIssue;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isFetching = useIsFetching();
		// TODO Remove this condition when cleaning up jira_spreadsheet_component_m1
		isForcedSelectionOnReset = false;
		({ exitFullPageIssueAppMode, selectNextIssue, selectPreviousIssue } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueActions());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isFetching } = useIssueSearchQuery());
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isForcedSelectionOnReset = useIsForcedSelectionOnReset();
		({ exitFullPageIssueAppMode, selectNextIssue, selectPreviousIssue } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useSelectedIssueStateOldActions());
	}

	const onBackToSearch = useCallback(() => {
		// Clear the selected issue key from the URL and return to normal view mode
		onChangeIssue(toIssueKey(''), true);
		exitFullPageIssueAppMode();
	}, [exitFullPageIssueAppMode, onChangeIssue]);

	const onBackToSearchByButton = useCallback(
		(e: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			// @ts-expect-error - Argument of type 'UIAnalyticsEvent | null' is not assignable to parameter of type 'UIAnalyticsEvent'.
			fireUIAnalytics(analyticsEvent.clone(), 'returnToSearch');
			onBackToSearch();
		},
		[onBackToSearch],
	);

	const onBackToSearchByKeyboardShortcut = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'issue',
			}),
			'returnToSearch',
			{
				keyPressed: 'z',
				keyboardShortcut: true,
			},
		);
		onBackToSearch();
	}, [onBackToSearch, createAnalyticsEvent]);

	const onNextIssue = useCallback(() => {
		if (selectNextIssue()) {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'nextIssue',
				{ keyboardShortcut: true },
			);
		}
	}, [createAnalyticsEvent, selectNextIssue]);

	const onPreviousIssue = useCallback(() => {
		if (selectPreviousIssue()) {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'previousIssue',
				{ keyboardShortcut: true },
			);
		}
	}, [createAnalyticsEvent, selectPreviousIssue]);

	// If a new search is being run and we do not have forced issue selection (i.e. issue app pagination) then return to
	// normal view mode. This can happen when a filter is clicked in the sidebar or browser history navigation.
	useEffect(() => {
		if (isFetching && !isForcedSelectionOnReset) {
			exitFullPageIssueAppMode();
		}
	}, [exitFullPageIssueAppMode, isFetching, isForcedSelectionOnReset]);

	const backToSearch = (
		<Button
			testId="issue-navigator.ui.full-page-issue-app.return-to-search"
			onClick={onBackToSearchByButton}
			interactionName="issue-navigator-full-page-issue-app-return-to-search"
		>
			{formatMessage(messages.returnText)}
		</Button>
	);

	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_FullPageIssueApp_issueResults on JiraIssueConnection {
				edges {
					node {
						key
					}
				}
				...main_issueNavigator_IssueAppWithData_issueResults
			}
		`,
		issueResults,
	);

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment main_issueNavigator_FullPageIssueApp_view on JiraIssueSearchViewMetadata {
				...main_issueNavigator_IssueAppWithData_view
				fieldSets(first: $amountOfColumns, filter: { fieldSetSelectedState: SELECTED }) {
					edges {
						node {
							fieldSetId
						}
					}
				}
			}
		`,
		view,
	);

	let attributes: { edition: ApplicationEdition } | undefined;
	if (fg('add-attribute-required-for-colorful-labels-expe')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		attributes = useAnalyticsAttributes(projectType);
	}

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const fieldSetIds = useMemo(
			() => viewData?.fieldSets?.edges?.map((edge) => edge?.node?.fieldSetId).filter(Boolean) ?? [],
			[viewData?.fieldSets?.edges],
		);
		const { hasPrevious, isLoadingPrevious, onLoadPrevious, hasNext, isLoadingNext, onLoadNext } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useInfiniteScroll({ fieldSetIds, view: null });
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const selectedIssueKey = useSelectedIssueKey();
		const edges = issueResultsData?.edges ?? [];
		const isFirstIssueSelected = edges[0]?.node?.key === selectedIssueKey;
		const isLastIssueSelected = edges[edges.length - 1]?.node?.key === selectedIssueKey;

		// Automatically fetch previous and next pages while navigating issues in full page mode
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (isFirstIssueSelected && hasPrevious && !isLoadingPrevious) {
				onLoadPrevious?.();
			}
		}, [hasPrevious, isFirstIssueSelected, isLoadingPrevious, onLoadPrevious]);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (isLastIssueSelected && hasNext && !isLoadingNext) {
				onLoadNext?.();
			}
		}, [hasNext, isLastIssueSelected, isLoadingNext, onLoadNext]);
	}

	return (
		<UFOLabel name="full-page-issue-app">
			<ContextualAnalyticsData
				sourceName={ANALYTICS_LIST_VIEW_SOURCE_NAME}
				sourceType={SCREEN}
				{...(attributes ? { attributes } : {})}
			>
				<Shortcuts
					keyMap={{
						z: {
							callback: onBackToSearchByKeyboardShortcut,
						},
						j: {
							callback: onNextIssue,
						},
						k: {
							callback: onPreviousIssue,
						},
					}}
				/>
				<IssueApp
					isEmbedView={false}
					extraHeaderActions={backToSearch}
					issueResults={issueResultsData}
					view={viewData}
				/>
			</ContextualAnalyticsData>
		</UFOLabel>
	);
};

export default FullPageIssueApp;
