/**
 * @generated SignedSource<<e914c0aacd086a1d486cf77ab6b1c123>>
 * @relayHash b9ae37a89066f444cd5623ad264d8ed6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8eede8a8d01c2ca684a99ee7b5333b915b6d2cb415440d0f536f44c00f11f1bc

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraIssueSearchFieldSetSelectedState = "ALL" | "NON_SELECTED" | "SELECTED" | "%future added value";
export type JiraIssueSearchFieldSetsFilter = {
  fieldSetSelectedState?: JiraIssueSearchFieldSetSelectedState | null | undefined;
  searchString?: string | null | undefined;
};
export type fetchColumnPickerOptionsQuery$variables = {
  after?: string | null | undefined;
  cloudId: string;
  filter?: JiraIssueSearchFieldSetsFilter | null | undefined;
  first?: number | null | undefined;
  isIFCEnabled: boolean;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type fetchColumnPickerOptionsQuery$data = {
  readonly jira: {
    readonly issueSearchViewByNamespaceAndViewId?: {
      readonly fieldSets: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly displayName: string;
            readonly fieldSetId: string;
            readonly fieldType: {
              readonly displayName: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          };
        } | null | undefined>;
        readonly pageInfo: {
          readonly endCursor: string | null | undefined;
        };
        readonly totalCount: number;
      };
    };
    readonly issueSearchViewResult?: {
      readonly fieldSets?: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly displayName: string;
            readonly fieldSetId: string;
            readonly fieldType: {
              readonly displayName: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          };
        } | null | undefined>;
        readonly pageInfo: {
          readonly endCursor: string | null | undefined;
        };
        readonly totalCount: number;
      };
    };
  };
};
export type fetchColumnPickerOptionsQuery = {
  response: fetchColumnPickerOptionsQuery$data;
  variables: fetchColumnPickerOptionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isIFCEnabled"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "namespace",
    "variableName": "namespace"
  },
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v10 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v12 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v13 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v14 = {
  "kind": "ScalarField",
  "name": "type"
},
v15 = {
  "concreteType": "JiraFieldType",
  "kind": "LinkedField",
  "name": "fieldType",
  "plural": false,
  "selections": [
    (v13/*: any*/)
  ]
},
v16 = {
  "args": (v8/*: any*/),
  "concreteType": "JiraIssueSearchFieldSetConnection",
  "kind": "LinkedField",
  "name": "fieldSets",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "concreteType": "JiraIssueSearchFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        (v11/*: any*/),
        {
          "concreteType": "JiraIssueSearchFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/)
          ]
        }
      ]
    }
  ]
},
v17 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "fetchColumnPickerOptionsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "condition": "isIFCEnabled",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v7/*: any*/),
                    "concreteType": "JiraIssueSearchView",
                    "kind": "LinkedField",
                    "name": "issueSearchViewByNamespaceAndViewId",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "args": (v8/*: any*/),
                          "concreteType": "JiraIssueSearchFieldSetConnection",
                          "kind": "LinkedField",
                          "name": "fieldSets",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v9/*: any*/),
                              "action": "THROW",
                              "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.totalCount"
                            },
                            (v10/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueSearchFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraIssueSearchFieldSet",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v12/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.fieldSetId"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v13/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.displayName"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v14/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node.type"
                                        },
                                        (v15/*: any*/)
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges.node"
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "jira.issueSearchViewByNamespaceAndViewId.fieldSets"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.issueSearchViewByNamespaceAndViewId"
                }
              ]
            },
            {
              "condition": "isIFCEnabled",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v7/*: any*/),
                    "kind": "LinkedField",
                    "name": "issueSearchViewResult",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "args": (v8/*: any*/),
                              "concreteType": "JiraIssueSearchFieldSetConnection",
                              "kind": "LinkedField",
                              "name": "fieldSets",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "RequiredField",
                                  "field": (v9/*: any*/),
                                  "action": "THROW",
                                  "path": "jira.issueSearchViewResult.fieldSets.totalCount"
                                },
                                (v10/*: any*/),
                                {
                                  "kind": "RequiredField",
                                  "field": {
                                    "concreteType": "JiraIssueSearchFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      (v11/*: any*/),
                                      {
                                        "kind": "RequiredField",
                                        "field": {
                                          "concreteType": "JiraIssueSearchFieldSet",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "RequiredField",
                                              "field": (v12/*: any*/),
                                              "action": "THROW",
                                              "path": "jira.issueSearchViewResult.fieldSets.edges.node.fieldSetId"
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": (v13/*: any*/),
                                              "action": "THROW",
                                              "path": "jira.issueSearchViewResult.fieldSets.edges.node.displayName"
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": (v14/*: any*/),
                                              "action": "THROW",
                                              "path": "jira.issueSearchViewResult.fieldSets.edges.node.type"
                                            },
                                            (v15/*: any*/)
                                          ]
                                        },
                                        "action": "THROW",
                                        "path": "jira.issueSearchViewResult.fieldSets.edges.node"
                                      }
                                    ]
                                  },
                                  "action": "THROW",
                                  "path": "jira.issueSearchViewResult.fieldSets.edges"
                                }
                              ]
                            },
                            "action": "THROW",
                            "path": "jira.issueSearchViewResult.fieldSets"
                          }
                        ],
                        "type": "JiraIssueSearchView"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.issueSearchViewResult"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "fetchColumnPickerOptionsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "isIFCEnabled",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v7/*: any*/),
                "concreteType": "JiraIssueSearchView",
                "kind": "LinkedField",
                "name": "issueSearchViewByNamespaceAndViewId",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/)
                ]
              }
            ]
          },
          {
            "condition": "isIFCEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v7/*: any*/),
                "kind": "LinkedField",
                "name": "issueSearchViewResult",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v16/*: any*/)
                    ],
                    "type": "JiraIssueSearchView"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v17/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "8eede8a8d01c2ca684a99ee7b5333b915b6d2cb415440d0f536f44c00f11f1bc",
    "metadata": {},
    "name": "fetchColumnPickerOptionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "19ef0aa93c8f07d23db19ebd37d0adaa";

export default node;
