import React from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { SpotlightTarget, SpotlightManager } from '@atlaskit/onboarding';
import { HierarchyToggle as HierarchyToggleBase } from '@atlassian/jira-issue-table-hierarchy-toggle/src/index.tsx';
import { HierarchyToggleOnboarding as HierarchyToggleOnboardingOld } from '@atlassian/jira-native-issue-table/src/ui/hierarchy-onboarding/index.tsx';
import { HierarchyToggleOnboarding as HierarchyToggleOnboardingNew } from '@atlassian/jira-issue-table-hierarchy/src/ui/hierarchy-onboarding/index.tsx';
import type { hierarchyToggle_issueNavigator_HierarchyToggleItem$key } from '@atlassian/jira-relay/src/__generated__/hierarchyToggle_issueNavigator_HierarchyToggleItem.graphql';
import { fg } from '@atlassian/jira-feature-gating';

export const HierarchyToggleItem = ({
	viewResult,
	isGroupingSupported,
	onChangeHierarchy,
}: {
	viewResult: hierarchyToggle_issueNavigator_HierarchyToggleItem$key;
	isGroupingSupported?: boolean;
	onChangeHierarchy: (isHierarchyEnabled: boolean) => void;
}) => {
	const viewResultData = useFragment<hierarchyToggle_issueNavigator_HierarchyToggleItem$key>(
		graphql`
			fragment hierarchyToggle_issueNavigator_HierarchyToggleItem on JiraIssueSearchViewMetadata
			@argumentDefinitions(
				isJscM2Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider"
				}
			) {
				...ui_issueTableHierarchyToggle_HierarchyToggle
					@arguments(staticViewInput: $staticViewInput)
					@include(if: $isJscM2Enabled)
			}
		`,
		viewResult,
	);

	return (
		<SpotlightManager>
			<SpotlightTarget name="hierarchy-feature-toggle">
				<HierarchyToggleBase
					isGroupingSupported={isGroupingSupported}
					viewResult={viewResultData}
					onChangeHierarchy={onChangeHierarchy}
				/>
			</SpotlightTarget>
			{fg('jira_list_hierarchy_extraction') ? (
				<HierarchyToggleOnboardingNew />
			) : (
				<HierarchyToggleOnboardingOld />
			)}
		</SpotlightManager>
	);
};
