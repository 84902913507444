import React from 'react';
import { type PreloadedQuery, graphql, usePreloadedQuery } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { documentTitleQuery } from '@atlassian/jira-relay/src/__generated__/documentTitleQuery.graphql';
import messages from './messages.tsx';

type NavigatorDocumentTitleProps = {
	queryReference: PreloadedQuery<documentTitleQuery>;
};

const NavigatorDocumentTitle = ({ queryReference }: NavigatorDocumentTitleProps) => {
	const { formatMessage } = useIntl();

	const data = usePreloadedQuery<documentTitleQuery>(
		graphql`
			query documentTitleQuery($projectKey: String!, $cloudId: ID!) {
				jira {
					jiraProjectByKey(key: $projectKey, cloudId: $cloudId) {
						... on JiraProject {
							__typename
							name
						}
					}
				}
			}
		`,
		queryReference,
	);
	const projectName: string = data?.jira?.jiraProjectByKey?.name ?? '';

	return (
		<DocumentTitle
			title={formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.windowTitleMigrationIssueTermRefresh
					: messages.windowTitleMigration,
				{ projectName },
			)}
		/>
	);
};

export default NavigatorDocumentTitle;
