import React from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import SearchNoResultsImageOld from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

const ANOTHER_FILTER_LINK = '/jira/filters';

export const NoFilter = ({ reason }: { reason?: string }) => {
	const { formatMessage } = useIntl();

	return (
		<ContextualAnalyticsData sourceName="noFilter" sourceType={SCREEN}>
			<FireScreenAnalytics
				attributes={
					reason
						? {
								reason,
							}
						: undefined
				}
			/>
			<EmptyState
				header={formatMessage(messages.weCouldntFindThisFilter)}
				description={
					<>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<p>{formatMessage(messages.filterNoExistOrNoPermissions)}</p>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<p>
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.tryAnotherFilterIssueTermRefresh
									: messages.tryAnotherFilter,
							)}
						</p>
					</>
				}
				secondaryAction={
					<Button href={ANOTHER_FILTER_LINK}>{formatMessage(messages.viewFilters)}</Button>
				}
				primaryAction={
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					<Button appearance="primary" href={window.location.pathname}>
						{formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.searchIssuesIssueTermRefresh
								: messages.searchIssues,
						)}
					</Button>
				}
				renderImage={() =>
					isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? (
						<Box xcss={ImageWrapperStyles}>
							<SearchNoResultsImage alt="" />
						</Box>
					) : (
						<Box xcss={ImageWrapperStylesOld}>
							<SearchNoResultsImageOld alt="" />
						</Box>
					)
				}
			/>
		</ContextualAnalyticsData>
	);
};

export default NoFilter;

const ImageWrapperStylesOld = xcss({
	maxHeight: '146px',
	maxWidth: '160px',
	margin: 'auto',
	marginBottom: 'space.300',
});

const ImageWrapperStyles = xcss({
	maxHeight: '165px',
	maxWidth: '150px',
	margin: 'auto',
	marginBottom: 'space.300',
});
