import React, { useContext, type PropsWithChildren } from 'react';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';

import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { ifcEntryPoint } from './entrypoint.tsx';

export const IFCEntryPointContext = createUseEntryPointContext<typeof ifcEntryPoint>();

const entryPointParams = {};

export const IFCEntryPointContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const entryPointResult = useEntryPoint(ifcEntryPoint, entryPointParams);
	return (
		<IFCEntryPointContext.Provider value={entryPointResult}>
			{children}
		</IFCEntryPointContext.Provider>
	);
};

export const useIFCEntryPointContext = () => {
	return useContext(IFCEntryPointContext);
};

export const InlineFieldConfigProviderComponent = componentWithCondition(
	() =>
		// eslint-disable-next-line jira/ff/no-preconditioning
		expVal('jira_inline_field_config', 'isInlineFieldConfigEnabled', false) &&
		fg('jira_nin_sidebar_layout') &&
		fg('jira_ifc_changes_for_pnin'),
	IFCEntryPointContextProvider,
	({ children }: PropsWithChildren<{}>) => children,
);
