/**
 * @generated SignedSource<<cbc71691841953de0d125b05443d9491>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_project$data = {
  readonly projectType: JiraProjectType | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_project">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_project";
};
export type main_issueNavigator_IssueNavigatorUI_project$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_project"
    },
    {
      "kind": "ScalarField",
      "name": "projectType"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "3dd510242e3d4890b157f1d082cc36fa";

export default node;
