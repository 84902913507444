import React, { useCallback, useEffect } from 'react';
import { useQueryLoader } from 'react-relay';
import AppPermissionsCheck from '@atlassian/jira-app-permissions-check/src/AppPermissionsCheck.tsx';
import type { CustomHeaderProps } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import IssueNavigator, {
	type Props as IssueNavigatorProps,
} from '@atlassian/jira-issue-navigator/src/main.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { ProjectIssueNavigatorHeaderSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-header.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import PROJECT_NAME_QUERY_AGG, {
	type documentTitleQuery,
} from '@atlassian/jira-relay/src/__generated__/documentTitleQuery.graphql.ts';

import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import Header from './ui/main.tsx';
import NavigatorDocumentTitle from './ui/document-title/index.tsx';
import PageActions from './ui/page-actions/index.tsx';

type InnerProps = {
	CustomHeader: IssueNavigatorProps['CustomHeader'];
	HeaderSkeletonImage: IssueNavigatorProps['HeaderSkeletonImage'];
};

export type Props = Omit<IssueNavigatorProps, keyof InnerProps> & {
	projectKey: ProjectKey;
	showNav3Header?: boolean;
};

const ActionMenu = (customHeaderProps: CustomHeaderProps) => {
	const {
		jql,
		filterId,
		filterDetails,
		searchResultPageData,
		HierarchyToggle,
		GroupByDropdown,
		HideDoneToggle,
	} = customHeaderProps;

	return (
		<PageActions
			jql={jql}
			filterId={filterId}
			isModifiedFilter={filterDetails?.jql !== jql}
			searchResultPageData={searchResultPageData}
			HierarchyToggle={HierarchyToggle}
			GroupByDropdown={GroupByDropdown}
			HideDoneToggle={HideDoneToggle}
		/>
	);
};

const ProjectIssueNavigator = ({
	projectKey,
	showNav3Header = false,
	isIssueHierarchySupportEnabled,
	...rest
}: Props) => {
	const CustomHeader = useCallback(
		(customHeaderProps: CustomHeaderProps) => (
			<Header
				projectKey={projectKey}
				{...customHeaderProps}
				isQuickFiltersDisabled={showNav3Header}
				isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
			/>
		),
		[isIssueHierarchySupportEnabled, projectKey, showNav3Header],
	);

	const showNewNav = getWillShowNav4() && !showNav3Header;

	const [queryReference, loadQuery] = useQueryLoader<documentTitleQuery>(PROJECT_NAME_QUERY_AGG);
	const cloudId = useCloudId();

	useEffect(() => {
		loadQuery({ projectKey, cloudId });
	}, [loadQuery, projectKey, cloudId]);

	return (
		<>
			{showNewNav &&
				fg('update-document-title-jira-project-issue-navigator') &&
				(queryReference ? (
					<NavigatorDocumentTitle queryReference={queryReference} />
				) : (
					<DocumentTitle title="" />
				))}
			<IssueNavigator
				{...(!showNewNav && { CustomHeader })}
				{...(showNewNav && { ActionMenu })}
				HeaderSkeletonImage={ProjectIssueNavigatorHeaderSkeleton}
				isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
				{...rest}
			/>
			<AppPermissionsCheck hasServiceDeskAccess>
				<JsdGettingStartedPanelShouldRender />
			</AppPermissionsCheck>
		</>
	);
};

export default ProjectIssueNavigator;
