import React, {
	type FC,
	useContext,
	useEffect,
	useState,
	useRef,
	useMemo,
	type PropsWithChildren,
} from 'react';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import Spinner from '@atlaskit/spinner';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import ButtonOld from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ErrorState } from '@atlassian/jira-issue-navigator-actions-common/src/ui/error/index.tsx';
import { NinChangeboardingContext } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import type { SearchResultPageData } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useHierarchyOnboardingContext as useHierarchyOnboardingContextNew } from '@atlassian/jira-issue-table-hierarchy/src/ui/hierarchy-onboarding/index.tsx';
import { useHierarchyOnboardingContext as useHierarchyOnboardingContextOld } from '@atlassian/jira-native-issue-table/src/ui/hierarchy-onboarding/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useMeatballMenuItems, type RequestPayload } from '../services/meatball-menu/index.tsx';
import { meatballMenuPopUpEntryPoint } from '../entrypoint.tsx';
import messages from './messages.tsx';
import { StatisticsOnboarding } from './statistics-onboarding/index.tsx';
import { useStatisticsSpotlight } from './statistics-onboarding/utils.tsx';
import {
	ONBOARDING_STATISTICS_DISMISS_BUTTON_ID,
	ONBOARDING_STATISTICS_TRY_IT_OUT_BUTTON_ID,
} from './statistics-onboarding/constants.tsx';

export type MeatballMenuProps = {
	jql: string;
	filterId?: string;
	searchResultPageData: SearchResultPageData;
	additionalMenuItems?: React.ReactNode;
	viewOptions?: React.ReactNode;
	isIssueHierarchySupportEnabled?: boolean;
};

type StatisticsOnboardingProps = {
	shouldShowSpotlight: boolean;
	onStatisticsTryNow: () => void;
	onStatisticsOnboardingStop: (actionSubject: string) => void;
	coordinationClient: CoordinationClient;
};

const StatisticsOnboardingSpotlight = componentWithCondition(
	() => expVal('helios_list_view_statistics_nin_migration', 'isEnabled', false),
	({
		children,
		shouldShowSpotlight,
		coordinationClient,
		onStatisticsTryNow,
		onStatisticsOnboardingStop,
	}: PropsWithChildren<StatisticsOnboardingProps>) => (
		<StatisticsOnboarding
			shouldShowSpotlight={shouldShowSpotlight}
			coordinationClient={coordinationClient}
			onStatisticsTryNow={onStatisticsTryNow}
			onStatisticsOnboardingStop={() =>
				onStatisticsOnboardingStop(ONBOARDING_STATISTICS_DISMISS_BUTTON_ID)
			}
		>
			{children}
		</StatisticsOnboarding>
	),
	({ children }: PropsWithChildren<StatisticsOnboardingProps>) => <>{children}</>,
);

const useHierarchyOnboardingContext = () => {
	if (fg('jira_list_hierarchy_extraction')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useHierarchyOnboardingContextNew();
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useHierarchyOnboardingContextOld();
};

export const MeatballMenu: FC<MeatballMenuProps> = ({
	jql,
	filterId = '',
	searchResultPageData,
	additionalMenuItems,
	viewOptions,
	isIssueHierarchySupportEnabled,
}: MeatballMenuProps) => {
	const { data, loading, error, fetch, requestPayload } =
		useMeatballMenuItems(searchResultPageData);
	const { formatMessage } = useIntl();
	const isNinChangeboardingEnabled = useContext(NinChangeboardingContext);
	const isAnonymous = useIsAnonymous();
	const shouldShowOptOut = isNinChangeboardingEnabled && !isAnonymous;

	const [isOpen, setIsOpen] = useState(false);
	const lastUsedRequestPayload = useRef<RequestPayload>();
	const hasPreviousError = useRef(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const isMeatballMenuOpenedWithNewPayload =
			isOpen && JSON.stringify(requestPayload) !== JSON.stringify(lastUsedRequestPayload.current);

		if (!loading && !hasPreviousError.current && isMeatballMenuOpenedWithNewPayload) {
			fetch(requestPayload);
			lastUsedRequestPayload.current = requestPayload;
		}

		// If there was a fetch error, we clear the lastUsedRequestPayload
		if (error) {
			hasPreviousError.current = true;
			lastUsedRequestPayload.current = undefined;
		}
	}, [fetch, isOpen, loading, requestPayload, error]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		meatballMenuPopUpEntryPoint,
		{},
	);

	const { shouldShowSpotlight, onStatisticsOnboardingStop, coordinationClient } =
		useStatisticsSpotlight();

	const onStatisticsTryNow = () => {
		onStatisticsOnboardingStop(ONBOARDING_STATISTICS_TRY_IT_OUT_BUTTON_ID);
		entryPointActions.load();
		setIsOpen(true);
	};

	const runtimeProps = useMemo(
		() => ({
			jql,
			filterId,
			additionalMenuItems,
			viewOptions,
			shouldShowOptOut,
			data,
		}),
		[jql, filterId, additionalMenuItems, viewOptions, shouldShowOptOut, data],
	);

	const entryPointTriggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	const [retryKey, setRetryKey] = useState(0);
	const errorFallback = (onRetry: () => void | Promise<void>) => (
		<ErrorState onRetry={onRetry} pageAction="meatballMenu" />
	);

	const { showToggleOnboarding } =
		// eslint-disable-next-line jira/ff/no-preconditioning
		expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
		fg('jsc_m2_hierarchy_fe_changes')
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useHierarchyOnboardingContext()
			: { showToggleOnboarding: false };

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (
			// eslint-disable-next-line jira/ff/no-preconditioning
			showToggleOnboarding &&
			expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
			isIssueHierarchySupportEnabled
		) {
			// We cannot access the ref of the "next" button in the onboarding spotlight.
			// Easiest workaround is to load the entrypoint here before we open the menu.
			// Once onboarding is removed, we can likely remove the entire isOpen prop from
			// the DropdownMenu component to avoid this issue in the future.
			entryPointActions.load();
			setIsOpen(true);
		}
	}, [showToggleOnboarding, entryPointActions, isIssueHierarchySupportEnabled]);

	return (
		<DropdownMenu
			isLoading={
				loading && JSON.stringify(requestPayload) === JSON.stringify(lastUsedRequestPayload.current)
			}
			onOpenChange={(nextState) => {
				if (nextState.isOpen) {
					hasPreviousError.current = false;
				}
				setIsOpen(nextState.isOpen);
				fireUIAnalytics(
					createAnalyticsEvent({
						action: nextState.isOpen ? 'opened' : 'closed',
						actionSubject: 'button',
					}),
					'meatballMenuPopup',
				);
			}}
			isOpen={isOpen}
			trigger={({ triggerRef, ...props }) =>
				isVisualRefreshEnabled() ? (
					<StatisticsOnboardingSpotlight
						shouldShowSpotlight={shouldShowSpotlight}
						onStatisticsTryNow={onStatisticsTryNow}
						onStatisticsOnboardingStop={onStatisticsOnboardingStop}
						coordinationClient={coordinationClient}
					>
						<IconButton
							ref={mergeRefs(triggerRef, entryPointTriggerRef)}
							{...props}
							testId="issue-navigator-action-meatball-menu.ui.menu-trigger"
							isSelected={isOpen}
							label={formatMessage(messages.moreActionsLabel)}
							icon={ShowMoreHorizontalIcon}
							isTooltipDisabled={false}
						/>
					</StatisticsOnboardingSpotlight>
				) : (
					<StatisticsOnboardingSpotlight
						shouldShowSpotlight={shouldShowSpotlight}
						onStatisticsTryNow={onStatisticsTryNow}
						onStatisticsOnboardingStop={onStatisticsOnboardingStop}
						coordinationClient={coordinationClient}
					>
						<ButtonOld
							{...props}
							testId="issue-navigator-action-meatball-menu.ui.menu-trigger"
							spacing="default"
							isSelected={isOpen}
							iconBefore={
								<MoreIcon
									label={formatMessage(messages.moreActionsLabel)}
									LEGACY_size="medium"
									spacing="spacious"
								/>
							}
							ref={mergeRefs(triggerRef, entryPointTriggerRef)}
						/>
					</StatisticsOnboardingSpotlight>
				)
			}
			shouldFlip
			placement="bottom-end"
			spacing="compact"
			testId="issue-navigator-action-meatball-menu.ui.dropdown-menu"
		>
			{!error ? (
				<JiraEntryPointContainer
					key={retryKey}
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="popup-content"
					packageName="jiraIssueNavigatorActionMeatballMenu"
					errorFallback={() =>
						errorFallback(() => {
							() => setRetryKey((prev) => prev + 1);
						})
					}
					fallback={
						<Box xcss={spinnerContainerStyles}>
							<Spinner size="small" />
						</Box>
					}
					runtimeProps={runtimeProps}
				/>
			) : (
				errorFallback(() => {
					fetch(requestPayload);
					lastUsedRequestPayload.current = requestPayload;
				})
			)}
		</DropdownMenu>
	);
};

const spinnerContainerStyles = xcss({
	display: 'flex',
	minWidth: '160px',
	padding: 'space.250',
	justifyContent: 'center',
});

export default MeatballMenu;
