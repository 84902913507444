/**
 * @generated SignedSource<<700c6cec2380a80010ed36ce2285a726>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_groupResults$data = {
  readonly firstGroup: {
    readonly issues: {
      readonly " $fragmentSpreads": FragmentRefs<"ui_issueNavigatorBulkOperationsConnectionsSubscriber_BulkOpsConnectionsSubscriber">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_groups">;
  readonly " $fragmentType": "main_issueNavigator_ListView_groupResults";
};
export type main_issueNavigator_ListView_groupResults$key = {
  readonly " $data"?: main_issueNavigator_ListView_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_groupResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isPaginating"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_groupResults",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isPaginating",
          "variableName": "isPaginating"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ui_nativeIssueTable_NativeIssueTable_groups"
    },
    {
      "concreteType": "JiraSpreadsheetGroup",
      "kind": "LinkedField",
      "name": "firstGroup",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "fieldSetsInput",
              "variableName": "fieldSetsInput"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            }
          ],
          "concreteType": "JiraIssueConnection",
          "kind": "LinkedField",
          "name": "issues",
          "plural": false,
          "selections": [
            {
              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ui_issueNavigatorBulkOperationsConnectionsSubscriber_BulkOpsConnectionsSubscriber"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "6ce9115a825361e34eff3aa4787e0251";

export default node;
