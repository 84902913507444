import React, { useCallback } from 'react';
import {
	useSidebar,
	useSidebarActions,
} from '@atlassian/jira-platform-sidebar/src/controllers/use-sidebar/index.tsx';
import { SIDEBAR_EXPERIENCE_NAME } from '@atlassian/jira-issue-navigator-action-issue-field-statistics/src/common/constants.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useResolvedJql } from '../../../services/active-jql/index.tsx';
import { IssueFieldStatisticsSidebarExperience } from './issue-field-statistics-experience/index.tsx';
import { ErrorState } from './error-state/index.tsx';

/**
 * This component is responsible for conditionally rendering entrypoint containers for sidebar experiences supported in the issue navigator.
 * To understand how a sidebar experience is rendered, or to onboard a new experience, refer to the following steps:
 * 1. Conditionally render your entrypoint container in this file using a unique experience id.
 * 2. Call setExperienceId(experienceId) from the useSidebar hook to mount your entrypoint container.
 * 3. Load your entrypoint using entrypointActions.load() or the entrypoint button + ref pattern.
 * Depending on your experiences runtimeProps requirements, you may need to call additional hooks in this file.
 * You will likely also need to create an additional entrypoint context provider.
 */
export const SidebarExperienceRegistry = () => {
	const [{ id, content }, { closeSidebar }] = useSidebar();
	const jql = useResolvedJql();

	if (content) {
		return content;
	}

	if (
		id === SIDEBAR_EXPERIENCE_NAME &&
		expVal('helios_list_view_statistics_nin_migration', 'isEnabled', true)
	) {
		return <IssueFieldStatisticsSidebarExperience jql={jql} onClose={closeSidebar} />;
	}

	return null;
};

// Note: This error cannot be reset without refreshing the page, as we do not want to expose the full useSidebar hook here
// It is recommended individual experiences handle their own error boundaries, which will be automatically provided when using entrypoints
export const SidebarExperienceRegistryWithErrorBoundary = () => {
	const { closeSidebar } = useSidebarActions();
	const fallback = useCallback(() => <ErrorState onClose={closeSidebar} />, [closeSidebar]);

	return (
		<JSErrorBoundary
			id="sidebarExperienceRegistry"
			packageName="jiraIssueNavigator"
			teamName="empanada"
			fallback={fallback}
		>
			<SidebarExperienceRegistry />
		</JSErrorBoundary>
	);
};
