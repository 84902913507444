import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	heading: {
		id: 'project-issue-navigator.heading',
		defaultMessage: 'Issues',
		description: 'Page heading title',
	},
	windowTitleMigration: {
		id: 'project-issue-navigator.window-title-migration',
		defaultMessage: '{projectName} - Issues',
		description: 'Window title of the project issue navigator page',
	},
	windowTitleMigrationIssueTermRefresh: {
		id: 'project-issue-navigator.window-title-migration-issue-term-refresh',
		defaultMessage: '{projectName} - All work',
		description: 'Window title of the project issue navigator page',
	},
	headingIssueTermRefresh: {
		id: 'project-issue-navigator.heading-issue-term-refresh',
		defaultMessage: 'All work',
		description: 'Page heading title',
	},
});

export default messages;
