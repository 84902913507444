import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { ButtonGroup } from '@atlaskit/button';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { issuePagination_issueNavigator$key as IssuePaginationKey } from '@atlassian/jira-relay/src/__generated__/issuePagination_issueNavigator.graphql';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import TotalIssueCount from '../../../total-issue-count/index.tsx';
import { useIssuesCount } from '../../../../../controllers/selected-issue/hooks.tsx';
import messages from './messages.tsx';
import IssuePaginationNextButton from './next-button/index.tsx';
import IssuePaginationPreviousButton from './previous-button/index.tsx';

export type Props = {
	/**
	 * Relay fragment for issue search data.
	 */
	issueResults: IssuePaginationKey | null;
	/**
	 * Position of the currently selected issue in the search results.
	 */
	// TODO Remove prop when cleaning up jira_spreadsheet_component_m1
	selectedIssuePosition?: number;
};

const IssuePagination = ({ issueResults, selectedIssuePosition }: Props) => {
	let issuesCount = 0;
	if (isRefactorNinToViewSchemaEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		issuesCount = useIssuesCount();
	}

	const issueResultsData = useFragment<IssuePaginationKey>(
		graphql`
			fragment issuePagination_issueNavigator on JiraIssueConnection {
				totalIssueSearchResultCount @required(action: THROW)
				isCappingIssueSearchResult
			}
		`,
		issueResults,
	);

	const isCappingIssueSearchResult = issueResultsData?.isCappingIssueSearchResult ?? false;
	const totalIssueSearchResultCount = issueResultsData?.totalIssueSearchResultCount || issuesCount;

	return (
		<>
			{!expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) && (
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				<PaginationTextContainer data-testid="issue-pagination.pagination-text">
					{totalIssueSearchResultCount <= 0 ? (
						selectedIssuePosition
					) : (
						<TotalIssueCount
							prependMessage={
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.issueIndexTotalKnownIssueTermRefresh
									: messages.issueIndexTotalKnown
							}
							prependMessageValues={{
								issueIndex: selectedIssuePosition,
							}}
							isCapped={isCappingIssueSearchResult}
							stableCount={totalIssueSearchResultCount}
						/>
					)}
				</PaginationTextContainer>
			)}
			<ButtonGroup>
				<IssuePaginationPreviousButton selectedIssuePosition={selectedIssuePosition} />
				<IssuePaginationNextButton
					totalResultCount={totalIssueSearchResultCount}
					selectedIssuePosition={selectedIssuePosition}
					isCappingIssueSearchResult={isCappingIssueSearchResult}
				/>
			</ButtonGroup>
		</>
	);
};

export default IssuePagination;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationTextContainer = styled.span({
	display: 'flex',
	alignItems: 'center',
});
