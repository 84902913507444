import { useSelectedViewState } from '@atlassian/jira-issue-navigator/src/controllers/selected-view-state/index.tsx';
import { useSidebar } from '@atlassian/jira-platform-sidebar/src/controllers/use-sidebar/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';

export const SidebarCloserController = () => {
	const [{ id }, { closeSidebar }] = useSidebar();
	const [{ view }] = useSelectedViewState();
	const prevView = usePrevious(view);

	if (id !== null && view === 'detail' && prevView !== 'detail') {
		closeSidebar();
	}

	return null;
};
