/**
 * @generated SignedSource<<413e774d04809c5372ccc3674da92a96>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_DetailView_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardContainer_issueNavigator_view" | "main_issueNavigator_IssueAppWithData_view">;
  readonly " $fragmentType": "main_issueNavigator_DetailView_view";
};
export type main_issueNavigator_DetailView_view$key = {
  readonly " $data"?: main_issueNavigator_DetailView_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_DetailView_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cardContainer_issueNavigator_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData_view"
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};

(node as any).hash = "68ca158b865b96f58c529de1ab919b9b";

export default node;
