/**
 * @generated SignedSource<<a30de7d3e4cbf0e20f5cf1656b1b33ec>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueTableHideDoneToggle_HideDoneItemsToggle$data = {
  readonly id: string;
  readonly viewConfigSettings?: {
    readonly hideDone: boolean | null | undefined;
  } | null | undefined;
  readonly viewId: string | null | undefined;
  readonly viewSettings?: {
    readonly hideDone: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueTableHideDoneToggle_HideDoneItemsToggle";
};
export type ui_issueTableHideDoneToggle_HideDoneItemsToggle$key = {
  readonly " $data"?: ui_issueTableHideDoneToggle_HideDoneItemsToggle$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableHideDoneToggle_HideDoneItemsToggle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "staticViewInput",
  "variableName": "staticViewInput"
},
v1 = [
  {
    "kind": "ScalarField",
    "name": "hideDone"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "groupBy"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueTableHideDoneToggle_HideDoneItemsToggle",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "viewId"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": [
            (v0/*: any*/)
          ],
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewConfigSettings",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ],
      "type": "JiraIssueSearchView"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "groupBy",
              "variableName": "groupBy"
            },
            {
              "kind": "Variable",
              "name": "issueSearchInput",
              "variableName": "issueSearchInput"
            },
            (v0/*: any*/)
          ],
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewSettings",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};
})();

(node as any).hash = "94d60d94a61db421c7d03592ff2de327";

export default node;
