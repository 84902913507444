// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = 748pv5qrn493bqk8kv9zvsnq
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/748pv5qrn493bqk8kv9zvsnq
import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import type { SVGViewBox } from '@atlassian/jira-illustration/src/ui/types.tsx';
import srcDark from '../../assets/search-no-results-old-darkmode.svg';
import srcLight from '../../assets/search-no-results-old.svg';

/** @deprecated cf. https://hello.atlassian.net/wiki/spaces/IMKT/pages/743859674/Empty+state+and+Error+state+guidelines */
const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 208, 190];
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { srcLight, srcDark, viewBox };
