import React, { memo, type ComponentType } from 'react';
import { EntryPointContainer } from 'react-relay';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { EntryPointReferencePlaceholder } from '@atlassian/jira-entry-point-placeholder/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { IssueTableSkeleton } from '../common/ui/skeleton/index.tsx';
import type { Props } from './types.tsx';

const AsyncNativeIssueTable = lazyForPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-issue-navigator-native-issue-table" */ './index'),
);

const AsyncNativeIssueTableOld: ComponentType<Props> = memo<Props>((props: Props) => (
	<Placeholder fallback={<IssueTableSkeleton />} name="async-native-issue-table">
		<AsyncNativeIssueTable {...props} />
	</Placeholder>
));

const AsyncNativeIssueTableNew = memo<Props>((props) => {
	const entryPointReference = props.preloadedEntryPoints?.nativeIssueTableEntryPoint;

	if (entryPointReference && fg('jira_list_issue_table_entrypoint')) {
		return (
			<UFOSegment name="native-issue-table-entrypoint">
				<JSErrorBoundary id="native-issue-table-entrypoint" packageName="jiraNativeIssueTable">
					<EntryPointReferencePlaceholder
						name="native-issue-table"
						fallback={<IssueTableSkeleton />}
						entryPointReference={entryPointReference}
					>
						<EntryPointContainer
							entryPointReference={entryPointReference}
							// EntryPointContainer can't infer the type of props from entryPointReference correctly
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							props={props as never}
						/>
					</EntryPointReferencePlaceholder>
				</JSErrorBoundary>
			</UFOSegment>
		);
	}

	return <AsyncNativeIssueTableOld {...props} />;
});

export default AsyncNativeIssueTableNew;
