import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { updateHideDoneToggleMutation } from '@atlassian/jira-relay/src/__generated__/updateHideDoneToggleMutation.graphql.ts';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import messages from './messages.tsx';

export const useUpdateHideDoneMutation = () => {
	const isAnonymous = useIsAnonymous();

	const [commit] = useMutation<updateHideDoneToggleMutation>(graphql`
		mutation updateHideDoneToggleMutation($viewId: ID!, $isHideDoneEnabled: Boolean!) {
			jira {
				updateIssueSearchHideDonePreference(viewId: $viewId, isHideDoneEnabled: $isHideDoneEnabled)
					@optIn(to: "JiraUpdateIssueSearchHideDonePreference") {
					success
				}
			}
		}
	`);

	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const updateHideDone = useCallback(
		({
			issueSearchViewResultAri,
			isHideDoneEnabled,
			onChangeHideDone,
		}: {
			issueSearchViewResultAri: string;
			isHideDoneEnabled: boolean;
			onChangeHideDone: (isHideDoneEnabled: boolean) => void;
		}) => {
			if (isAnonymous) {
				onChangeHideDone(isHideDoneEnabled);
				return;
			}

			commit({
				variables: {
					viewId: issueSearchViewResultAri,
					isHideDoneEnabled,
				},
				optimisticUpdater() {
					onChangeHideDone(isHideDoneEnabled);
				},
				onError: (error) => {
					fireErrorAnalytics({
						meta: {
							id: 'issue-table-hide-done-toggle',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						error,
					});

					showFlag({
						messageId:
							'issue-table-hide-done-toggle.services.update-hide-done-toggle.show-flag.error',
						messageType: 'transactional',
						id: 'hideDoneToggleError',
						title: formatMessage(messages.hideDoneErrorFlagTitle),
						description: formatMessage(messages.hideDoneErrorFlagDescription),
						isAutoDismiss: true,
						type: 'error',
					});
				},
			});
		},
		[commit, formatMessage, isAnonymous, showFlag],
	);

	return updateHideDone;
};
