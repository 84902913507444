import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const ifcEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-inline-field-config-with-apollo" */ '@atlassian/jira-business-inline-field-config-sidebar/src/ui/inline-field-config-content/main-with-apollo'
			),
	),
	getPreloadProps: () => ({}),
});
