import React from 'react';
import { Flex, Text, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { useIntl } from '@atlassian/jira-intl';
import ErrorTriangle from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/error/components/error-triangle-basic/index.tsx';
import messages from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const ErrorState = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Flex direction="column" alignItems="stretch">
			<Flex direction="column" alignItems="end">
				<Flex gap="space.100">
					<IconButton
						appearance="subtle"
						icon={CrossIcon}
						label={formatMessage(messages.close)}
						isTooltipDisabled={false}
						onClick={() => {
							onClose();
						}}
					/>
				</Flex>
			</Flex>
			<Flex direction="column" alignItems="center" xcss={contentStyles}>
				<ErrorTriangle alt={formatMessage(messages.warning)} />
				<Text weight="bold">{formatMessage(messages.errorTitle)}</Text>
				<Text align="center">{formatMessage(messages.errorBody)}</Text>
			</Flex>
		</Flex>
	);
};

const contentStyles = xcss({
	marginTop: 'space.800',
	gap: 'space.100',
});
