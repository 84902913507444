/**
 * @generated SignedSource<<00786fd07d799d59d54a6fe98c42ac89>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topBar_issueNavigator_jiraViewResult$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"hierarchyToggle_issueNavigator_HierarchyToggleItem" | "src_issueTableHideDoneToggle_HideDoneItemsToggle" | "ui_issueTableGroupByDropdown_GroupByDropdown">;
  readonly " $fragmentType": "topBar_issueNavigator_jiraViewResult";
};
export type topBar_issueNavigator_jiraViewResult$key = {
  readonly " $data"?: topBar_issueNavigator_jiraViewResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"topBar_issueNavigator_jiraViewResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "topBar_issueNavigator_jiraViewResult",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem"
        },
        {
          "kind": "FragmentSpread",
          "name": "ui_issueTableGroupByDropdown_GroupByDropdown"
        },
        {
          "kind": "FragmentSpread",
          "name": "src_issueTableHideDoneToggle_HideDoneItemsToggle"
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraView",
  "abstractKey": "__isJiraView"
};

(node as any).hash = "a15fc2f8358d2528f544588b8d27263d";

export default node;
