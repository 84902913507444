import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	moreActionsLabel: {
		id: 'issue-navigator-action-meatball-menu.more-actions-label',
		defaultMessage: 'More actions',
		description: 'Accessibility label of the meatball menu button.',
	},
	actionsOptOutDisplayText: {
		id: 'issue-navigator-action-meatball-menu.actions-opt-out-display-text',
		defaultMessage: 'Go back to the old issue search',
		description: 'meatball menu item display text for opt out of NIN',
	},
	actionsGiveFeedbackDisplayText: {
		id: 'issue-navigator-action-meatball-menu.actions-give-feedback-display-text',
		defaultMessage: 'Give feedback',
		description: 'meatball menu item display text giving feedback for NIN',
	},
	feedbackTitle: {
		id: 'issue-navigator-action-meatball-menu.feedback-title',
		defaultMessage: 'Give feedback for issue search',
		description:
			'Title of the modal dialog where users can share feedback about the issue search experience',
	},
	feedbackTitleDetails: {
		id: 'issue-navigator-action-meatball-menu.feedback-title-details',
		defaultMessage: 'Let us know how we can improve this experience.',
		description:
			'Description of a modal dialog where users can leave feedback for the issue search experience',
	},
	feedbackTitleDetailsContactSupport: {
		id: 'issue-navigator-action-meatball-menu.feedback-title-details-contact-support',
		defaultMessage: 'If you’re having technical issues, contact our support team.',
		description:
			'Message hinting the user to contact the support team if they are trying to report a bug from a feedback dialog',
	},
	feedbackTypePlaceholder: {
		id: 'issue-navigator-action-meatball-menu.feedback-type-placeholder',
		defaultMessage: 'Select an option',
		description: 'Placeholder of the dropdown component where users can select a feedback category',
	},
	bulkChangeIssues: {
		id: 'issue-navigator-action-meatball-menu.bulk-change-issues',
		defaultMessage: 'Bulk change issues',
		description: 'Text for the bulk change issues link in the meatball menu',
	},
	viewIssuesAsAChart: {
		id: 'issue-navigator-action-meatball-menu.view-issues-as-a-chart',
		defaultMessage: 'View issues as a chart',
		description:
			'Dropdown item to open a sidebar allowing users to visualise their issues as a chart',
	},
	viewWorkItemsAsAChart: {
		id: 'issue-navigator-action-meatball-menu.view-issues-as-a-chart-issue-term-refresh',
		defaultMessage: 'View work items as a chart',
		description:
			'Dropdown item to open a sidebar allowing users to visualise their work items as a chart',
	},
	export: {
		id: 'issue-navigator-action-meatball-menu.export',
		defaultMessage: 'Export',
		description: 'Text for the export option in the meatball menu',
	},
	newLozengeLabel: {
		id: 'issue-navigator-action-meatball-menu.new-lozenge-label',
		defaultMessage: 'New',
		description:
			'Label for a lozenge indicating an item in the meatball menu is new, for discoverability purposes.',
	},
});

export default messages;
