import React, { useCallback, useMemo, useState } from 'react';
import Button from '@atlaskit/button';
import { fg } from '@atlassian/jira-feature-gating';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Attributes } from '../../common/types/index.tsx';
import { saveFilterDialogEntryPoint } from './entrypoint.tsx';
import { AsyncSaveFilterDialogContent } from './save-filter-dialog-content/async.tsx';

export type Props = {
	attributes?: Attributes;
	jql: string;
	filterId?: string;
	buttonText: string;
	title: string;
	onFilterSave?: (filterId: string) => void;
};

export const SaveFilterDialog = ({ buttonText, filterId, ...props }: Props) => {
	if (!fg('nin-save-filter-dialog-entry-point')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isOpen, setIsOpen] = useState(false);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const openModal = useCallback(
			(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'saveFilterDialog');
				setIsOpen(true);
			},
			[setIsOpen],
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

		return (
			<>
				<Button
					onClick={openModal}
					appearance="link"
					testId="save-filter-dialog.ui.save-filter-dialog.button"
				>
					{buttonText}
				</Button>
				<AsyncSaveFilterDialogContent
					onClose={closeModal}
					filterId={filterId}
					{...props}
					isOpen={isOpen}
				/>
			</>
		);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const modalProps = useMemo(
		() => ({
			width: 'medium',
			shouldScrollInViewport: true,
		}),
		[],
	);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const entryPointProps = useMemo(
		() => ({
			filterId,
			...props,
		}),
		[filterId, props],
	);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const cloudId = useCloudId();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const activationId = useActivationId();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const entryPointParams = useMemo(
		() => ({
			filterAri:
				filterId !== null && filterId !== undefined
					? createAri({
							resourceOwner: 'jira',
							cloudId,
							resourceType: 'filter',
							resourceId: filterId,
							activationId,
						})
					: undefined,
		}),
		[filterId, cloudId, activationId],
	);

	return (
		<ModalEntryPointPressableTrigger
			modalProps={modalProps}
			entryPoint={saveFilterDialogEntryPoint}
			entryPointProps={entryPointProps}
			entryPointParams={entryPointParams}
			useInternalModal
		>
			{({ ref }) => (
				<Button
					onClick={(_, analyticsEvent) => fireUIAnalytics(analyticsEvent, 'saveFilterDialog')}
					appearance="link"
					testId="save-filter-dialog.ui.save-filter-dialog.button"
					interactionName="save-filter-dialog"
					ref={ref}
				>
					{buttonText}
				</Button>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export default SaveFilterDialog;
