/**
 * @generated SignedSource<<9447c66ceb43590970549d094be1fa98>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly issueId: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore";
};
export type bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key = {
  readonly " $data"?: bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$data;
  readonly " $fragmentSpreads": FragmentRefs<"bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "3ef50a3a9b158093691740d555608365";

export default node;
