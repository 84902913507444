/**
 * @generated SignedSource<<edb4dc30383db0f553ab844a357e2afa>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_project$data = {
  readonly projectType: JiraProjectType | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_project">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_project";
};
export type main_issueNavigator_IssueResults_project$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_ListView_project"
    },
    {
      "kind": "ScalarField",
      "name": "projectType"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "d2d4ef4e95db7c6e705ce7f23b5f0a5b";

export default node;
