import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DetailsButtonHeaderWrapper = styled.span({
	font: token('font.body'),
	marginTop: token('space.025'),
	marginRight: token('space.050'),
	marginBottom: 0,
	marginLeft: token('space.050'),
});
