/**
 * @generated SignedSource<<038d52b681a72d58f8f533420cf580f4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_groupedListViewData$data = {
  readonly groups: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    } | null | undefined> | null | undefined;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly startCursor: string | null | undefined;
    };
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_groupResults">;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_groupedListViewData";
};
export type main_issueNavigator_IssueNavigator_groupedListViewData$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_groupedListViewData$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_groupedListViewData">;
};

import IssueNavigatorGroupedListViewPaginationQuery_graphql from './IssueNavigatorGroupedListViewPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "groups"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsContext"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "groupBy"
    },
    {
      "kind": "RootArgument",
      "name": "isPaginating"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "kind": "RootArgument",
      "name": "scope"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": IssueNavigatorGroupedListViewPaginationQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "main_issueNavigator_IssueNavigator_groupedListViewData",
  "selections": [
    {
      "alias": "groups",
      "args": [
        {
          "kind": "Variable",
          "name": "issueSearchInput",
          "variableName": "issueSearchInput"
        }
      ],
      "concreteType": "JiraSpreadsheetGroupConnection",
      "kind": "LinkedField",
      "name": "__IssueNavigatorGroupedListViewPagination__groups_connection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSpreadsheetGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "cursor"
            },
            {
              "concreteType": "JiraSpreadsheetGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            }
          ]
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "endCursor"
            },
            {
              "kind": "ScalarField",
              "name": "startCursor"
            },
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            },
            {
              "kind": "ScalarField",
              "name": "hasPreviousPage"
            }
          ]
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "projectKey",
              "variableName": "projectKey"
            },
            {
              "kind": "Variable",
              "name": "projectKeys",
              "variableName": "projectKeys"
            }
          ],
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_IssueNavigatorUI_groupResults"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "JiraGroupedListView"
};
})();

(node as any).hash = "899edbd4ab306cc11af57fd78c9fef95";

export default node;
