/**
 * @generated SignedSource<<5eec9cb87961d4f938c8648d180bee8c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueTableGroupByDropdown_GroupByDropdown$data = {
  readonly filterId: string | null | undefined;
  readonly id: string;
  readonly jql: string | null | undefined;
  readonly viewSettings: {
    readonly canEnableGrouping: boolean | null | undefined;
    readonly groupByConfig: {
      readonly groupByField: {
        readonly fieldId: string | null | undefined;
        readonly name: string | null | undefined;
        readonly typeKey: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly isGroupingEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueTableGroupByDropdown_GroupByDropdown";
};
export type ui_issueTableGroupByDropdown_GroupByDropdown$key = {
  readonly " $data"?: ui_issueTableGroupByDropdown_GroupByDropdown$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableGroupByDropdown_GroupByDropdown">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "groupBy"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueTableGroupByDropdown_GroupByDropdown",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "ScalarField",
      "name": "jql"
    },
    {
      "kind": "ScalarField",
      "name": "filterId"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "groupBy",
          "variableName": "groupBy"
        },
        {
          "kind": "Variable",
          "name": "issueSearchInput",
          "variableName": "issueSearchInput"
        },
        {
          "kind": "Variable",
          "name": "staticViewInput",
          "variableName": "staticViewInput"
        }
      ],
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canEnableGrouping"
        },
        {
          "kind": "ScalarField",
          "name": "isGroupingEnabled"
        },
        {
          "concreteType": "JiraSpreadsheetGroupByConfig",
          "kind": "LinkedField",
          "name": "groupByConfig",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraField",
              "kind": "LinkedField",
              "name": "groupByField",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "fieldId"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "typeKey"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetView",
  "abstractKey": "__isJiraSpreadsheetView"
};

(node as any).hash = "fb74039ef112118517cb2d8c0aacd6b0";

export default node;
