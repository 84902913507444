import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import SearchNoResultsImageOld from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results/index.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export const EmptyList = ({ intl: { formatMessage } }: Intl) => {
	const noIssues = formatMessage(
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.noIssuesIssueTermRefresh
			: messages.noIssues,
	);

	return (
		<>
			{isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? (
				<Box xcss={ImageWrapperStyles}>
					<SearchNoResultsImage alt="" />
				</Box>
			) : (
				<Box xcss={ImageWrapperStylesOld}>
					<SearchNoResultsImageOld alt="" />
				</Box>
			)}
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{noIssues}</p>
		</>
	);
};

/* eslint-disable-next-line jira/js/no-inject-intl-if-functional-component */
export default injectIntl(EmptyList);

const ImageWrapperStylesOld = xcss({
	height: '146px',
	width: '160px',
});

const ImageWrapperStyles = xcss({
	height: '165px',
	width: '150px',
});
