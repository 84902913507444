/**
 * @generated SignedSource<<1de1445498b44672cc6e64f801a2e3c6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_FullPageIssueApp_view$data = {
  readonly fieldSets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fieldSetId: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueAppWithData_view">;
  readonly " $fragmentType": "main_issueNavigator_FullPageIssueApp_view";
};
export type main_issueNavigator_FullPageIssueApp_view$key = {
  readonly " $data"?: main_issueNavigator_FullPageIssueApp_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_FullPageIssueApp_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_FullPageIssueApp_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData_view"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "fieldSetSelectedState": "SELECTED"
          }
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "amountOfColumns"
        }
      ],
      "concreteType": "JiraIssueSearchFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSets",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueSearchFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueSearchFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "fieldSetId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};

(node as any).hash = "9486a61169223702f49af2fa40c90f38";

export default node;
