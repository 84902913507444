import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showHierarchy: {
		id: 'issue-table-show-hierarchy',
		defaultMessage: 'Show hierarchy',
		description: 'Text shown in the label for toggle to enable/disable the hierarchy view',
	},
	hierarchyDisabledReasonGroupingAndFilters: {
		id: 'issue-table-hierarchy-disabled-reason-grouping-and-filters',
		defaultMessage: 'Clear grouping and filters to show hierarchy',
		description:
			'Text shown under hierarchy toggle when hierarchy is disabled because of grouping and filters',
	},
	hierarchyDisabledReasonFilters: {
		id: 'issue-table-hierarchy-disabled-reason-filters',
		defaultMessage: 'Clear filters to show hierarchy',
		description: 'Text shown under hierarchy toggle when hierarchy is disabled because of filters',
	},
	hierarchyErrorFlagTitle: {
		id: 'issue-table-hierarchy-error-flag-title',
		defaultMessage: 'Hierarchy preference could not be updated.',
		description: 'Text shown in the error flag when hierarchy toggle fails to update',
	},
	hierarchyErrorFlagDescription: {
		id: 'issue-table-hierarchy-error-flag-description',
		defaultMessage: 'Something went wrong. Please try again.',
		description: 'Text shown in the error flag when hierarchy toggle fails to update',
	},
});
