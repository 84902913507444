import React, { memo } from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIssueSearchQuery } from '../../../services/issue-search-query/index.tsx';
import {
	useIssueSearchActions,
	useTotalIssueCount,
	useTotalIssueCountIsError,
	useTotalIssueCountIsFetching,
} from '../../../services/issue-search/selectors.tsx';
import LoadUncappedCountButton from './load-uncapped-count-button/index.tsx';
import messages from './messages.tsx';

export type Props = {
	/**
	 * A message descripter containing any text that should be prefixed to the total count.
	 * Example - a message containing page ranges:
	 * ```
	 * {
	 *  id: 'issue-navigator.card-container.paginator.issue-range-known',
	 *  defaultMessage: '{startIssueRange}-{endIssueRange} of {totalNumberIssues}',
	 *  description: 'Text indicating the shown range out of the total number of issues.',
	 * }
	 * ```
	 */
	prependMessage: MessageDescriptorV2;
	/**
	 * Any values contained within `prependMessage` prop.
	 *
	 * **NOTE - a value with key `totalNumberIssues` will automatically be appended to this prop.**
	 *
	 * Example - Given this `prependMessage` prop:
	 * ```
	 * {
	 *  id: 'issue-navigator.card-container.paginator.issue-range-known',
	 *  defaultMessage: '{startIssueRange}-{endIssueRange} of {totalNumberIssues}',
	 *  description: 'Text indicating the shown range out of the total number of issues.',
	 * }
	 * ```
	 *
	 * `prependMessageValues` prop would look like this:
	 *
	 * ```
	 * {
	 *   startIssueRange: 1,
	 *   endIssueRange: 50
	 * }
	 * ```
	 *
	 * This component will then mutate the prop by adding the total count:
	 * ```
	 * {
	 *   startIssueRange: 1,
	 *   endIssueRange: 50,
	 *   totalNumberIssues: '1000+'
	 * }
	 * ```
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prependMessageValues: Record<string, any>;
	/**
	 * Indicates if the `stableCount` prop is capped. When a count is capped, the user will
	 * be given the option of loading the uncapped count
	 *
	 * TODO - Refactor this component to use relay fragments and this prop can be replaced with fragment data
	 */
	isCapped: boolean;
	/**
	 * Contains the (possibly capped) total count. This value is derived from the issueSearchStable query
	 *
	 * TODO - Refactor this component to use relay fragments and this prop can be replaced with fragment data
	 */
	stableCount: number;
};

const TotalIssueCount = ({
	prependMessage,
	prependMessageValues,
	isCapped,
	stableCount,
}: Props) => {
	const { formatMessage } = useIntl();
	let totalIssueCount;
	let totalIssueCountIsFetching;
	let totalIssueCountIsError;
	let onFetchTotalIssueCount;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		totalIssueCount = useTotalIssueCount();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		totalIssueCountIsFetching = useTotalIssueCountIsFetching();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		totalIssueCountIsError = useTotalIssueCountIsError();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onFetchTotalIssueCount } = useIssueSearchActions());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { uncappedTotalIssueCount, onFetchUncappedTotalIssueCount } = useIssueSearchQuery();
		totalIssueCount = uncappedTotalIssueCount.count;
		totalIssueCountIsFetching = uncappedTotalIssueCount.isFetching;
		totalIssueCountIsError = uncappedTotalIssueCount.isError;
		onFetchTotalIssueCount = onFetchUncappedTotalIssueCount;
	}

	if (isCapped) {
		if (totalIssueCountIsError) {
			return (
				<>
					<Box as="span">
						{formatMessage(prependMessage, {
							...prependMessageValues,
							totalNumberIssues: `${stableCount}+`,
						})}
					</Box>
					<Tooltip
						position="top"
						content={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.errorTooltipTextIssueTermRefresh
								: messages.errorTooltipText,
						)}
					>
						{(tooltipProps) => (
							<ErrorIconContainer
								{...tooltipProps}
								data-testid="issue-navigator.common.ui.total-issue-count.error-icon"
							>
								<ErrorIcon
									spacing="spacious"
									label={formatMessage(
										expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
											? messages.errorIconLabelIssueTermRefresh
											: messages.errorIconLabel,
									)}
								/>
							</ErrorIconContainer>
						)}
					</Tooltip>
					<FireUiAnalytics
						action="viewed"
						actionSubject="errorIcon"
						actionSubjectId="totalIssueCount"
					/>
				</>
			);
		}

		if (totalIssueCountIsFetching) {
			return (
				<>
					<Box as="span">
						{formatMessage(prependMessage, {
							...prependMessageValues,
							totalNumberIssues: '',
						})}
					</Box>
					<SpinnerContainer>
						<Spinner size="small" testId="issue-navigator.common.ui.total-issue-count.spinner" />
					</SpinnerContainer>
				</>
			);
		}

		if (!totalIssueCount) {
			return (
				<Box as="span">
					{formatMessage(prependMessage, {
						...prependMessageValues,
						totalNumberIssues: (
							<LoadUncappedCountButton
								displayText={`${stableCount}+`}
								onFetchCount={onFetchTotalIssueCount}
							/>
						),
					})}
				</Box>
			);
		}

		return (
			<Box as="span">
				{formatMessage(prependMessage, {
					...prependMessageValues,
					totalNumberIssues: totalIssueCount,
				})}
			</Box>
		);
	}

	return (
		<Box as="span">
			{formatMessage(prependMessage, {
				...prependMessageValues,
				totalNumberIssues: stableCount,
			})}
		</Box>
	);
};

export default memo(TotalIssueCount);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconContainer = styled.span({
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`, // For vertical alignment with total count value
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.span({
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	'margin-bottom': '3px', // For vertical alignment with total count value
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'margin-left': `${gridSize / 2}px`, // adds space between count text and the spinner. E.g. 1-50 of <spinner>
});
