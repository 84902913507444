/**
 * @generated SignedSource<<bb430c501ccce22cd8ca816174850f68>>
 * @relayHash da2409ae2bc56c51b0f8898e8ba8c588
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4f80926256dac19536f6b2057ab2e6fe8c357e6ab85002583f57fdbb921f12d7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcFullCoreQuery } from './srcFullCoreQuery.graphql';

const node: PreloadableConcreteRequest<srcFullCoreQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4f80926256dac19536f6b2057ab2e6fe8c357e6ab85002583f57fdbb921f12d7",
    "metadata": {},
    "name": "srcFullCoreQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
