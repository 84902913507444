import React, { type ReactElement, type ReactNode } from 'react';
import { styled, jsx } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Text, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type Props = {
	children: ReactElement;
};

const IconContainerConditional = ({ children }: Props) => {
	if (isVisualRefreshEnabled()) {
		return children;
	}
	return <IconContainer>{children}</IconContainer>;
};

const MessageContainer = ({ icon, children }: { icon: ReactElement; children: ReactNode[] }) => {
	const { formatMessage } = useIntl();

	if (isVisualRefreshEnabled()) {
		return (
			<Stack space="space.150" xcss={stackStyles} alignInline="center">
				{icon}
				<Text color="color.text">{formatMessage(messages.noOptionsPrimaryMessage)}</Text>
				<Text color="color.text.subtle" size="small">
					{formatMessage(messages.noOptionsSecondaryMessage)}
				</Text>

				{children}
			</Stack>
		);
	}
	return (
		<Container>
			{icon}
			<StyledTitle>{formatMessage(messages.title)}</StyledTitle>
			{children}
		</Container>
	);
};
/**
 * Custom message to show when no options are visible in the column picker. This allows additional help text/content to
 * be rendered using the children prop.
 */
const NoOptionsMessage = ({ children }: { children?: ReactNode }) => {
	return (
		<MessageContainer
			icon={
				<IconContainerConditional>
					<ErrorIcon label="" color={token('color.icon')} LEGACY_primaryColor="currentColor" />
				</IconContainerConditional>
			}
		>
			{children}
			<FireUiAnalytics actionSubject="emptyState" action="viewed" actionSubjectId="columnPicker" />
		</MessageContainer>
	);
};

export default NoOptionsMessage;

const stackStyles = xcss({
	paddingBlock: 'space.150',
	paddingInline: 'space.100',
	// this is for the children additional help text/content
	color: 'color.text.subtlest',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${token('space.100')} ${token('space.250')} ${gridSize * 1.2}px ${token('space.250')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginBottom: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '72px',
		height: '72px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTitle = styled.div({
	color: token('color.text'),
	marginBottom: token('space.100'),
});
