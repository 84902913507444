/**
 * @generated SignedSource<<7aa0785e47fc94a3e1a4af12f45faa84>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_issueQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryNew" | "main_issueNavigator_IssueNavigator_refetchQueryOld">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_issueQuery";
};
export type main_issueNavigator_IssueNavigator_issueQuery$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_issueQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigator_issueQuery",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_IssueNavigator_refetchQueryOld"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "projectKey",
              "variableName": "projectKey"
            },
            {
              "kind": "Variable",
              "name": "projectKeys",
              "variableName": "projectKeys"
            }
          ],
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_IssueNavigator_refetchQueryNew"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "0b187435848f6b4a422a2b897dcb841c";

export default node;
