import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	groupByLabelWithoutSelection: {
		id: 'issue-table-group-by-dropdown.group-by-label-without-selection',
		defaultMessage: 'Group',
		description: 'Label for the dropdown allowing users to select what way to group issues by',
	},
	groupByLabelWithASelection: {
		id: 'issue-table-group-by-dropdown.group-by-label-with-a-selection',
		defaultMessage: 'Group: {groupByField}',
		description:
			'Label for the dropdown allowing users to select what way to group issues by when a user has selected a grouping',
	},
	cantGroupIssuesContent: {
		id: 'issue-table-group-by-dropdown.cant-group-issues-content',
		defaultMessage:
			'We can only group issues when you filter issues from one project. Update your JQL filter and try again.',
		description: 'Label for the dropdown option indicating that issues cannot be grouped',
	},
	cantGroupIssuesContentIssueTermRefresh: {
		id: 'issue-table-group-by-dropdown.cant-group-issues-content-issue-term-refresh',
		defaultMessage:
			'We can only group work items when you filter work items from one project. Update your JQL filter and try again.',
		description: 'Label for the dropdown option indicating that work items cannot be grouped',
	},
	entrypointErrorContent: {
		id: 'issue-table-group-by-dropdown.entrypoint-error-content',
		defaultMessage: 'Something went wrong. Try loading the content again.',
		description: 'Content displayed when the group by dropdown fails to load',
	},
});
