import React from 'react';
import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createHook,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { STORE_NAME } from '../../common/constants.tsx';
import type { State } from '../../common/types.tsx';
import { actions } from './actions.tsx';

export type SidebarContainerProps = {
	scope: string;
	sidebarId?: string; // Sidebar id should be in pascal case, e.g. "IssueNavigator"
	createAnalyticsEvent?: (_payload: AnalyticsEventPayload) => UIAnalyticsEvent;
	children: React.ReactNode;
};

export const InnerSidebarContainer = createContainer<SidebarContainerProps>();

export const SidebarContainer = ({ children, scope, sidebarId }: SidebarContainerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<InnerSidebarContainer
			scope={scope}
			sidebarId={sidebarId}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			{children}
		</InnerSidebarContainer>
	);
};

const Store = createStore<State, typeof actions, SidebarContainerProps>({
	name: STORE_NAME,
	containedBy: InnerSidebarContainer,
	initialState: {
		id: null,
		content: null,
	},
	actions,
});

export const useSidebar = createHook(Store);

export const useSidebarActions = createActionsHook(Store);
