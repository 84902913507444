/**
 * @generated SignedSource<<8169ecad465e715815c2295aecc1f51a>>
 * @relayHash cef0af5f1ffb6c1746574c08900d7ecb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 64da94b349b17c84216ef81da914f33538077ebcf1438ac672fad245104ad62d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_StatisticsSidebarQuery } from './ui_StatisticsSidebarQuery.graphql';

const node: PreloadableConcreteRequest<ui_StatisticsSidebarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "64da94b349b17c84216ef81da914f33538077ebcf1438ac672fad245104ad62d",
    "metadata": {},
    "name": "ui_StatisticsSidebarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
