import React, { useCallback, useMemo } from 'react';
import { fromFlagId, type FlagId, useFlagsService } from '@atlassian/jira-flags';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import JiraErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	id: FlagId;
	errors: string[];
} & Partial<MaybeChoreographedComponentProps>;

export const ErrorFlag = ({
	id,
	errors,
	messageId = 'issue-navigator-actions-common.ui.error-flag.jira-error-flag',
	messageType = 'transactional',
}: Props) => {
	const { formatMessage } = useIntl();
	const { dismissFlag } = useFlagsService();

	const onDismissed = useCallback(() => dismissFlag(id), [dismissFlag, id]);

	const description = useMemo(() => errors.reduce((desc, error) => `${desc}\n${error}`), [errors]);

	return (
		<JiraErrorFlag
			id={fromFlagId(id)}
			onDismissed={onDismissed}
			title={formatMessage(messages.flagTitle)}
			description={description}
			messageId={messageId}
			messageType={messageType}
		/>
	);
};
