import camelCase from 'lodash/camelCase';

export const SIDEBAR_EXPERIENCE_NAME = 'issue-field-statistics';
export const SIDEBAR_NAME = 'statistics';

export const ISSUE_SEARCH_NAMESPACE = 'ISSUE_NAVIGATOR';

const AUTO_OPEN_SIDEBAR_KEY = 'sidebar';

// used in local storage
export const SELECTED_FIELD_ID_KEY = 'statistics-field';
export const SELECTED_CHART_TYPE_KEY = 'statistics-chart';

// used in url query params
export const SELECTED_FIELD_ID_QUERY_KEY = camelCase(SELECTED_FIELD_ID_KEY);
export const SELECTED_CHART_TYPE_QUERY_KEY = camelCase(SELECTED_CHART_TYPE_KEY);

export const CLEAR_ALL_QUERY_PARAMS = Object.freeze({
	[AUTO_OPEN_SIDEBAR_KEY]: undefined,
	[SELECTED_FIELD_ID_QUERY_KEY]: undefined,
	[SELECTED_CHART_TYPE_QUERY_KEY]: undefined,
});
