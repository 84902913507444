import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { graphql, useFragment } from 'react-relay';
import { mergeRefs } from 'use-callback-ref';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import Button from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss, Text, Flex } from '@atlaskit/primitives';
import { addUFOCustomData } from '@atlaskit/react-ufo/custom-data';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { ui_issueTableGroupByDropdown_GroupByDropdown$key } from '@atlassian/jira-relay/src/__generated__/ui_issueTableGroupByDropdown_GroupByDropdown.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import {
	GROUP_BY_DROPDOWN_EXPERIENCE,
	ANALYTICS_SOURCE,
	TEAM_NAME,
	PACKAGE_NAME,
	ONBOARDING_TARGET_NAME,
} from '../common/constants.tsx';
import { LoadingContent } from '../common/ui/loading-content/index.tsx';
import { groupByDropdownEntryPoint } from './dropdown-content/entrypoint.tsx';
import messages from './messages.tsx';

export type GroupByDropdownProps = {
	spreadsheetView: ui_issueTableGroupByDropdown_GroupByDropdown$key;
	onChangeGroupedByField: (fieldId: string | null | undefined) => void;
};

const GroupByTriggerIconAfter = () => {
	if (getWillShowNav3()) {
		return (
			<Flex xcss={iconStyles}>
				<ChevronDownIcon label="" />
			</Flex>
		);
	}

	return null;
};

export const GroupByDropdown = ({
	spreadsheetView,
	onChangeGroupedByField,
}: GroupByDropdownProps) => {
	const { formatMessage } = useIntl();

	const onExperienceStart = useExperienceStart({
		experience: GROUP_BY_DROPDOWN_EXPERIENCE,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceFail = useExperienceFail({
		experience: GROUP_BY_DROPDOWN_EXPERIENCE,
	});

	useEffect(() => {
		onExperienceStart();
	}, [onExperienceStart]);

	const data = useFragment<ui_issueTableGroupByDropdown_GroupByDropdown$key>(
		graphql`
			fragment ui_issueTableGroupByDropdown_GroupByDropdown on JiraSpreadsheetView {
				id @required(action: THROW)
				jql
				filterId
				viewSettings(
					groupBy: $groupBy
					staticViewInput: $staticViewInput
					issueSearchInput: $issueSearchInput
				) {
					canEnableGrouping
					isGroupingEnabled
					groupByConfig {
						groupByField {
							fieldId
							name
							typeKey
						}
					}
				}
			}
		`,
		spreadsheetView,
	);

	const groupByFieldFromQuery = useMemo(
		() =>
			data?.viewSettings?.groupByConfig?.groupByField
				? {
						fieldId: data.viewSettings.groupByConfig.groupByField.fieldId,
						name: data.viewSettings.groupByConfig.groupByField.name,
					}
				: null,
		[data?.viewSettings?.groupByConfig?.groupByField],
	);
	const prevGroupByFieldFromQuery = useRef(groupByFieldFromQuery);
	const [groupByField, setGroupByField] = useState(groupByFieldFromQuery);

	// If group by field changes in the query response, update local state to keep it in sync
	if (!isEqual(groupByFieldFromQuery, prevGroupByFieldFromQuery.current)) {
		prevGroupByFieldFromQuery.current = groupByFieldFromQuery;
		setGroupByField(groupByFieldFromQuery);
	}

	const onUpdateGroupByField = useCallback(
		(newGroupByField: { fieldId: string; name: string } | null) => {
			if (groupByField?.fieldId !== newGroupByField?.fieldId) {
				setGroupByField(newGroupByField ?? null);
				onChangeGroupedByField(newGroupByField?.fieldId);
			}
		},
		[groupByField, onChangeGroupedByField],
	);

	const issueSearchInput =
		(data?.filterId && { filterId: data.filterId }) ||
		(data?.jql && { jql: data.jql }) ||
		undefined;

	const groupByFieldId = groupByField?.fieldId;
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		groupByDropdownEntryPoint,
		{
			nodeId: data.id,
			selectedFieldId: groupByFieldId,
			issueSearchInput,
		},
	);

	const buttonTrigger = useEntryPointButtonTrigger(entryPointActions);

	addUFOCustomData({
		groupByFieldId: data?.viewSettings?.groupByConfig?.groupByField?.typeKey,
		isGrouping: data?.viewSettings?.isGroupingEnabled,
	});

	const errorFallback = useCallback(
		({ error }: { error: Error }) => {
			fireErrorAnalytics({
				meta: {
					id: 'issue-table-group-by-dropdown',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			onExperienceFail(PACKAGE_NAME, error);
			return (
				<Box xcss={errorFallbackStyles}>
					<Text>{formatMessage(messages.entrypointErrorContent)}</Text>
				</Box>
			);
		},
		[formatMessage, onExperienceFail],
	);

	if (!data?.viewSettings?.canEnableGrouping) {
		return (
			<DropdownMenu<HTMLButtonElement>
				trigger={({ triggerRef, onClick, ...triggerProps }) => (
					<Button
						{...triggerProps}
						ref={triggerRef}
						iconAfter={GroupByTriggerIconAfter}
						onClick={(
							event: React.MouseEvent<HTMLButtonElement>,
							analyticsEvent: UIAnalyticsEvent,
						) => {
							fireUIAnalytics(
								analyticsEvent,
								'button clicked',
								'issueTableGroupByDropdownDisabledTrigger',
							);
							onClick && onClick(event);
						}}
					>
						{formatMessage(messages.groupByLabelWithoutSelection)}
					</Button>
				)}
				placement="bottom-end"
				shouldRenderToParent
				spacing="compact"
			>
				<Box xcss={cantGroupIssuesStyles}>
					<Text>
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.cantGroupIssuesContentIssueTermRefresh
								: messages.cantGroupIssuesContent,
						)}
					</Text>
				</Box>
			</DropdownMenu>
		);
	}

	return (
		<SpotlightManager>
			<SpotlightTarget name={ONBOARDING_TARGET_NAME}>
				<DropdownMenu<HTMLButtonElement>
					trigger={({ triggerRef, onClick, isSelected, ...triggerProps }) => (
						<Button
							{...triggerProps}
							isSelected={Boolean(groupByField) || isSelected}
							testId="issue-table-group-by-dropdown.ui.group-by-dropdown"
							ref={mergeRefs([triggerRef, buttonTrigger])}
							iconAfter={GroupByTriggerIconAfter}
							onClick={(
								event: React.MouseEvent<HTMLButtonElement>,
								analyticsEvent: UIAnalyticsEvent,
							) => {
								fireUIAnalytics(
									analyticsEvent,
									'button clicked',
									'issueTableGroupByDropdownTrigger',
								);
								onClick && onClick(event);
							}}
						>
							{groupByField?.name
								? formatMessage(messages.groupByLabelWithASelection, {
										groupByField: groupByField.name,
									})
								: formatMessage(messages.groupByLabelWithoutSelection)}
						</Button>
					)}
					placement="bottom-end"
					shouldRenderToParent
					spacing="compact"
				>
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="async-jira-spreadsheet-group-by-dropdown"
						packageName={PACKAGE_NAME}
						teamName={TEAM_NAME}
						errorFallback={errorFallback}
						runtimeProps={{ onUpdateGroupByField }}
						fallback={<LoadingContent />}
					/>
				</DropdownMenu>
			</SpotlightTarget>
			<EngagementSpotlight engagementId={ONBOARDING_TARGET_NAME} />
		</SpotlightManager>
	);
};

const cantGroupIssuesStyles = xcss({
	marginInline: 'space.300',
	marginBlock: 'space.200',
	width: '250px',
});

const errorFallbackStyles = xcss({
	padding: 'space.200',
	width: '250px',
});

const iconStyles = xcss({
	marginRight: 'space.negative.075',
});
