/**
 * @generated SignedSource<<58f0caa04f54df88b02dd1066d15c43f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_issueResults$data = {
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly issueSearchError: {
    readonly __typename: string;
  } | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore" | "jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults" | "main_issueNavigator_FullPageIssueApp_issueResults" | "main_issueNavigator_Header_issueResults" | "main_issueNavigator_IssueResults_issueResults" | "topBar_issueNavigator_issueResults">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_issueResults";
};
export type main_issueNavigator_IssueNavigatorUI_issueResults$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_issueResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "topBar_issueNavigator_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_Header_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        },
        {
          "kind": "Variable",
          "name": "shouldQueryHasChildren",
          "variableName": "shouldQueryHasChildren"
        }
      ],
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_FullPageIssueApp_issueResults"
    },
    {
      "kind": "LinkedField",
      "name": "issueSearchError",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "ac60e0f31f3a7da92f2e220df7713938";

export default node;
