import React, { type ReactNode, useMemo, useRef } from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { createDebouncedColumnLoader } from '@atlassian/jira-issue-table-column-picker/src/common/constants.tsx';
import type {
	OptionResponse,
	SelectValue,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import { getSecondaryLabelWithIcon } from '@atlassian/jira-jql-builder-basic-picker/src/common/utils/get-secondary-label-with-icon.tsx';
import { FieldTypeIcon } from '@atlassian/jira-project-settings-apps-common/src/icon-map.tsx';
import type {
	fetchColumnPickerOptionsQuery,
	fetchColumnPickerOptionsQuery$data,
} from '@atlassian/jira-relay/src/__generated__/fetchColumnPickerOptionsQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ISSUE_SEARCH_NAMESPACE, PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import type { IssueNavigatorViewId } from '../../common/types.tsx';
import { isNonNullish } from '../../common/utils/index.tsx';
import messages from './messages.tsx';
import { getCursorOfLastUnselectedOption, isOnlyUnselectableField } from './utils.tsx';

type Node = {
	cursor: string;
	node: {
		displayName: string;
		fieldSetId: string;
		type: string;
		fieldType: {
			displayName: string | null;
		};
	};
};

const mapColumnPickerOptions = (
	data: fetchColumnPickerOptionsQuery$data | undefined,
	search: string,
	intl: IntlShape,
	selectedColumns: SelectValue,
	canEditColumns: boolean,
	isIFCEnabled?: boolean,
): OptionResponse => {
	let totalCount = 0;
	let edges;
	let endCursor: string | undefined;

	if (!isIFCEnabled && data?.jira.issueSearchViewByNamespaceAndViewId?.fieldSets) {
		totalCount = data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.totalCount;
		edges = data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.edges;
		endCursor = data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.pageInfo?.endCursor
			? data?.jira.issueSearchViewByNamespaceAndViewId.fieldSets.pageInfo.endCursor
			: undefined;
	}

	if (isIFCEnabled && data?.jira.issueSearchViewResult?.fieldSets) {
		totalCount = data?.jira.issueSearchViewResult.fieldSets.totalCount;
		edges = data?.jira.issueSearchViewResult.fieldSets.edges;
		endCursor = data?.jira.issueSearchViewResult.fieldSets.pageInfo?.endCursor
			? data?.jira.issueSearchViewResult.fieldSets.pageInfo.endCursor
			: undefined;
	}

	// @ts-expect-error - Argument of type '(edge: Node) => { secondaryLabel?: JSX.Element | undefined; isDisabled?: true | undefined; disabledMessage?: React.ReactNode; optionType: "option"; cursor: string; value: string; label: string; }' is not assignable to parameter of type '(value: { readonly cursor: string; readonly node: { readonly displayName: string; readonly fieldSetId: string; readonly fieldType?: { readonly displayName: string | null | undefined; } | null | undefined; readonly type: string; }; }, index: number, array: { ...; }[]) => { ...; }'.
	const options: SelectValue | undefined = edges?.filter(isNonNullish).map((edge: Node) => {
		const fieldTypeDisplayName = edge.node.fieldType?.displayName;
		const icon = edge.node.type ? <FieldTypeIcon type={edge.node.type} size="small" /> : undefined;

		const isOnlyUnselectable = isOnlyUnselectableField(edge.node.type, canEditColumns);

		return {
			optionType: 'option',
			cursor: edge.cursor,
			value: edge.node.fieldSetId,
			label: edge.node.displayName,
			secondaryLabel:
				fieldTypeDisplayName && icon
					? getSecondaryLabelWithIcon(icon, fieldTypeDisplayName)
					: undefined,
			...(isOnlyUnselectable && {
				isOnlyUnselectable,
				disabledMessage: intl.formatMessage(messages.deprecatedParentyLinkMessage, {
					field: edge.node.displayName,
					strong: (chunks: ReactNode) => (
						<Text as="strong" color="inherit" size="UNSAFE_small">
							{chunks}
						</Text>
					),
				}),
			}),
		};
	});

	if (!options?.length) {
		return { options: [], totalCount: 0 };
	}

	if (search.length) {
		return { options, totalCount, endCursor };
	}

	// total count is the count of unselected options for non-search queries
	totalCount += selectedColumns.length;

	return {
		options: [
			{
				label: intl.formatMessage(messages.columnGroupHeader),
				optionType: 'group',
				options,
			},
		],
		totalCount,
		endCursor,
	};
};

export const useColumnLoader = (
	viewId: IssueNavigatorViewId,
	canEditColumns: boolean,
	selectedColumns: SelectValue,
	isIFCEnabled?: boolean,
) => {
	const intl = useIntl();
	const cloudId = useCloudId();
	const environment = useRelayEnvironment();

	// refs for selectedColumns, endCursor and search prevent outdated data in the relay store from being used
	// in cases when selected options changes & picker is paginated
	const columnsRef = useRef(selectedColumns);
	const endCursorRef = useRef<string | undefined>();
	const searchRef = useRef<string>();
	const loadedOptionsRef = useRef<SelectValue | undefined>();

	return useMemo(
		() =>
			createDebouncedColumnLoader(async (search, renderInitialValues, endCursor) => {
				let cursor = endCursor;
				if (!canEditColumns) {
					const options = selectedColumns
						? selectedColumns.filter(
								(col) =>
									col.optionType === 'option' &&
									(search
										? col.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
										: true),
							)
						: [];
					return Promise.resolve({
						options,
						totalCount: options.length,
					});
				}

				// calculate cursor from edges, since endCursor might not be valid anymore when last option was selected
				if (endCursor !== undefined && loadedOptionsRef.current && selectedColumns) {
					cursor = getCursorOfLastUnselectedOption(
						loadedOptionsRef.current,
						selectedColumns,
						search,
						endCursor,
					);
				}

				try {
					const result = await fetchQuery<fetchColumnPickerOptionsQuery>(
						environment,
						graphql`
							query fetchColumnPickerOptionsQuery(
								$cloudId: ID!
								$namespace: String
								$viewId: String
								$first: Int
								$after: String
								$filter: JiraIssueSearchFieldSetsFilter
								$isIFCEnabled: Boolean!
							) {
								jira @required(action: THROW) {
									issueSearchViewByNamespaceAndViewId(
										cloudId: $cloudId
										namespace: $namespace
										viewId: $viewId
									) @required(action: THROW) @skip(if: $isIFCEnabled) {
										fieldSets(first: $first, filter: $filter, after: $after)
											@required(action: THROW) {
											totalCount @required(action: THROW)
											pageInfo {
												endCursor
											}
											edges @required(action: THROW) {
												cursor
												node @required(action: THROW) {
													fieldSetId @required(action: THROW)
													displayName @required(action: THROW)
													type @required(action: THROW)
													fieldType {
														displayName
													}
												}
											}
										}
									}
									issueSearchViewResult(cloudId: $cloudId, namespace: $namespace, viewId: $viewId)
										@optIn(to: "JiraIssueSearch")
										@required(action: THROW)
										@include(if: $isIFCEnabled) {
										... on JiraIssueSearchView {
											fieldSets(first: $first, filter: $filter, after: $after)
												@required(action: THROW) {
												totalCount @required(action: THROW)
												pageInfo {
													endCursor
												}
												edges @required(action: THROW) {
													cursor
													node @required(action: THROW) {
														fieldSetId @required(action: THROW)
														displayName @required(action: THROW)
														type @required(action: THROW)
														fieldType {
															displayName
														}
													}
												}
											}
										}
									}
								}
							}
						`,
						{
							cloudId,
							namespace: ISSUE_SEARCH_NAMESPACE,
							viewId,
							first: 25,
							after: cursor,
							filter: {
								searchString: search,
								fieldSetSelectedState: !search ? 'NON_SELECTED' : 'ALL',
							},
							isIFCEnabled: !!isIFCEnabled,
						},
						{
							fetchPolicy:
								columnsRef.current !== selectedColumns ||
								endCursorRef.current !== cursor ||
								searchRef.current !== search
									? 'network-only'
									: 'store-or-network',
						},
					).toPromise();

					columnsRef.current = selectedColumns;
					endCursorRef.current = cursor;
					searchRef.current = search;
					const pickerOptions = mapColumnPickerOptions(
						result,
						search,
						intl,
						selectedColumns,
						canEditColumns,
						isIFCEnabled,
					);

					loadedOptionsRef.current = pickerOptions.options || undefined;

					return pickerOptions;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					fireErrorAnalytics({
						meta: {
							id: 'fetchColumnPickerOptions',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
					loadedOptionsRef.current = undefined;

					return {
						options: [
							{
								optionType: 'error',
								error,
								onRetry: () => undefined,
							},
						],
						totalCount: 0,
					};
				}
			}),
		[canEditColumns, cloudId, environment, intl, isIFCEnabled, selectedColumns, viewId],
	);
};
