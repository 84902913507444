import React, { memo, useEffect, useState } from 'react';
import { useIssueTableSelectedIssues } from '@atlassian/jira-native-issue-table/src/controllers/selected-issues/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { bulkOperationsConnectionsSubscriberEntryPoint } from '@atlassian/jira-issue-navigator-bulk-operations-connections-subscriber/src/entrypoint.tsx';
import type { BulkOpsConnectionsSubscriberProps } from '@atlassian/jira-issue-navigator-bulk-operations-connections-subscriber/src/ui/types.tsx';

const entryPointParams = {};

export const BulkOpsConnectionsSubscriber = memo((props: BulkOpsConnectionsSubscriberProps) => {
	const selectedIssues = useIssueTableSelectedIssues();
	const [hasSelectedAtLeastOnce, setHasSelectedAtLeastOnce] = useState(false);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		bulkOperationsConnectionsSubscriberEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (!hasSelectedAtLeastOnce && selectedIssues.length > 0) {
			setHasSelectedAtLeastOnce(true);
			entryPointActions.load();
		}
	}, [entryPointActions, hasSelectedAtLeastOnce, selectedIssues.length]);

	return (
		<JiraEntryPointContainer
			id="jira.nin-bulk.operations.connections.subscriber"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
});
