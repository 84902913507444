/**
 * @generated SignedSource<<4aa67d431503e89ed559c9796911ad7f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_jiraView$data = {
  readonly __typename: string;
  readonly error: {
    readonly extensions: ReadonlyArray<{
      readonly statusCode: number | null | undefined;
    }> | null | undefined;
    readonly message: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView_view" | "main_issueNavigator_ListView_view">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_jiraView";
};
export type main_issueNavigator_IssueResults_jiraView$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_jiraView$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_jiraView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_jiraView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "QueryError",
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "extensions",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "statusCode"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "message"
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_DetailView_view"
        }
      ],
      "type": "JiraDetailedView"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_ListView_view"
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraView",
  "abstractKey": "__isJiraView"
};

(node as any).hash = "d1bfcbf5b50637766f970894f71c262d";

export default node;
