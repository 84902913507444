import React from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { src_issueTableHideDoneToggle_HideDoneItemsToggle$key } from '@atlassian/jira-relay/src/__generated__/src_issueTableHideDoneToggle_HideDoneItemsToggle.graphql.ts';
import { HideDoneItemsToggle as HideDoneItemsToggleWithoutErrorBoundary } from './ui/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

export type props = {
	onChangeHideDone: (isHideDoneEnabled: boolean) => void;
	viewResult: src_issueTableHideDoneToggle_HideDoneItemsToggle$key;
};
export const HideDoneItemsToggle = (props: props) => {
	const viewResultData = useFragment<src_issueTableHideDoneToggle_HideDoneItemsToggle$key>(
		graphql`
			fragment src_issueTableHideDoneToggle_HideDoneItemsToggle on JiraIssueSearchViewMetadata {
				...ui_issueTableHideDoneToggle_HideDoneItemsToggle
					@arguments(staticViewInput: $staticViewInput)
			}
		`,
		props.viewResult,
	);
	return (
		<JSErrorBoundary
			fallback="unmount"
			id="issue-table-hide-done-toggle"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<HideDoneItemsToggleWithoutErrorBoundary
				viewResult={viewResultData}
				onChangeHideDone={props.onChangeHideDone}
			/>
		</JSErrorBoundary>
	);
};
