import React, { useCallback, useEffect, useMemo, type PropsWithChildren } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { useIsBulkOperationsInProgress } from '@atlassian/jira-bulk-operations-common/src/controllers/use-bulk-operations/index.tsx';
import { AsyncBulkOpsCoreFull } from '@atlassian/jira-bulk-operations-full-core/src/async.tsx';
import type { BulkOperationsCoreFullProps } from '@atlassian/jira-bulk-operations-full-core/src/index.tsx';
import {
	useIssueTableSelectedIssues,
	useIssueTableSelectedIssuesActions,
} from '@atlassian/jira-native-issue-table/src/controllers/selected-issues/index.tsx';
import type { bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key } from '@atlassian/jira-relay/src/__generated__/bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore.graphql';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useFlatListIssueIds } from '../../controllers/flat-list-issues/index.tsx';
import { useSelectedView } from '../../controllers/selected-view-state/index.tsx';
import { useActiveJql } from '../../services/active-jql/index.tsx';

const EMPTY_ARRAY: string[] = [];

const LoadBulkOpsCore = ({
	bulkOpsCoreEntryPointReference,
	fragmentRef,
}: Pick<BulkOperationsCoreFullProps, 'bulkOpsCoreEntryPointReference'> & {
	fragmentRef: bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key | null;
}) => {
	const selectedIssues = useIssueTableSelectedIssues();
	const { reset, insertSelectedIssueIds } = useIssueTableSelectedIssuesActions();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const view = fg('bulk_operations_in_nin_fast_follow') ? useSelectedView() : undefined;

	if (fg('bulk_operations_in_nin_fast_follow')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { jql } = useActiveJql();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const previousJql = usePrevious(jql);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (jql !== previousJql && previousJql !== undefined) {
				reset();
			}
		}, [jql, previousJql, reset]);
	}

	let issuesFlatListIds = EMPTY_ARRAY;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		issuesFlatListIds = useFlatListIssueIds();
	}

	const data = useFragment<bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key>(
		graphql`
			fragment bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore on JiraIssueConnection {
				edges {
					node {
						issueId
					}
				}
			}
		`,
		fragmentRef,
	);

	// we don't need to read issueIds for grouping from the query because we already have them in the flat list
	const issueIds = useMemo(() => {
		if (fg('jsc_m2_hierarchy_fe_changes')) {
			return {
				strings: issuesFlatListIds,
				numbers: issuesFlatListIds.map((issueId) => Number(issueId)),
			};
		}

		const strings = data?.edges?.map((edge) => edge?.node?.issueId).filter(Boolean) ?? [];
		return {
			strings,
			numbers: strings.map((issueId) => Number(issueId)),
		};
	}, [data?.edges, issuesFlatListIds]);

	const onSelectAll = useCallback(() => {
		insertSelectedIssueIds(issueIds.strings);
	}, [issueIds.strings, insertSelectedIssueIds]);

	return (
		<AsyncBulkOpsCoreFull
			bulkOpsCoreEntryPointReference={bulkOpsCoreEntryPointReference}
			selectedIssueIds={selectedIssues.map((issue) => Number(issue))}
			onClearAll={reset}
			onSelectAll={onSelectAll}
			selectableIssueIds={issueIds.numbers}
			onClearAllVisible={noop} // What do we do with this one?
			{...(fg('bulk_operations_in_nin_fast_follow') && { shouldHideToolbar: view === 'detail' })}
		/>
	);
};

export const BulkOperationsWrapper = ({
	children,
	bulkOpsCoreEntryPointReference,
	fragmentRef,
}: PropsWithChildren &
	Pick<BulkOperationsCoreFullProps, 'bulkOpsCoreEntryPointReference'> & {
		fragmentRef: bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key | null;
	}) => {
	if (!expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)) {
		return children;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isBulkOpsInProgress = useIsBulkOperationsInProgress();

	return (
		<Flex xcss={wrapperStyles} direction="row">
			<Box xcss={[childContainerStyles, isBulkOpsInProgress && childContainerDisabledStyles]}>
				{children}
			</Box>
			{(fragmentRef || fg('jira_list_grouping_bulk_ops')) && (
				<LoadBulkOpsCore
					fragmentRef={fragmentRef}
					bulkOpsCoreEntryPointReference={bulkOpsCoreEntryPointReference}
				/>
			)}
		</Flex>
	);
};

const wrapperStyles = xcss({
	height: '100%',
	overflowY: 'auto',
});

const childContainerStyles = xcss({
	flexGrow: 1,
	flexShrink: 1,
	flexBasis: 0,
	overflowY: 'auto',
});

const childContainerDisabledStyles = xcss({
	opacity: 0.5,
	pointerEvents: 'none',
});
