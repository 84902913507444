import React, { useContext, useMemo, type ReactNode } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { ISSUE_SEARCH_NAMESPACE } from '../../common/constants.tsx';
import { issueFieldStatisticsStatisticsSidebarEntryPoint } from '../../entrypoint.tsx';

type IssueFieldStatisticsEntrypointProviderProps = {
	children: ReactNode;
	jql: string;
	view: string;
};

const IssueFieldStatisticsEntrypointContext =
	createUseEntryPointContext<typeof issueFieldStatisticsStatisticsSidebarEntryPoint>();

export const IssueFieldStatisticsEntrypointProvider = ({
	children,
	jql,
	view,
}: IssueFieldStatisticsEntrypointProviderProps) => {
	const cloudId = useCloudId();
	const entryPointParams = useMemo(
		() => ({
			cloudId,
			jql,
			view,
			namespace: ISSUE_SEARCH_NAMESPACE,
		}),
		[cloudId, jql, view],
	);

	const createObjectEntryPoint = useEntryPoint(
		issueFieldStatisticsStatisticsSidebarEntryPoint,
		entryPointParams,
	);

	return (
		<IssueFieldStatisticsEntrypointContext.Provider value={createObjectEntryPoint}>
			{children}
		</IssueFieldStatisticsEntrypointContext.Provider>
	);
};

export const useIssueFieldStatisticsEntryPoint = () =>
	useContext(IssueFieldStatisticsEntrypointContext);
