import React, { useCallback, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Inline, Stack } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ui_issueTableHideDoneToggle_HideDoneItemsToggle$key } from '@atlassian/jira-relay/src/__generated__/ui_issueTableHideDoneToggle_HideDoneItemsToggle.graphql';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { useUpdateHideDoneMutation } from '../services/update-hide-done-toggle/index.tsx';
import messages from './messages.tsx';

export type HideDoneToggleProps = {
	viewResult: ui_issueTableHideDoneToggle_HideDoneItemsToggle$key;
	onChangeHideDone: (isHideDoneEnabled: boolean) => void;
};

export const HideDoneItemsToggle = ({ viewResult, onChangeHideDone }: HideDoneToggleProps) => {
	const viewResultData = useFragment<ui_issueTableHideDoneToggle_HideDoneItemsToggle$key>(
		graphql`
			fragment ui_issueTableHideDoneToggle_HideDoneItemsToggle on JiraIssueSearchViewMetadata
			@argumentDefinitions(staticViewInput: { type: "JiraIssueSearchStaticViewInput" }) {
				id
				viewId
				... on JiraIssueSearchView {
					viewConfigSettings(staticViewInput: $staticViewInput) {
						hideDone
					}
				}
				... on JiraSpreadsheetView {
					viewSettings(
						groupBy: $groupBy
						staticViewInput: $staticViewInput
						issueSearchInput: $issueSearchInput
					) {
						hideDone
					}
				}
			}
		`,
		viewResult,
	);

	const { viewConfigSettings, viewSettings, id, viewId } = viewResultData;
	const hideDoneToggleViewSettings = isRefactorNinToViewSchemaEnabled()
		? viewSettings
		: viewConfigSettings;
	const { hideDone } = hideDoneToggleViewSettings ?? {};

	const [hideDoneIsChecked, setHideDoneIsChecked] = useState(hideDone);

	const toggleIssueHideDone = useUpdateHideDoneMutation();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onChange = useCallback(() => {
		if (id) {
			fireUIAnalytics(createAnalyticsEvent({}), 'toggle clicked', 'hideDoneItems', {
				hideDone: !hideDone,
			});
			setHideDoneIsChecked(!hideDone);
			// toggle mutation here
			toggleIssueHideDone({
				issueSearchViewResultAri: id,
				isHideDoneEnabled: !hideDone,
				onChangeHideDone,
			});
		}
	}, [createAnalyticsEvent, hideDone, id, onChangeHideDone, toggleIssueHideDone]);

	const toggleId = `${viewId}_hide_done_toggle`;
	const { formatMessage } = useIntl();

	return (
		<Stack>
			<Inline spread="space-between" alignBlock="center">
				<label htmlFor={toggleId}>{formatMessage(messages.showHideDoneItems)}</label>
				<Toggle
					size="large"
					id={toggleId}
					onChange={onChange}
					isChecked={Boolean(hideDoneIsChecked)}
				/>
			</Inline>
		</Stack>
	);
};
