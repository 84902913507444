import noop from 'lodash/noop';
import { v4 as uuid } from 'uuid';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { actions, onContainerUpdate, onSearchInputUpdate } from './actions.tsx';
import type { State, Actions, Props } from './types.tsx';

const initialState: State = {
	onLoadNext: noop,
	onLoadPrevious: noop,
	isLoadingNext: false,
	isLoadingPrevious: false,
	hasNext: false,
	hasPrevious: false,
	disposables: [],
	isFetching: false,
	isFetchingView: false,
	isRefreshing: false,
	isNetworkError: false,
	searchKey: '',
	totalIssueCount: null,
	totalIssueCountIsFetching: false,
	totalIssueCountIsError: false,
};

export const IssueSearchServiceContainer = createContainer<Props>();

export const Store = createStore<State, Actions, Props>({
	initialState,
	actions,
	containedBy: IssueSearchServiceContainer,
	handlers: {
		onInit:
			() =>
			({ dispatch, setState }) => {
				if (!fg('empanada_nin_concurrent_mode_fixes')) {
					dispatch(onContainerUpdate());
				}
				setState({
					searchKey: uuid(),
				});
			},
		onContainerUpdate:
			(nextProps, prevProps) =>
			({ dispatch }) => {
				if (!fg('empanada_nin_concurrent_mode_fixes')) {
					dispatch(onContainerUpdate());
				}

				if (!isShallowEqual(nextProps.issueSearchInput, prevProps.issueSearchInput)) {
					dispatch(onSearchInputUpdate());
				}
			},
		onDestroy:
			() =>
			({ getState }) => {
				const { disposables } = getState();
				// Dispose of any manually retained operations when the container is unmounted
				disposables.forEach((disposable) => disposable.dispose());
			},
	},
});
