import React, { useCallback, useMemo } from 'react';
import { useIssueFieldStatisticsEntryPoint } from '@atlassian/jira-issue-navigator-action-issue-field-statistics/src/controllers/entrypoint-context/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { ErrorState } from '../error-state/index.tsx';

const HELIOS_TEAM_NAME = 'helios-reports';

type Props = {
	jql: string;
	onClose: () => void;
};

export const IssueFieldStatisticsSidebarExperience = ({ jql, onClose }: Props) => {
	const { entryPointReferenceSubject } = useIssueFieldStatisticsEntryPoint();

	const issueFieldStatisticsRuntimeProps = useMemo(() => ({ jql, onClose }), [jql, onClose]);
	const errorFallback = useCallback(() => <ErrorState onClose={onClose} />, [onClose]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="issueFieldStatisticsSidebar"
			packageName="jiraIssueNavigatorActionIssueFieldStatistics"
			errorFallback={errorFallback}
			teamName={HELIOS_TEAM_NAME}
			fallback={<></>}
			runtimeProps={issueFieldStatisticsRuntimeProps}
		/>
	);
};
