/** @jsx jsx */
import React, { useEffect } from 'react';
import { Flex, Box } from '@atlaskit/primitives/compiled';
import { cssMap, jsx } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import Resizer from '@atlassian/jira-flex-resizer/src/ui/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { ResizerProps } from '@atlassian/jira-flex-resizer/src/common/types.tsx';
import { useSidebar } from '../controllers/use-sidebar/index.tsx';
import { PACKAGE_NAME } from '../common/constants.tsx';

export type SidebarProps = {
	children: React.ReactNode;
	content: React.JSX.Element;
	teamName: string;
} & Pick<ResizerProps, 'defaultRatio' | 'minRatio' | 'maxRatio' | 'minWidth' | 'maxWidth'>;

export const SidebarLayout = ({
	children,
	content,
	teamName,
	defaultRatio,
	minWidth,
	minRatio,
	maxRatio,
}: SidebarProps) => {
	const [{ id }, { closeSidebar }] = useSidebar();

	// reset sidebar content on view unmount
	useEffect(
		() => () => {
			closeSidebar();
		},
		[closeSidebar],
	);

	return (
		<Flex testId="platform-sidebar.ui.sidebar-layout" xcss={styles.wrapper} direction="row">
			<Box xcss={styles.childContentContainer}>{children}</Box>
			<JSErrorBoundary
				id="sidebarWrapper"
				packageName={PACKAGE_NAME}
				teamName={teamName}
				fallback="flag"
				attributes={{ sidebarExperienceId: id }}
			>
				{id ? (
					<Resizer
						defaultRatio={defaultRatio}
						minWidth={minWidth}
						minRatio={minRatio}
						maxRatio={maxRatio}
					>
						{(props) => (
							<div {...props} css={styles.sidebarContentContainer}>
								{content}
							</div>
						)}
					</Resizer>
				) : null}
			</JSErrorBoundary>
		</Flex>
	);
};

const styles = cssMap({
	wrapper: {
		height: '100%',
		overflowY: 'auto',
	},
	sidebarContentContainer: {
		boxShadow: token('elevation.shadow.overlay'),
		/* ResizerHandle does not support restyling, this allows us to style the handle using the left border */
		marginLeft: token('space.negative.150'),
	},
	childContentContainer: {
		flexGrow: 1,
		flexShrink: 0,
		flexBasis: 0,
		overflowY: 'auto',
	},
});
