import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useElementSizeSelector } from '../../../../controllers/element-size/index.tsx';
import { useScrollStateSelector } from '../../../../controllers/scroll-state/index.tsx';
import { BORDER_WIDTH } from '../../../constants.tsx';
import type { FooterProps } from '../../../types.tsx';

/**
 * Default footer element to display beneath the issue table.
 */
export const Footer = ({
	start = SpacerElement,
	center = SpacerElement,
	end = SpacerElement,
}: FooterProps) => {
	return (
		<FooterBase>
			{start}
			{center}
			{end}
		</FooterBase>
	);
};

function FooterBase({ children }: { children: ReactNode }) {
	const tableHeight = useElementSizeSelector((size) => size.height);

	const hasShadow = useScrollStateSelector((scrollState) => {
		const { height: containerHeight, scrollTop } = scrollState;
		// Scroll measurements are fractional, while height measurements are integer, so we need to round before comparing
		return containerHeight < tableHeight && Math.round(scrollTop) < tableHeight - containerHeight;
	});

	return (
		<FooterContainer
			data-testid="native-issue-table.common.ui.components.footer.footer-container"
			hasShadow={hasShadow}
		>
			{children}
		</FooterContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div<{ hasShadow: boolean }>(
	{
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		position: 'sticky',

		left: 0,
		bottom: 0,

		backgroundColor: token('elevation.surface'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderTop: `${BORDER_WIDTH} solid ${token('color.border')}`,
		/* adding 4px left and right padding for focus border of child elements */
		paddingTop: token('space.050'),
		paddingRight: token('space.050'),
		paddingBottom: token('space.050'),
		paddingLeft: token('space.050'),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasShadow }) =>
		hasShadow && {
			boxShadow: `
                0px -8px 8px -8px ${token('elevation.shadow.overflow.spread')},
                0px -1px 1px -1px ${token('elevation.shadow.overflow.perimeter')}
            `,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Spacer = styled.div({
	display: 'flex',
	flex: 1,
});

const SpacerElement = <Spacer />;
