import debounce from 'lodash/debounce';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import { views } from '../../common/constants.tsx';
import { getNextIssueKey, getPreviousIssueKey } from './selectors.tsx';
import type { State, Actions, Props, SelectIssueOptions, OnChangeHandler } from './types.tsx';

type SideEffectOptions = SelectIssueOptions & {
	onChange?: OnChangeHandler;
};

const doSideEffectUpdate = (
	{ getState, setState }: StoreActionApi<State>,
	{
		onChange,
		shouldNotifyOnChange = true,
		isSelectedByUserInteraction = true,
		viewActivity,
	}: SideEffectOptions,
): void => {
	const { selectedIssueKey } = getState();
	setState({ _rememoize: Date.now() });
	shouldNotifyOnChange &&
		onChange &&
		onChange(selectedIssueKey, isSelectedByUserInteraction, viewActivity);
};

const doDebouncedSideEffectUpdate = debounce(doSideEffectUpdate, 600);

const doSideEffectUpdates = (actions: StoreActionApi<State>, options: SideEffectOptions) => {
	options.shouldDebounce
		? doDebouncedSideEffectUpdate(actions, options)
		: doSideEffectUpdate(actions, options);
};

const setSelectedIssueByKey =
	(selectedIssueKey: IssueKey, options: SideEffectOptions = {}): Action<State, Props, boolean> =>
	(actions, { onChange }) => {
		if (!selectedIssueKey.length) {
			return false;
		}

		actions.setState({
			selectedIssueKey,
		});

		doSideEffectUpdates(actions, {
			...options,
			onChange,
		});
		return true;
	};

const setSelectedIssueByIndex =
	(issueIndex: number, options?: SelectIssueOptions): Action<State, Props, boolean> =>
	({ dispatch }, { issueKeys }) => {
		return dispatch(setSelectedIssueByKey(issueKeys[issueIndex] ?? toIssueKey(''), options));
	};
/**
 * Select the next issue in the search results (if there is a valid selection). Returns `true` if an issue was selected,
 * otherwise `false`.
 */
const selectNextIssue =
	(shouldDebounce?: boolean): Action<State, Props, boolean> =>
	({ dispatch, getState }) => {
		return dispatch(
			setSelectedIssueByKey(getNextIssueKey(getState()) ?? toIssueKey(''), { shouldDebounce }),
		);
	};

/**
 * Select the previous issue in the search results (if there is a valid selection). Returns `true` if an issue was
 * selected, otherwise `false`.
 */
const selectPreviousIssue =
	(shouldDebounce?: boolean): Action<State, Props, boolean> =>
	({ dispatch, getState }) => {
		return dispatch(
			setSelectedIssueByKey(getPreviousIssueKey(getState()) ?? toIssueKey(''), { shouldDebounce }),
		);
	};

const deselectIssue =
	(options?: SelectIssueOptions): Action<State, Props> =>
	(actions, { onChange }) => {
		actions.setState({ selectedIssueKey: toIssueKey('') });
		doSideEffectUpdates(actions, {
			...options,
			onChange,
		});
	};

const enterFullPageIssueAppMode =
	(): Action<State, Props> =>
	({ setState }) =>
		setState({ isFullPageIssueAppMode: true });

const exitFullPageIssueAppMode =
	(): Action<State, Props> =>
	({ setState }) =>
		setState({ isFullPageIssueAppMode: false });

/**
 * Return true if we're in default list view mode without the full page issue app visible.
 */
const isListViewWithoutIssueApp =
	(): Action<State, Props, boolean> =>
	({ getState }, { view }) => {
		const { isFullPageIssueAppMode } = getState();
		return view === views.list && !isFullPageIssueAppMode;
	};

/**
 * Return true if we're in default list view mode with the full page issue app visible and a selected issue key.
 */
const isListViewWithIssueAppAndKey =
	(): Action<State, Props, boolean> =>
	({ getState }, { view }) => {
		const { isFullPageIssueAppMode, selectedIssueKey } = getState();
		return view === views.list && isFullPageIssueAppMode && !!selectedIssueKey;
	};

/**
 * Reset the selected issue to the first issue in the connection.
 */
const resetSelectedIssue =
	(): Action<State, Props> =>
	({ dispatch }) => {
		// We only want to update issue key in the URL if our view mode uses external issue key selection, i.e. detail
		// view or full page issue app in list view. For the default list view mode we want to keep the selected issue
		// state internal.
		const shouldNotifyOnChange = !dispatch(isListViewWithoutIssueApp());

		dispatch(
			setSelectedIssueByIndex(0, {
				shouldDebounce: false,
				shouldNotifyOnChange,
				isSelectedByUserInteraction: false,
			}),
		);
	};

/**
 * Private action to set props required for selectors into state whenever the container updates.
 */
export const onContainerUpdate =
	(): Action<State, Props> =>
	({ setState }, { issueKeys }) => {
		setState({
			issueKeys,
		});
	};

export const actions: Actions = {
	setSelectedIssueByKey,
	setSelectedIssueByIndex,
	selectNextIssue,
	selectPreviousIssue,
	deselectIssue,
	enterFullPageIssueAppMode,
	exitFullPageIssueAppMode,
};

export const privateActions = {
	isListViewWithoutIssueApp,
	isListViewWithIssueAppAndKey,
	resetSelectedIssue,
	onContainerUpdate,
};

export default actions;
