import { useState, useMemo } from 'react';
import { markOnce, NINJqlBuilderAsyncLoad } from '../../../common/utils/performance-analytics.tsx';

export const useJqlBuilderInstrumentation = () => {
	const [finished, setFinished] = useState(false);

	return useMemo(
		() => ({
			startJqlBuilderMetric: () => {
				if (finished) return;
				NINJqlBuilderAsyncLoad.start();
			},
			stopJqlBuilderMetric: () => {
				setFinished(true);

				NINJqlBuilderAsyncLoad.stop();
			},
			markJqlBuilderMetric: (mark: string) => {
				if (finished) return;

				markOnce(mark);
			},
		}),
		[finished],
	);
};
