// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createStateHook,
	type Action,
	createActionsHook,
	createContainer,
} from '@atlassian/react-sweet-state';

export const FlatListIssuesContainer = createContainer();

type State = {
	flatListIssues: string[];
	flatListIssueIds: string[];
};

const initialState = {
	flatListIssues: [],
	flatListIssueIds: [],
};

const actions = {
	setFlatListIssueIds:
		(flatListIssueIds: string[]): Action<State> =>
		({ setState }) => {
			setState({ flatListIssueIds });
		},

	setFlatListIssues:
		(flatListIssues: string[]): Action<State> =>
		({ setState }) => {
			setState({ flatListIssues });
		},
};

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	containedBy: FlatListIssuesContainer,
	initialState,
	actions,
	name: 'jira-native-issue-table-flat-list-issues-store',
});

export const useFlatListIssuesActions = createActionsHook(Store);
export const useFlatListIssuesStore = createHook(Store);

const getFlatListIssuesSelector = (state: State) => state.flatListIssues;

export const useFlatListIssues = createStateHook(Store, {
	selector: getFlatListIssuesSelector,
});

const getFlatListIssueIdsSelector = (state: State) => state.flatListIssueIds;

export const useFlatListIssueIds = createStateHook(Store, {
	selector: getFlatListIssueIdsSelector,
});
