import React, {
	useMemo,
	useState,
	useEffect,
	createContext,
	useContext,
	type ReactNode,
} from 'react';
import { useCoordination } from '@atlassiansox/engagekit-ts';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import messages from './messages.tsx';

/* TODO: remove after cleaning jira_spreadsheet_component_m1 and all the Spotlight targets  */
export const HIERARCHY_ONBOARDING_ENGAGEMENT_ID_STG = 'jira-nin-hierarchy-onboarding-stg';
export const HIERARCHY_ONBOARDING_ENGAGEMENT_ID_PROD = 'jira-list-nin-hierarchy-onboarding';

const ENV_STAGING: Environment = 'staging';
const STARGATE_URL = '/gateway/api';

/* Onboarding context to open the more menu from the issue-table */
type HierarchyOnboardingContextType = {
	showToggleOnboarding: boolean;
	setShowToggleOnboarding: (isMenuOpen: boolean) => void;
};

const HierarchyOnboardingContext = createContext<HierarchyOnboardingContextType | null>(null);

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/hierarchy-onboarding/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const HierarchyOnboardingProvider = ({ children }: { children: ReactNode }) => {
	const [showToggleOnboarding, setShowToggleOnboarding] = useState(false);

	const value = useMemo(
		() => ({
			showToggleOnboarding,
			setShowToggleOnboarding,
		}),
		[showToggleOnboarding],
	);

	return (
		<HierarchyOnboardingContext.Provider value={value}>
			{children}
		</HierarchyOnboardingContext.Provider>
	);
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/hierarchy-onboarding/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const useHierarchyOnboardingContext = () => {
	const hierarchyContext = useContext(HierarchyOnboardingContext);

	if (!hierarchyContext) {
		throw new Error(
			'useHierarchyOnboardingContext must be used within a HierarchyOnboardingProvider',
		);
	}

	return hierarchyContext;
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/hierarchy-onboarding/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const HierarchyOnboarding = () => {
	const ONBOARDING_ENGAGEMENT_ID =
		useEnvironment() === ENV_STAGING
			? HIERARCHY_ONBOARDING_ENGAGEMENT_ID_STG
			: HIERARCHY_ONBOARDING_ENGAGEMENT_ID_PROD;

	const { setShowToggleOnboarding } = useHierarchyOnboardingContext();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();

	/**
	 * Onboarding Experience is loaded manually on page load via EP's CoordinationClient class
	 * Once loaded, if the message is active for this particular context, only then the HIERARCHY_ONBOARDING will be loaded and displayed
	 */
	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, STARGATE_URL, false, '', 'engagement'),
		[cloudId],
	);

	const [isSpotlightActive, setIsSpotlightActive] = useState(false);
	const [isEnrolled, stopEnrolment] = useCoordination(coordinationClient, ONBOARDING_ENGAGEMENT_ID);

	useEffect(() => {
		if (isEnrolled) {
			stopEnrolment(false);
			setIsSpotlightActive(true);
		}
	}, [isEnrolled, stopEnrolment]);

	return (
		<ChoreographerContextProvider isChoreographed={coordinationClient.enableChoreographer}>
			<SpotlightTransition>
				{isEnrolled && isSpotlightActive && (
					<JiraSpotlight
						messageId="native-issue-table.ui.hierarchy-onboarding.jira-spotlight.expand-column-header"
						messageType="engagement"
						actionsBeforeElement="1/2"
						actions={[
							{
								onClick: () => {
									setIsSpotlightActive(false);
									setShowToggleOnboarding(true);
								},
								text: formatMessage(messages.next),
							},
							{
								appearance: 'subtle',
								onClick: () => setIsSpotlightActive(false),
								text: formatMessage(messages.dismiss),
							},
						]}
						dialogWidth={320}
						heading={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.onboardingHeadingIssueTermRefresh
								: messages.onboardingHeading,
						)}
						target="expand-column-header"
						key="expand-column-header"
						targetRadius={3}
					>
						{formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.onboardingDescriptionIssueTermRefresh
								: messages.onboardingDescription,
						)}
					</JiraSpotlight>
				)}
			</SpotlightTransition>
		</ChoreographerContextProvider>
	);
};

export const HierarchyToggleOnboarding = () => {
	const { formatMessage } = useIntl();
	const { showToggleOnboarding, setShowToggleOnboarding } = useHierarchyOnboardingContext();
	const [isSpotlightActive, setIsSpotlightActive] = useState(true);

	if (!isSpotlightActive || !showToggleOnboarding) {
		return null;
	}

	return (
		<SpotlightTransition>
			<JiraSpotlight
				messageId="native-issue-table.ui.hierarchy-onboarding.jira-spotlight.hierarchy-feature-toggle"
				messageType="engagement"
				actionsBeforeElement="2/2"
				actions={[
					{
						onClick: () => {
							setShowToggleOnboarding(false);
							setIsSpotlightActive(false);
						},
						text: formatMessage(messages.ok),
					},
				]}
				dialogWidth={320}
				target="hierarchy-feature-toggle"
				key="hierarchy-feature-toggle"
				targetRadius={3}
			>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.toggleOnboardingIssueTermRefresh
						: messages.toggleOnboarding,
				)}
			</JiraSpotlight>
		</SpotlightTransition>
	);
};
