import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	nextIssueTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-tooltip',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: "Next issue ''{issueKey}'' (Type 'j')",
		description:
			'Tooltip shown on the button to select the next issue followed by the keyboard shortcut to perform the action.',
	},
	nextIssueWithoutKeyTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-without-key-tooltip',
		defaultMessage: "Next issue (Type 'j')",
		description:
			'Tooltip shown on the button to select the next issue followed by the keyboard shortcut to perform the action.',
	},
	disabledButtonTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-tooltip',
		defaultMessage: 'We can’t load more pages. Refine your search criteria to see more issues.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
	disabledButtonLabel: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-label',
		defaultMessage: '{issueCount} of {issueCount} issues, select previous issues to go back',
		description:
			'Aria label when last issue is reached and the "Next issue" button is disabled. The message tells users they are on the last issue and they can look at previous issues instead.',
	},
	disabledButtonLabelIssueTermRefresh: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-label-issue-term-refresh',
		defaultMessage:
			'{issueCount} of {issueCount} work items, select previous work items to go back',
		description:
			'Aria label when last issue is reached and the "Next issue" button is disabled. The message tells users they are on the last issue and they can look at previous issues instead.',
	},
	nextIssueTooltipIssueTermRefresh: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-tooltip-issue-term-refresh',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: "Next work item ''{issueKey}'' (Type 'j')",
		description:
			'Tooltip shown on the button to select the next issue followed by the keyboard shortcut to perform the action.',
	},
	nextIssueWithoutKeyTooltipIssueTermRefresh: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-without-key-tooltip-issue-term-refresh',
		defaultMessage: "Next work item (Type 'j')",
		description:
			'Tooltip shown on the button to select the next issue followed by the keyboard shortcut to perform the action.',
	},
	disabledButtonTooltipIssueTermRefresh: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-tooltip-issue-term-refresh',
		defaultMessage: 'We can’t load more pages. Refine your search criteria to see more work items.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
});
export default messages;
