/**
 * @generated SignedSource<<febf24c46a5b7469fc6147163ebf19bd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueSearch_issueNavigator$data = {
  readonly viewConfigSettings?: {
    readonly hideDone: boolean | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly viewId: string;
  readonly " $fragmentType": "issueSearch_issueNavigator";
};
export type issueSearch_issueNavigator$key = {
  readonly " $data"?: issueSearch_issueNavigator$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueSearch_issueNavigator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "issueSearch_issueNavigator",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "staticViewInput",
              "variableName": "staticViewInput"
            }
          ],
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewConfigSettings",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "isHierarchyEnabled"
            },
            {
              "kind": "ScalarField",
              "name": "hideDone"
            }
          ]
        }
      ]
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "viewId"
      },
      "action": "THROW",
      "path": "viewId"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "003cd3c768e45103680b4e1e40fe414d";

export default node;
