/**
 * @generated SignedSource<<096bf82bd355a50c4e3c69b25160edf4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topBar_issueNavigator_viewResult$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hierarchyToggle_issueNavigator_HierarchyToggleItem" | "src_issueTableHideDoneToggle_HideDoneItemsToggle">;
  readonly " $fragmentType": "topBar_issueNavigator_viewResult";
};
export type topBar_issueNavigator_viewResult$key = {
  readonly " $data"?: topBar_issueNavigator_viewResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"topBar_issueNavigator_viewResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "topBar_issueNavigator_viewResult",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem"
    },
    {
      "kind": "FragmentSpread",
      "name": "src_issueTableHideDoneToggle_HideDoneItemsToggle"
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};

(node as any).hash = "cb1ec8eae133c845db482d8342db857f";

export default node;
