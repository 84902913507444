export const IMPORT_ISSUES = 'bulk_create_dd_link';
export const CURRENT_PAGE = 'bulkedit_curr_pg';
export const BULK_CHANGE = 'bulkedit_all';
export const CLEAR_SORTS = 'gin-meatball-menu-clear-sorts';
export const NIN_OPT_OUT_FEEDBACK = 'opt-out-feedback';
export const CONNECT_APP = 'connect-app';
export const GIVE_FEEDBACK = 'give-feedback';
export const GO_TO_ALL_ISSUES = 'go-to-all-issues';
export const HIERARCHY_TOGGLE = 'hierarchy-toggle';
export const HIDE_DONE_TOGGLE = 'hide-done-toggle';
export const VIEW_ISSUES_AS_A_CHART = 'view-issues-as-a-chart';
export const EXPORT_ISSUES = 'export-issues';
