import { useEffect, useCallback, useRef } from 'react';
import { ELID_ISSUE_ACTIVITY_FEED } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';

type TimeoutId = ReturnType<typeof setTimeout>;

const INTERVAL = 100;
const TIMEOUT = 10000;

// Exported for DI in unit tests
export const getRef = () => {
	return globalThis.document.getElementById(ELID_ISSUE_ACTIVITY_FEED);
};

export const useScrollToActivityFeed = (issueKey: IssueKey) => {
	const viewActivity = useQueryParam('viewActivity')[0] === 'true';
	const intervalId = useRef<TimeoutId>();
	const timeoutId = useRef<TimeoutId>();
	const isTimeout = useRef(false);

	const waitForElement = useCallback(() => {
		if (isTimeout.current) {
			return;
		}

		const ref = getRef();
		if (ref) {
			// sometimes the browser can't immediately scroll,
			// so we add a small timeout here to let it render Bento first
			setTimeout(() => {
				ref.scrollIntoView({
					block: 'start',
					inline: 'nearest',
				});
			}, 300);
		} else {
			intervalId.current = setTimeout(waitForElement, INTERVAL);
		}
	}, []);

	useEffect(() => {
		if (!viewActivity) {
			return;
		}

		clearTimeout(intervalId.current);
		clearTimeout(timeoutId.current);
		isTimeout.current = false;

		// clear the interval in case comments section never shows up
		timeoutId.current = setTimeout(() => {
			isTimeout.current = true;
		}, TIMEOUT);

		// kick off the interval if issueKey changes + on mount
		intervalId.current = setTimeout(waitForElement, INTERVAL);

		// clear interval on unmount
		return () => {
			clearTimeout(intervalId.current);
			clearTimeout(timeoutId.current);
		};
	}, [waitForElement, issueKey, viewActivity]);
};
