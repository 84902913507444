// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ReactElement, type ComponentProps, memo } from 'react';
import Icon from '@atlaskit/icon';
import GoalIcon from '@atlaskit/icon/core/goal';
import BranchesIcon from '@atlaskit/icon/core/migration/branch--bitbucket-branches';
import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group--people';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import StopwatchIcon from '@atlaskit/icon/core/migration/stopwatch';
import type { Size } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import type {
	IgnoredFieldCheck,
	CheckFieldTypeDefinitions,
	CheckedLegacyFieldTypeDefinitions,
} from '@atlassian/jira-platform-field-config/src/common/types/checked-fields.tsx';
import {
	ASSETS_CF_TYPE,
	ASSIGNEE_TYPE as ASSIGNEE_FIELD_TYPE,
	ATTACHMENT_TYPE as ATTACHMENT_FIELD_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DUE_DATE_TYPE as DUE_DATE_FIELD_TYPE,
	FIX_VERSIONS_TYPE as FIX_VERSIONS_FIELD_TYPE,
	FLAGGED_CF_TYPE,
	GROUP_CF_TYPE,
	ISSUE_LINKS_TYPE as ISSUE_LINKS_FIELD_TYPE,
	LABELS_CF_TYPE,
	LABELS_TYPE as LABELS_FIELD_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	MULTI_USER_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	PARENT_CF_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE as PRIORITY_FIELD_TYPE,
	PROJECT_PICKER_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	REPORTER_TYPE as REPORTER_FIELD_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	SELECT_CF_TYPE,
	CASCADING_SELECT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	SPRINT_TYPE as SPRINT_CF_GLOBAL_TYPE,
	SUMMARY_TYPE as SUMMARY_FIELD_TYPE,
	TEAM_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	TEXT_CF_TYPE,
	TIME_ESTIMATE_CONFIG_TYPE as TIME_ESTIMATE_FIELD_TYPE,
	TIME_TRACKING_TYPE as TIME_TRACKING_FIELD_TYPE,
	TIME_ESTIMATE_TYPE as ORIGINAL_TIME_ESTIMATE_FIELD_TYPE,
	URL_CF_TYPE,
	USER_CF_TYPE,
	VERSION_CF_TYPE,
	STATUS_TYPE as STATUS_FIELD_TYPE,
	COMMENT_TYPE as COMMENT_FIELD_TYPE,
	APPROVERS_LIST_CF_TYPE,
	APPROVALS_CF_TYPE as APPROVAL_DATA_FIELD_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	CATEGORY_TYPE,
	ENTITLEMENT_CF_TYPE,
	SENTIMENT_CF_TYPE,
	GOALS_CF_TYPE,
	ORGANIZATION_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	MULTIUSERPICKER_FIELD_TYPE,
	USERPICKER_FIELD_TYPE,
	DESCRIPTION_FIELD_TYPE,
	ENVIRONMENT_FIELD_TYPE,
	BASELINE_START_CF_TYPE,
	BASELINE_END_CF_TYPE,
	TEXT_AREA_CF_TYPE,
} from './consts.tsx';
import {
	addonGlyph,
	arrowRightGlyph,
	attachmentGlyph,
	bulletListGlyph,
	checkboxGlyph,
	closeGlyph,
	commentGlyph,
	dateGlyph,
	dropdownGlyph,
	labelGlyph,
	numberGlyph,
	officeBuildingGlyph,
	paragraphGlyph,
	peopleGlyph,
	peopleGroupGlyph,
	priorityGlyph,
	radioGlyph,
	screenGlyph,
	shipGlyph,
	shortTextGlyph,
	timeStampGlyph,
	timeTrackingGlyph,
	originalTimeEstimateGlyph,
	appAccessGlyph,
	folderGlyph,
	doubleDropdownGlyph,
	customFieldGlyph,
	jwmCategoryGlyph,
} from './glyphs.tsx';

type ResizedIconProps = {
	// eslint-disable-next-line jira/react/handler-naming
	glyph: (props: { [key: string]: string }) => ReactElement;
};

// @ts-expect-error - TS2322 - Type '(props: { [key: string]: string; }) => ReactElement<any, string | JSXElementConstructor<any>>' is not assignable to type 'ComponentType<CustomGlyphProps> | undefined'.
const ResizedIcon = ({ glyph }: ResizedIconProps) => <Icon label="" glyph={glyph} size="medium" />;

type IgnoredFieldChecks<T> = {
	['com.atlassian.jira.modules.servicemanagement.responders-entity:responders-entity-field-cftype']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:multiselect']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:storypoint']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk.approvals-plugin:sd-approvals']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugins.cmdb:cmdb-object-cftype']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:participants']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk.servicedesk-lingo-integration-plugin:sd-request-language']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-request-feedback']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-request-feedback-date']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-sla-field']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:vp-origin']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-color']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-label']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-link']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-status']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-lexo-rank']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:jsw-issue-color']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:firstresponsedate']: IgnoredFieldCheck<T>;
	['com.atlassian.jconnect.jconnect-plugin:uuid']: IgnoredFieldCheck<T>;
	['com.atlassian.jconnect.jconnect-plugin:location']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:timeinstatus']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:assigneedomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:LastCommentDate']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:attachments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:comments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:dayslastcommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastupdateorcommenter']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastusercommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:reporterdomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:userproperty']: IgnoredFieldCheck<T>;
	aggregateprogress: IgnoredFieldCheck<T>;
	aggregatetimeestimate: IgnoredFieldCheck<T>;
	aggregatetimeoriginalestimate: IgnoredFieldCheck<T>;
	assignee: IgnoredFieldCheck<T>;
	components: IgnoredFieldCheck<T>;
	created: IgnoredFieldCheck<T>;
	creator: IgnoredFieldCheck<T>;
	description: IgnoredFieldCheck<T>;
	devSummary: IgnoredFieldCheck<T>;
	issuekey: IgnoredFieldCheck<T>;
	issuetype: IgnoredFieldCheck<T>;
	parent: IgnoredFieldCheck<T>;
	priority: IgnoredFieldCheck<T>;
	releases: IgnoredFieldCheck<T>;
	reporter: IgnoredFieldCheck<T>;
	resolution: IgnoredFieldCheck<T>;
	resolutiondate: IgnoredFieldCheck<T>;
	status: IgnoredFieldCheck<T>;
	summary: IgnoredFieldCheck<T>;
	updated: IgnoredFieldCheck<T>;
	versions: IgnoredFieldCheck<T>;
	watches: IgnoredFieldCheck<T>;
	workratio: IgnoredFieldCheck<T>;
	worklog: IgnoredFieldCheck<T>;
	votes: IgnoredFieldCheck<T>;
	timespent: IgnoredFieldCheck<T>;
	subtasks: IgnoredFieldCheck<T>;
	statuscategorychangedate: IgnoredFieldCheck<T>;
	project: IgnoredFieldCheck<T>;
	security: IgnoredFieldCheck<T>;
	progress: IgnoredFieldCheck<T>;
	lastViewed: IgnoredFieldCheck<T>;
	issuerestriction: IgnoredFieldCheck<T>;
};

const checkedFieldTypesIcons = {
	[NUMBER_CF_TYPE]: <ResizedIcon glyph={numberGlyph} />,
	[STORY_POINT_ESTIMATE_CF_TYPE]: <ResizedIcon glyph={numberGlyph} />,
	[LABELS_CF_TYPE]: <ResizedIcon glyph={labelGlyph} />,
	[DATE_CF_TYPE]: <ResizedIcon glyph={dateGlyph} />,
	[DATETIME_CF_TYPE]: <ResizedIcon glyph={timeStampGlyph} />,
	[RADIO_BUTTONS_CF_TYPE]: <ResizedIcon glyph={radioGlyph} />,
	[SELECT_CF_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[MULTI_SELECT_CF_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[CASCADING_SELECT_CF_TYPE]: <ResizedIcon glyph={doubleDropdownGlyph} />,
	[MULTI_VERSION_CF_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[VERSION_CF_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[GROUP_CF_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[MULTI_GROUP_CF_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[USER_CF_TYPE]: <ResizedIcon glyph={peopleGlyph} />,
	[USERPICKER_FIELD_TYPE]: <ResizedIcon glyph={peopleGlyph} />,
	[MULTI_USER_CF_TYPE]: <ResizedIcon glyph={peopleGlyph} />,
	[MULTIUSERPICKER_FIELD_TYPE]: <ResizedIcon glyph={peopleGlyph} />,
	[PEOPLE_CF_TYPE]: <ResizedIcon glyph={peopleGlyph} />,
	[FLAGGED_CF_TYPE]: <ResizedIcon glyph={timeStampGlyph} />,
	[URL_CF_TYPE]: <ResizedIcon glyph={customFieldGlyph} />,
	[TEAM_CF_TYPE]: <ResizedIcon glyph={customFieldGlyph} />,
	// <ResizedIcon /> was introduced when SWIFT had a limited number of supported field types,
	// but it's no longer a scalable option. We should be using icons directly provided by @atlaskit,
	// and if it does not match our design constraints, then we'll need to investigate contributing to a new @atlaskit icon set.
	[TEAMS_PLATFORM_CF_TYPE]: <PeopleGroupIcon spacing="spacious" label="" />,
	[PARENT_CF_TYPE]: <ResizedIcon glyph={customFieldGlyph} />,
	[BASELINE_START_CF_TYPE]: <ResizedIcon glyph={dateGlyph} />,
	[BASELINE_END_CF_TYPE]: <ResizedIcon glyph={dateGlyph} />,
	[SPRINT_CF_GLOBAL_TYPE]: <ResizedIcon glyph={shipGlyph} />,
	[REQUEST_PARTICIPANTS_CF_TYPE]: <ResizedIcon glyph={peopleGroupGlyph} />,
	[ORGANIZATIONS_CF_TYPE]: <ResizedIcon glyph={officeBuildingGlyph} />,
	[REPORTER_FIELD_TYPE]: <ResizedIcon glyph={peopleGlyph} />,
	[ATTACHMENT_FIELD_TYPE]: <ResizedIcon glyph={attachmentGlyph} />,
	[ASSIGNEE_FIELD_TYPE]: <ResizedIcon glyph={peopleGlyph} />,
	[LABELS_FIELD_TYPE]: <ResizedIcon glyph={labelGlyph} />,
	[PRIORITY_FIELD_TYPE]: <ResizedIcon glyph={priorityGlyph} />,
	[DUE_DATE_FIELD_TYPE]: <ResizedIcon glyph={dateGlyph} />,
	[TIME_ESTIMATE_FIELD_TYPE]: <ResizedIcon glyph={timeStampGlyph} />,
	[TIME_TRACKING_FIELD_TYPE]: <ResizedIcon glyph={timeTrackingGlyph} />,
	[ORIGINAL_TIME_ESTIMATE_FIELD_TYPE]: <ResizedIcon glyph={originalTimeEstimateGlyph} />,
	[ASSETS_CF_TYPE]: <ResizedIcon glyph={screenGlyph} />,
	[SERVICE_ENTITY_CF_TYPE]: <ResizedIcon glyph={labelGlyph} />,
	[STATUS_FIELD_TYPE]: <ResizedIcon glyph={arrowRightGlyph} />,
	[SUMMARY_FIELD_TYPE]: <ResizedIcon glyph={shortTextGlyph} />,
	[DESCRIPTION_FIELD_TYPE]: <ResizedIcon glyph={paragraphGlyph} />,
	[TEXT_CF_TYPE]: <ResizedIcon glyph={shortTextGlyph} />,
	[TEXT_AREA_CF_TYPE]: <ResizedIcon glyph={paragraphGlyph} />,
	[MULTI_CHECKBOXES_CF_TYPE]: <ResizedIcon glyph={checkboxGlyph} />,
	[ISSUE_LINKS_FIELD_TYPE]: <ResizedIcon glyph={customFieldGlyph} />,
	[ENVIRONMENT_FIELD_TYPE]: <ResizedIcon glyph={paragraphGlyph} />,
	[FIX_VERSIONS_FIELD_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[COMMENT_FIELD_TYPE]: <ResizedIcon glyph={commentGlyph} />,
	[APPROVERS_LIST_CF_TYPE]: <ResizedIcon glyph={appAccessGlyph} />,
	[APPROVAL_DATA_FIELD_TYPE]: <ResizedIcon glyph={appAccessGlyph} />,
	[PROJECT_PICKER_CF_TYPE]: <ResizedIcon glyph={folderGlyph} />,
	[MESSAGE_EDIT_CF_TYPE]: <ResizedIcon glyph={paragraphGlyph} />,
	[MESSAGE_VIEW_CF_TYPE]: <ResizedIcon glyph={paragraphGlyph} />,
	[CATEGORY_TYPE]: <ResizedIcon glyph={jwmCategoryGlyph} />,
	[ENTITLEMENT_CF_TYPE]: <ResizedIcon glyph={dropdownGlyph} />,
	[SENTIMENT_CF_TYPE]: <ResizedIcon glyph={customFieldGlyph} />,
	[GOALS_CF_TYPE]: <GoalIcon label="" spacing="spacious" color={token('color.icon')} />,
	[ORGANIZATION_CF_TYPE]: <ResizedIcon glyph={customFieldGlyph} />,
} as const;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
({ ...checkedFieldTypesIcons }) as CheckFieldTypeDefinitions<
	CheckedLegacyFieldTypeDefinitions<ReactElement<ComponentProps<typeof ResizedIcon | typeof Icon>>>,
	IgnoredFieldChecks<ReactElement<ComponentProps<typeof ResizedIcon | typeof Icon>>>
>;

/**
 * @deprecated - Use checkedFieldTypesGlyphs or StrictFieldTypeIcon instead
 */
export const fieldTypesIcons: {
	[key: string]: ReactElement<ComponentProps<typeof ResizedIcon | typeof Icon>>;
} = checkedFieldTypesIcons;

export const panelIcons: {
	[key: string]: ReactElement;
} = {
	development: <BranchesIcon label="" LEGACY_size="medium" spacing="spacious" />,
	devSummary: <BranchesIcon label="" LEGACY_size="medium" spacing="spacious" />,
	timetracking: <StopwatchIcon label="" LEGACY_size="medium" spacing="spacious" />,
};

/**
 * @deprecated - consider using allFieldTypeGlyphs or FieldTypeIcon instead
 */
const allIcons: {
	[key: string]: ReactElement<ComponentProps<typeof ResizedIcon | typeof Icon>>;
} = {
	...fieldTypesIcons,
	text: <ResizedIcon glyph={paragraphGlyph} />,
	number: <ResizedIcon glyph={numberGlyph} />,
	date: <ResizedIcon glyph={dateGlyph} />,
	datetime: <ResizedIcon glyph={timeStampGlyph} />,
	dropdown: <ResizedIcon glyph={dropdownGlyph} />,
	addon: <ResizedIcon glyph={addonGlyph} />,
	checkbox: <ResizedIcon glyph={checkboxGlyph} />,
	user: <ResizedIcon glyph={peopleGlyph} />,
	radio: <ResizedIcon glyph={radioGlyph} />,
	time: <ResizedIcon glyph={timeStampGlyph} />,
	message: <ResizedIcon glyph={commentGlyph} />,
	bulletList: <ResizedIcon glyph={bulletListGlyph} />,
	label: <ResizedIcon glyph={labelGlyph} />,
	labelpicker: <ResizedIcon glyph={labelGlyph} />,
	close: <ResizedIcon glyph={closeGlyph} />,
	assetspicker: <ResizedIcon glyph={screenGlyph} />,
	select: <ResizedIcon glyph={dropdownGlyph} />,
	multiselect: <ResizedIcon glyph={dropdownGlyph} />,
	cascadingselect: <ResizedIcon glyph={doubleDropdownGlyph} />,
	multigrouppicker: <ResizedIcon glyph={dropdownGlyph} />,
	grouppicker: <ResizedIcon glyph={dropdownGlyph} />,
	multiversion: <ResizedIcon glyph={dropdownGlyph} />,
	version: <ResizedIcon glyph={dropdownGlyph} />,
	projectpicker: <ResizedIcon glyph={dropdownGlyph} />,
	customField: <ResizedIcon glyph={customFieldGlyph} />,
};

const checkedFieldTypesGlyphs: {
	[key: string]: (props: ComponentProps<'svg'>) => JSX.Element;
} = {
	[NUMBER_CF_TYPE]: numberGlyph,
	[STORY_POINT_ESTIMATE_CF_TYPE]: numberGlyph,
	[LABELS_CF_TYPE]: labelGlyph,
	[DATE_CF_TYPE]: dateGlyph,
	[DATETIME_CF_TYPE]: timeStampGlyph,
	[RADIO_BUTTONS_CF_TYPE]: radioGlyph,
	[SELECT_CF_TYPE]: dropdownGlyph,
	[MULTI_SELECT_CF_TYPE]: dropdownGlyph,
	[CASCADING_SELECT_CF_TYPE]: doubleDropdownGlyph,
	[MULTI_VERSION_CF_TYPE]: dropdownGlyph,
	[VERSION_CF_TYPE]: dropdownGlyph,
	[GROUP_CF_TYPE]: dropdownGlyph,
	[MULTI_GROUP_CF_TYPE]: dropdownGlyph,
	[USER_CF_TYPE]: peopleGlyph,
	[USERPICKER_FIELD_TYPE]: peopleGlyph,
	[MULTI_USER_CF_TYPE]: peopleGlyph,
	[MULTIUSERPICKER_FIELD_TYPE]: peopleGlyph,
	[PEOPLE_CF_TYPE]: peopleGlyph,
	[FLAGGED_CF_TYPE]: timeStampGlyph,
	[URL_CF_TYPE]: customFieldGlyph,
	[TEAM_CF_TYPE]: customFieldGlyph,
	[PARENT_CF_TYPE]: customFieldGlyph,
	[BASELINE_START_CF_TYPE]: dateGlyph,
	[BASELINE_END_CF_TYPE]: dateGlyph,
	[SPRINT_CF_GLOBAL_TYPE]: shipGlyph,
	[REQUEST_PARTICIPANTS_CF_TYPE]: peopleGroupGlyph,
	[ORGANIZATIONS_CF_TYPE]: officeBuildingGlyph,
	[REPORTER_FIELD_TYPE]: peopleGlyph,
	[ATTACHMENT_FIELD_TYPE]: attachmentGlyph,
	[ASSIGNEE_FIELD_TYPE]: peopleGlyph,
	[LABELS_FIELD_TYPE]: labelGlyph,
	[PRIORITY_FIELD_TYPE]: priorityGlyph,
	[DUE_DATE_FIELD_TYPE]: dateGlyph,
	[TIME_ESTIMATE_FIELD_TYPE]: timeStampGlyph,
	[TIME_TRACKING_FIELD_TYPE]: timeTrackingGlyph,
	[ORIGINAL_TIME_ESTIMATE_FIELD_TYPE]: originalTimeEstimateGlyph,
	[ASSETS_CF_TYPE]: screenGlyph,
	[SERVICE_ENTITY_CF_TYPE]: labelGlyph,
	[STATUS_FIELD_TYPE]: arrowRightGlyph,
	[SUMMARY_FIELD_TYPE]: shortTextGlyph,
	[DESCRIPTION_FIELD_TYPE]: paragraphGlyph,
	[TEXT_CF_TYPE]: shortTextGlyph,
	[TEXT_AREA_CF_TYPE]: paragraphGlyph,
	[MULTI_CHECKBOXES_CF_TYPE]: checkboxGlyph,
	[ISSUE_LINKS_FIELD_TYPE]: customFieldGlyph,
	[ENVIRONMENT_FIELD_TYPE]: paragraphGlyph,
	[FIX_VERSIONS_FIELD_TYPE]: dropdownGlyph,
	[COMMENT_FIELD_TYPE]: commentGlyph,
	[APPROVERS_LIST_CF_TYPE]: appAccessGlyph,
	[APPROVAL_DATA_FIELD_TYPE]: appAccessGlyph,
	[PROJECT_PICKER_CF_TYPE]: folderGlyph,
	[MESSAGE_EDIT_CF_TYPE]: paragraphGlyph,
	[MESSAGE_VIEW_CF_TYPE]: paragraphGlyph,
	[CATEGORY_TYPE]: jwmCategoryGlyph,
	[ENTITLEMENT_CF_TYPE]: dropdownGlyph,
	[SENTIMENT_CF_TYPE]: customFieldGlyph,
	[ORGANIZATION_CF_TYPE]: customFieldGlyph,
} as const;

export const allFieldTypeGlyphs: {
	[key: string]: (props: ComponentProps<'svg'>) => JSX.Element;
} = {
	...checkedFieldTypesGlyphs,
	text: paragraphGlyph,
	number: numberGlyph,
	date: dateGlyph,
	datetime: timeStampGlyph,
	dropdown: dropdownGlyph,
	addon: addonGlyph,
	checkbox: checkboxGlyph,
	user: peopleGlyph,
	radio: radioGlyph,
	time: timeStampGlyph,
	message: commentGlyph,
	bulletList: bulletListGlyph,
	label: labelGlyph,
	labelpicker: labelGlyph,
	close: closeGlyph,
	assetspicker: screenGlyph,
	select: dropdownGlyph,
	multiselect: dropdownGlyph,
	cascadingselect: doubleDropdownGlyph,
	multigrouppicker: dropdownGlyph,
	grouppicker: dropdownGlyph,
	multiversion: dropdownGlyph,
	version: dropdownGlyph,
	projectpicker: dropdownGlyph,
	customField: customFieldGlyph,
};

export type FieldTypeIconProps = {
	type: string;
	size?: Size;
	testId?: string;
};

export const StrictFieldTypeIcon = memo(({ type, size = 'medium', testId }: FieldTypeIconProps) => {
	if (type === TEAMS_PLATFORM_CF_TYPE) {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		return <PeopleIcon label="" size={size} testId={testId} />;
	}

	if (type === GOALS_CF_TYPE) {
		return <GoalIcon label="" spacing="spacious" testId={testId} color={token('color.icon')} />;
	}

	return (
		<Icon
			label=""
			glyph={checkedFieldTypesGlyphs[type] || checkedFieldTypesGlyphs.customField}
			size={size}
			testId={testId}
		/>
	);
});

export const FieldTypeIcon = memo(({ type, size = 'medium', testId }: FieldTypeIconProps) => {
	if (type === TEAMS_PLATFORM_CF_TYPE) {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		return <PeopleIcon label="" size={size} testId={testId} />;
	}

	if (type === GOALS_CF_TYPE) {
		return <GoalIcon label="" spacing="spacious" testId={testId} color={token('color.icon')} />;
	}

	return (
		<Icon
			label=""
			glyph={allFieldTypeGlyphs[type] || allFieldTypeGlyphs.customField}
			size={size}
			testId={testId}
		/>
	);
});

/**
 * @deprecated - Use allFieldTypeGlyphs or FieldTypeIcon instead.
 */
export default allIcons;
