import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	exportButtonText: {
		id: 'issue-navigator-action-export-issues.export-button-text',
		defaultMessage: 'Export',
		description: 'The button text for the export button',
	},
	exportIssuesButtonIconLabel: {
		id: 'issue-navigator-action-export-issues.export-issues-button-icon-label',
		defaultMessage: 'Open export issues dropdown',
		description: 'The label text for the export issues button icon',
	},
});

export default messages;
