/**
 * @generated SignedSource<<ea561c81d2a9ee87a588c604cb3879c2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_issueSearchData$data = {
  readonly jira: {
    readonly issueSearch?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly startCursor: string | null | undefined;
      };
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_issueResults" | "selectedIssue_issueNavigator">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_issueSearchData";
};
export type main_issueNavigator_IssueNavigator_issueSearchData$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_issueSearchData$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueSearchData">;
};

import IssueNavigatorIssueSearchPaginationQuery_graphql from './IssueNavigatorIssueSearchPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "jira",
  "issueSearch"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetIds"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsContext"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsInput"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "issueSearchInput"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "options"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    },
    {
      "kind": "RootArgument",
      "name": "scope"
    },
    {
      "kind": "RootArgument",
      "name": "shouldQueryFieldSetsById"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldQueryHasChildren"
    },
    {
      "kind": "RootArgument",
      "name": "viewConfigInput"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": IssueNavigatorIssueSearchPaginationQuery_graphql
    }
  },
  "name": "main_issueNavigator_IssueNavigator_issueSearchData",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": "issueSearch",
              "args": [
                {
                  "kind": "Variable",
                  "name": "issueSearchInput",
                  "variableName": "issueSearchInput"
                }
              ],
              "concreteType": "JiraIssueConnection",
              "kind": "LinkedField",
              "name": "__IssueNavigatorIssueSearchPagination__issueSearch_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    },
                    {
                      "concreteType": "JiraIssue",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "startCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasPreviousPage"
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "selectedIssue_issueNavigator"
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "projectKey",
                      "variableName": "projectKey"
                    },
                    {
                      "kind": "Variable",
                      "name": "projectKeys",
                      "variableName": "projectKeys"
                    },
                    {
                      "kind": "Variable",
                      "name": "shouldQueryHasChildren",
                      "variableName": "shouldQueryHasChildren"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "main_issueNavigator_IssueNavigatorUI_issueResults"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "2effd46440f8aee61775fe79bc8cc67a";

export default node;
