// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md

import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import type { SVGViewBox } from '@atlassian/jira-illustration/src/ui/types.tsx';
import srcLight from '../../assets/error-triangle-basic.svg';
import srcDark from '../../assets/error-triangle-basic-darkmode.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
export const viewBox: SVGViewBox = [0, 0, 164, 212];
