import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueLoadingLabel: {
		id: 'issue-navigator.issue-results.detail-view.card-list.card-skeleton.issue-loading-label',
		defaultMessage: 'Issue card {number} loading',
		description: 'ScreenReader label for a card when it is still loading',
	},
	issueLoadingLabelIssueTermRefresh: {
		id: 'issue-navigator.issue-results.detail-view.card-list.card-skeleton.issue-loading-label-issue-term-refresh',
		defaultMessage: 'Work item card {number} loading',
		description: 'ScreenReader label for a card when it is still loading',
	},
});
