/**
 * @generated SignedSource<<7ebdd1820e138dcf11f68ac2778906c4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Card_issue$data = {
  readonly fieldSetsForIssueSearchView?: {
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_fieldSets">;
  };
  readonly isResolved: boolean | null | undefined;
  readonly key: string;
  readonly " $fragmentType": "main_issueNavigator_Card_issue";
};
export type main_issueNavigator_Card_issue$key = {
  readonly " $data"?: main_issueNavigator_Card_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_issue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "fieldSetsContext"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_Card_issue",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "ScalarField",
      "name": "isResolved"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "args": [
              {
                "kind": "Variable",
                "name": "context",
                "variableName": "fieldSetsContext"
              },
              {
                "kind": "Variable",
                "name": "filterId",
                "variableName": "filterId"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "amountOfColumns"
              },
              {
                "kind": "Variable",
                "name": "namespace",
                "variableName": "namespace"
              },
              {
                "kind": "Variable",
                "name": "viewId",
                "variableName": "viewId"
              }
            ],
            "concreteType": "JiraIssueFieldSetConnection",
            "kind": "LinkedField",
            "name": "fieldSetsForIssueSearchView",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_Card_fieldSets"
              }
            ]
          },
          "action": "THROW",
          "path": "fieldSetsForIssueSearchView"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "c7905d3b95940a78e8b4bd1557d28ea2";

export default node;
