import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createSelector } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getSelectedIssueIndex = createSelector<State, void, number, IssueKey, IssueKey[]>(
	(state) => state.selectedIssueKey,
	(state) => state.issueKeys,
	(selectedIssueKey, issueKeys) => issueKeys.indexOf(selectedIssueKey),
);

export const getNextIssueKey = (state: State): IssueKey | null => {
	const index = getSelectedIssueIndex(state);
	const { issueKeys } = state;
	return index > -1 && index < issueKeys.length - 1 ? issueKeys[index + 1] : null;
};

export const getPreviousIssueKey = (state: State): IssueKey | null => {
	const index = getSelectedIssueIndex(state);
	const { issueKeys } = state;
	return index > 0 ? issueKeys[index - 1] : null;
};

export const getIssuesCount = (state: State): number => {
	return state.issueKeys.length;
};
