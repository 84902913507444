import React from 'react';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	position: 'top' | 'bottom';
};

export const LoadingIndicator = ({ position }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Inline
			alignInline="center"
			xcss={[containerStyles, position === 'top' ? positionTopStyles : positionBottomStyles]}
		>
			<Inline space="space.100" alignBlock="center" grow="hug" xcss={loadingIndicatorStyles}>
				<Spinner
					appearance="invert"
					size="small"
					interactionName={
						fg('add_nin_press_interactions') ? 'nin-detail-view-infinite-scroll-loading' : undefined
					}
				/>
				<Text color="color.text.inverse">
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.loadingMessageIssueTermRefresh
							: messages.loadingMessage,
					)}
				</Text>
			</Inline>
		</Inline>
	);
};

const positionTopStyles = xcss({ top: 'space.0' });
const positionBottomStyles = xcss({ bottom: 'space.0' });

const loadingIndicatorStyles = xcss({
	backgroundColor: 'color.background.neutral.bold',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.overlay',
	color: 'color.text.inverse',
	marginBlock: 'space.200',
	padding: 'space.100',
});

const containerStyles = xcss({
	position: 'absolute',
	width: '100%',
});
