import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { State } from '../../common/types.tsx';

type AnalyticsProps = {
	sidebarId: string;
	createAnalyticsEvent: (_payload: AnalyticsEventPayload) => UIAnalyticsEvent;
};

const fireSidebarAnalytics = (
	experienceId: string,
	analyticsProps: AnalyticsProps,
	eventType: 'started' | 'ended',
) => {
	const { sidebarId, createAnalyticsEvent } = analyticsProps;
	const sidebarLocationId = `jira${sidebarId}SidebarExperience`;

	try {
		fireTrackAnalytics(createAnalyticsEvent({}), `${sidebarLocationId} ${eventType}`, {
			experienceId,
		});
	} catch (error: unknown) {
		log.safeErrorWithoutCustomerData(
			'jiraPlatformSidebar',
			error instanceof Error
				? error.message
				: `Error firing analytics event for sidebar with id ${sidebarId}`,
		);
	}
};

const fireStartSidebarAnalytics = (experienceId: string, analyticsProps: AnalyticsProps) => {
	fireSidebarAnalytics(experienceId, analyticsProps, 'started');
};

const fireCloseSidebarAnalytics = (experienceId: string, analyticsProps: AnalyticsProps) => {
	fireSidebarAnalytics(experienceId, analyticsProps, 'ended');
};

const setExperienceId =
	(id: string) =>
	(
		{ setState, getState }: { setState: (arg1: State) => void; getState: () => State },
		analyticsProps: AnalyticsProps,
	) => {
		const state = getState();
		const newState = { id, content: null };
		const newId = newState.id;
		const previousId = state.id;

		if (analyticsProps.sidebarId) {
			fireStartSidebarAnalytics(newId, analyticsProps);
			if (previousId != null) {
				fireCloseSidebarAnalytics(previousId, analyticsProps);
			}
		}

		setState({
			...state,
			...newState,
		});
	};

const closeSidebar =
	() =>
	(
		{ setState, getState }: { setState: (arg1: State) => void; getState: () => State },
		analyticsProps: AnalyticsProps,
	) => {
		const state = getState();
		const newState = { id: null, content: null };

		if (analyticsProps.sidebarId && state.id) {
			fireCloseSidebarAnalytics(state.id, analyticsProps);
		}

		setState({
			...state,
			...newState,
		});
	};

const setStaticSidebarContent =
	(id: string, content: React.ReactElement | null) =>
	({ setState, getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		const state = getState();
		setState({
			...state,
			content,
			id,
		});
	};

export const actions = {
	setExperienceId,
	closeSidebar,
	setStaticSidebarContent,
} as const;
