/**
 * @generated SignedSource<<bccaea827099599c35efdd1a65270e81>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_groupResults$data = {
  readonly errors: ReadonlyArray<{
    readonly extensions: ReadonlyArray<{
      readonly statusCode: number | null | undefined;
    }> | null | undefined;
    readonly message: string | null | undefined;
  }> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"jqlBuilder_issueNavigator_JQLBuilderWrapper_groupResults" | "main_issueNavigator_IssueResults_groupResults">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_groupResults";
};
export type main_issueNavigator_IssueNavigatorUI_groupResults$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_groupResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_groupResults",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_groupResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_groupResults"
    },
    {
      "concreteType": "QueryError",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "extensions",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "statusCode"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "message"
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "5d6a696ce62196724f5906b5a505895e";

export default node;
