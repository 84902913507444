import React, { type ReactNode } from 'react';
import { styled, css } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { withRecoverableErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/recoverable-error-boundary/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { headerActions_issueNavigator$key as HeaderActionsKey } from '@atlassian/jira-relay/src/__generated__/headerActions_issueNavigator.graphql';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useSelectedIssueStateOldActions } from '../../../../controllers/selected-issue-state-old/index.tsx';
import {
	useSelectedIssueIndex,
	useIssuesCount,
} from '../../../../controllers/selected-issue/hooks.tsx';
import IssuePagination from './issue-pagination/index.tsx';

export type Props = {
	/**
	 * Optional react node to render in the header above the issue app.
	 */
	extraHeaderActions?: ReactNode;
	/**
	 * Flag to adjust the container spacing based on whether the component is in an embedded or full page layout.
	 */
	isEmbedView: boolean;
	/**
	 * Relay fragment for issue search data.
	 */
	issueResults: HeaderActionsKey | null;
	/**
	 * Max-width applied to the issue app that should be set on the header actions container to ensure correct alignment.
	 */
	maxWidth?: number;
};

/**
 * Render extraHeaderActions if provided or otherwise return an empty render.
 */
const HeaderActionsFallback = ({ extraHeaderActions, isEmbedView, maxWidth }: Props) =>
	extraHeaderActions ? (
		<HeaderActionsContainer
			maxWidth={maxWidth}
			isEmbedView={isEmbedView}
			isVREnabled={isVisualRefreshEnabled() && fg('visual-refresh_drop_6')}
		>
			{extraHeaderActions}
		</HeaderActionsContainer>
	) : null;

/**
 * Render issue pagination controls and extraHeaderActions if provided.
 */
const HeaderActions = (props: Props) => {
	const { extraHeaderActions, issueResults, maxWidth, isEmbedView } = props;
	const issueResultsData = useFragment<HeaderActionsKey>(
		graphql`
			fragment headerActions_issueNavigator on JiraIssueConnection {
				...issuePagination_issueNavigator
			}
		`,
		issueResults,
	);

	let issuesCount = 0;
	if (isRefactorNinToViewSchemaEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		issuesCount = useIssuesCount();
	}

	let selectedIssuePosition;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const selectedIssueIndex = useSelectedIssueIndex();
		// If selectedIssueIndex is negative then our selected issue key does not exist in the result set, so there
		// are no pagination controls to show.
		// If issueResultsData is null and issuesCount is not zero it means the grouping is enabled
		if (selectedIssueIndex === -1 || (issueResultsData == null && issuesCount === 0)) {
			return <HeaderActionsFallback {...props} />;
		}
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { getSelectedIssuePosition } = useSelectedIssueStateOldActions();
		selectedIssuePosition = getSelectedIssuePosition();
		// If selectedIssuePosition is not defined then our selected issue key does not exist in the result set, so there
		// are no pagination controls to show.
		if (selectedIssuePosition == null || issueResultsData == null) {
			return <HeaderActionsFallback {...props} />;
		}
	}

	return (
		<HeaderActionsContainer
			maxWidth={maxWidth}
			isEmbedView={isEmbedView}
			isVREnabled={isVisualRefreshEnabled() && fg('visual-refresh_drop_6')}
		>
			{extraHeaderActions ?? null}
			<IssuePagination
				issueResults={issueResultsData}
				// TODO Remove prop when cleaning up jira_spreadsheet_component_m1
				selectedIssuePosition={selectedIssuePosition}
			/>
		</HeaderActionsContainer>
	);
};

export default withRecoverableErrorBoundary<Props>(HeaderActions, {
	recoveryProp: 'issueResults',
	Fallback: HeaderActionsFallback,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderActionsContainer = styled.div<{
	isEmbedView: boolean;
	isVREnabled: boolean;
	maxWidth?: number;
}>(
	{
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '0 auto',
		display: 'flex',
		width: '100%',
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEmbedView }) =>
		isEmbedView
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					paddingBottom: token('space.100'),
				})
			: undefined,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ maxWidth }) =>
		maxWidth
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					maxWidth: `${maxWidth}px`,
				})
			: undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isVREnabled }) =>
		isVREnabled
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
					'> :last-child': {
						// Extra right margin is added to ensure our controls align with the issue app's right sidebar
						marginRight: token('space.300'),
					},
				})
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
					'> :last-child': {
						// Adjust the right margin to align with visual refresh
						marginRight: token('space.250'),
					},
				}),
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> * + *': {
			marginLeft: token('space.100'),
		},
	},
);
