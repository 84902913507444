import React from 'react';
import type { FooterProps } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import { components } from '@atlassian/jira-native-issue-table/src/common/ui/components/index.tsx';
import { useIssueSearchQuery } from '../../../../services/issue-search-query/index.tsx';

/**
 * Override default issue table footer to conditionally hide pagination controls when issue data is stale.
 */
// TODO Remove when cleaning up jira_spreadsheet_component_m1
export const Footer = ({ end, center, ...props }: FooterProps) => {
	const { isStalePage } = useIssueSearchQuery();
	return (
		<components.Footer
			{...props}
			// Do not show pagination controls while issue data is stale as page cursor information will be inaccurate
			center={isStalePage && props.isNewFooterOrderEnabled ? undefined : center}
			end={isStalePage && !props.isNewFooterOrderEnabled ? undefined : end}
		/>
	);
};
