import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadingMessage: {
		id: 'issue-navigator.issue-results.detail-view.card-container.loading-manager.loading-indicator.loading-message',
		defaultMessage: 'Loading more issues',
		description: 'Text displayed in the loading indicator when more table rows are being loaded',
	},
	loadingMessageIssueTermRefresh: {
		id: 'issue-navigator.issue-results.detail-view.card-container.loading-manager.loading-indicator.loading-message-issue-term-refresh',
		defaultMessage: 'Loading more work items',
		description: 'Text displayed in the loading indicator when more table rows are being loaded',
	},
});
