import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { viewDetailsPopupExperience } from '../../../experiences.tsx';
import type { Props } from './index.tsx';

export // eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPopupContent = lazy(
	() =>
		import(
			/* webpackChunkName: "async-issue-navigator-actions-custom-filters-details-popup-content" */ './index'
		),
);

export const preloadPopupContentComponent = () => LazyPopupContent.preload();

const LoadingFallback = () => (
	<LoadingWrapper>
		<Spinner />
	</LoadingWrapper>
);

export const PopupContent = (props: Props) => {
	useUFOComponentExperience(viewDetailsPopupExperience);
	return (
		<Placeholder name="popup-content" fallback={<LoadingFallback />}>
			<LazyPopupContent {...props} />
		</Placeholder>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ContentWrapper = styled.div({
	width: '440px',

	paddingTop: token('space.250'),
	paddingRight: token('space.250'),
	paddingBottom: token('space.250'),
	paddingLeft: token('space.250'),
	overflow: 'auto',
	maxHeight: 'calc(100vh - 215px)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LoadingWrapper = styled(ContentWrapper)({
	textAlign: 'center',
});
