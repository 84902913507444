import { useCallback, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import type { IssueNavigatorIssueSearchPaginationQuery$variables } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchPaginationQuery.graphql';
import type { IssueNavigatorIssueSearchRefetchQuery$variables } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import type {
	JiraIssueSearchInput,
	main_IssueNavigatorQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/main_IssueNavigatorQuery.graphql';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { NIN_PROJECT_SHADOW_REQUEST_OPERATION_SCOPE } from '../../common/constants.tsx';

type ShadowRequestVariables =
	| {
			cloudId: string;
			issueSearchInput: JiraIssueSearchInput;
			viewId: string;
	  }
	| IssueNavigatorIssueSearchRefetchQuery$variables
	| IssueNavigatorIssueSearchPaginationQuery$variables
	| main_IssueNavigatorQuery$variables;

export const fetchShadowRequest = async (
	variables: ShadowRequestVariables,
	query: string,
	operationName: string,
	isGrouping = false,
) => {
	try {
		await fetchJson('/gateway/api/graphql', {
			method: 'POST',
			headers: {
				'X-ExperimentalApi': 'JiraIssueSearch, JiraProject',
			},
			body: JSON.stringify({
				operationName,
				query,
				variables: {
					...variables,
					namespace: 'ISSUE_NAVIGATOR',
					amountOfColumns: 500,
					scope: { operationScope: NIN_PROJECT_SHADOW_REQUEST_OPERATION_SCOPE },
					// we want to use the new view schema for grouping
					isRefactorNinToViewSchemaEnabled: isGrouping,
					__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider:
						expVal('bulk_operations_in_nin_experiment', 'isEnabled', false),
					__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider:
						true,
					// we want to use the new view schema for grouping
					__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider:
						isGrouping,
					__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: 1,
					__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider:
						true,
					__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: true,
					__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider:
						true,
					__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider:
						true,
					__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider:
						true,
					__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider:
						true,
					__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider: true,
				},
			}),
		});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			attributes: { message: `Shadow request for nin issue search ${operationName} failed` },
			meta: {
				id: 'shadow-request-for-nin-issue-search',
				packageName: 'jiraIssueNavigator',
				teamName: 'empanada',
			},
		});
	}
};

const getIssueNavigatorIssueSearchRefetchQueryShadowOperation = (isGrouping = false) => {
	const operation = import(
		/* webpackChunkName: "async-shadow-issue-search-refetch-query" */ '@atlassian/jira-relay/./src/common/mocks/IssueNavigatorIssueSearchRefetchQuery.json'
	).then((module) => {
		const operationName = `IssueNavigatorIssueSearchRefetch${isGrouping ? 'Grouping' : ''}QueryShadow`;
		return {
			operationName,
			operation: module.operation.replace('IssueNavigatorIssueSearchRefetchQuery', operationName),
		};
	});
	return operation;
};

const getIssueNavigatorIssueSearchPaginationQueryShadowOperation = () => {
	const operation = import(
		/* webpackChunkName: "async-shadow-issue-search-pagination-query" */ '@atlassian/jira-relay/./src/common/mocks/IssueNavigatorIssueSearchPaginationQuery.json'
	).then((module) => ({
		operationName: 'IssueNavigatorIssueSearchPaginationQueryShadow',
		operation: module.operation.replace(
			'IssueNavigatorIssueSearchPaginationQuery',
			'IssueNavigatorIssueSearchPaginationQueryShadow',
		),
	}));
	return operation;
};

const getMainIssueNavigatorQueryShadowOperation = (isGrouping = false) => {
	const operation = import(
		/* webpackChunkName: "async-shadow-main-issue-navigator-query" */ '@atlassian/jira-relay/./src/common/mocks/main_IssueNavigatorQuery.json'
	).then((module) => {
		const operationName = `main_IssueNavigator${isGrouping ? 'Grouping' : ''}QueryShadow`;
		return {
			operationName,
			operation: module.operation.replace('main_IssueNavigatorQuery', operationName),
		};
	});
	return operation;
};

const catchedCallback = <T extends Array<A>, A>(cb: (...args: T) => void) => {
	return (...args: T) => {
		try {
			cb(...args);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				error,
				attributes: { message: 'Queue shadow request for nin issue search failed' },
				meta: {
					id: 'queue-shadow-request-for-nin-issue-search',
					packageName: 'jiraIssueNavigator',
					teamName: 'empanada',
				},
			});
		}
	};
};

const catchedCallbackAsync = <T extends Array<A>, A>(cb: (...args: T) => Promise<void>) => {
	return async (...args: T) => {
		try {
			await cb(...args);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				error,
				attributes: { message: 'Queue shadow request for nin issue search failed' },
				meta: {
					id: 'queue-shadow-request-for-nin-issue-search',
					packageName: 'jiraIssueNavigator',
					teamName: 'empanada',
				},
			});
		}
	};
};

export const queueShadowRequest = catchedCallback(
	(
		getOperation: () => Promise<{
			operationName: string;
			operation: string;
		}>,
		variables: ShadowRequestVariables,
		isGrouping: boolean | undefined = false,
	) => {
		if (__SERVER__) return;

		// requestIdleCallback is not supported in Safari, so we need a workaround.
		if (typeof window !== 'undefined' && typeof window.requestIdleCallback !== 'undefined') {
			window.requestIdleCallback(
				catchedCallback(() =>
					setTimeout(
						catchedCallback(() => {
							window.requestIdleCallback(
								catchedCallbackAsync(async () => {
									const query = await getOperation();
									fetchShadowRequest(variables, query.operation, query.operationName, isGrouping);
								}),
							);
						}),
						5000,
					),
				),
			);
		}
	},
);

export const fetchIssueNavigatorIssueSearchRefetchQueryShadow = (
	variables: IssueNavigatorIssueSearchRefetchQuery$variables,
	isGrouping: boolean,
) => {
	queueShadowRequest(
		() => getIssueNavigatorIssueSearchRefetchQueryShadowOperation(isGrouping),
		variables,
		isGrouping,
	);
};

export const useMainIssueNavigatorQueryShadowRequest = (
	variables: main_IssueNavigatorQuery$variables,
	viewId: string,
) => {
	useEffect(() => {
		if (variables.projectKey && viewId !== 'detail') {
			if (fg('jira_list_hierarchy_shadow_requests')) {
				queueShadowRequest(getMainIssueNavigatorQueryShadowOperation, variables, false);
			} else if (fg('jira_list_group_shadow_requests')) {
				const groupByFields = [
					'status',
					'assignee',
					'priority',
					'com.atlassian.jira.plugin.system.customfieldtypes:float',
					'com.pyxis.greenhopper.jira:jsw-story-points',
					'com.pyxis.greenhopper.jira:gh-sprint',
					'com.atlassian.jira.plugin.system.customfieldtypes:jwm-category',
				];

				// Switch between the main query with refactor enabled and with group by overrides
				const variableOverrides = fg('jira_list_main_shadow_query_group_overrides')
					? {
							staticViewInput: { isHierarchyEnabled: false, isGroupingEnabled: true },
							viewConfigInput: {
								staticViewInput: { isHierarchyEnabled: false, isGroupingEnabled: true },
							},
							// Randomly select a groupBy field to test the shadow request
							groupBy: groupByFields[Math.floor(Math.random() * groupByFields.length)],
						}
					: {};

				queueShadowRequest(
					() => getMainIssueNavigatorQueryShadowOperation(true),
					{ ...variables, ...variableOverrides },
					true,
				);
			}
		}
		// We only want to fetch the main query once, so we don't need to add any dependencies here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export const useIssueSearchPaginationShadowRequest = () => {
	return useCallback(
		(variables: IssueNavigatorIssueSearchPaginationQuery$variables) =>
			queueShadowRequest(getIssueNavigatorIssueSearchPaginationQueryShadowOperation, variables),
		[],
	);
};
