import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { selectedIssueStateOld_issueNavigator_SelectedIssueContainer$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/selectedIssueStateOld_issueNavigator_SelectedIssueContainer.graphql';

import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { views } from '../../common/constants.tsx';
import { convertToView, createRememoizeSelector } from '../../common/utils/index.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query/index.tsx';
import { useSelectedViewState } from '../selected-view-state/index.tsx';
import actions, { privateActions } from './actions.tsx';
import {
	type StoreType,
	type ActionType,
	type PropType,
	type PropTypeExternal,
	DEFAULT_FIRST,
	FORCE_FIRST,
	FORCE_LAST,
} from './types.tsx';

const Store = createStore<StoreType, ActionType>({
	initialState: {
		_rememoize: Date.now(),
		searchKey: '',
		selectedIssue: [null, toIssueKey('')],
		focusedIssue: null,
		nextResetStrategy: DEFAULT_FIRST,
		isFullPageIssueAppMode: false,
	},
	actions,
	name: 'IssueNavigatorSelectedIssueState',
});

type SelectionState = 'ISSUE_SELECTED' | 'BLANK_ISSUE_SELECTED' | 'NO_SELECTION';
export const selectionState: {
	[Selection in SelectionState]: SelectionState;
} = {
	ISSUE_SELECTED: 'ISSUE_SELECTED',
	BLANK_ISSUE_SELECTED: 'BLANK_ISSUE_SELECTED',
	NO_SELECTION: 'NO_SELECTION',
};

const getSelectedIndex = (issueKeys: Array<IssueKey>, selectedIssueKey: IssueKey) => {
	const index = selectedIssueKey && issueKeys ? issueKeys.indexOf(selectedIssueKey) : null;
	if (index !== null && index >= 0) {
		// If key exists in result set, set selected index to the corresponding position
		return index;
	}
	// Otherwise, set selected index to null (i.e. no visual selection)
	return null;
};

const Container = createContainer<StoreType, ActionType, PropType>(Store, {
	onInit:
		() =>
		({ dispatch, setState }, { selectedIssueKey, issueKeys, searchKey, view }) => {
			const isFullPageIssueAppMode = Boolean(selectedIssueKey) && view === views.list;
			const initialIssueKey =
				selectedIssueKey || dispatch(privateActions.getDefaultIssueKey()) || toIssueKey('');
			const initialIndex = getSelectedIndex(issueKeys, initialIssueKey);
			setState({
				_rememoize: Date.now(),
				selectedIssue: [initialIndex, initialIssueKey],
				searchKey,
				isFullPageIssueAppMode,
			});
		},

	onUpdate:
		() =>
		(
			{ getState, setState, dispatch },
			{
				selectedIssueKey: nextIssueKey,
				isFetching,
				searchKey,
				firstIssueKeyFromNextPage,
				lastIssueKeyFromPreviousPage,
			},
		) => {
			if (isFetching) {
				return;
			}
			const state = getState();

			// Reset the selected issue whenever our search key has changed
			if (searchKey !== state.searchKey) {
				dispatch(privateActions.resetSelectedIssue(!!nextIssueKey));
				setState({
					searchKey,
				});
			} else if (nextIssueKey) {
				// Call our set action to ensure the selected issue index is up to date.
				dispatch(Store.actions.setSelectedIssueByKey(nextIssueKey, false, false, false));

				// If an issue key has been specified while in list view then enter full page issue app mode.
				if (dispatch(privateActions.isListViewWithoutIssueApp())) {
					dispatch(Store.actions.enterFullPageIssueAppMode());
				}

				// If the issue key to select matches the first issue key from the next page then emit an event to go to
				// the next page.
				if (nextIssueKey === firstIssueKeyFromNextPage) {
					dispatch(Store.actions.selectFirstIssueOnNextPage());
				}
				// If the issue key to select matches the last issue key from the previous page then emit an event to go
				// to the previous page.
				else if (nextIssueKey === lastIssueKeyFromPreviousPage) {
					dispatch(Store.actions.selectLastIssueOnPreviousPage());
				}
			}
			// If nextIssueKey is empty and we're in full page mode with a selected issue then we should exit. This
			// handles the scenario where issue app is opened and the user presses the browser back button while still
			// allowing us to cycle through deleted issues in full page mode.
			else if (dispatch(privateActions.isListViewWithIssueAppAndKey())) {
				dispatch(Store.actions.exitFullPageIssueAppMode());
			}
		},
});

export const SelectedIssueContainer = ({
	selectedIssueKey,
	issueResults,
	...props
}: PropTypeExternal) => {
	const [{ view }] = useSelectedViewState();

	const {
		isFetching,
		searchKey,
		onIssueSearchForPage,
		onIssueSearchForNextPage,
		onIssueSearchForPreviousPage,
	} = useIssueSearchQuery();
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const issueData = useFragment<IssueResultsFragment>(
		graphql`
			fragment selectedIssueStateOld_issueNavigator_SelectedIssueContainer on JiraIssueConnection {
				edges {
					node {
						key
					}
				}
				issueNavigatorPageInfo {
					firstIssuePosition
					lastIssuePosition
					firstIssueKeyFromNextPage
					lastIssueKeyFromPreviousPage
				}
			}
		`,
		issueResults,
	);

	const {
		firstIssuePosition,
		lastIssuePosition,
		firstIssueKeyFromNextPage,
		lastIssueKeyFromPreviousPage,
	} = issueData?.issueNavigatorPageInfo ?? {};

	const issueKeys = useMemo(
		() => issueData?.edges?.map((edge) => toIssueKey(edge?.node?.key ?? '')) ?? [],
		[issueData?.edges],
	);

	return (
		<Container
			isFetching={isFetching}
			searchKey={searchKey}
			selectedIssueKey={selectedIssueKey}
			issueKeys={issueKeys}
			view={convertToView(view)}
			onPageChange={onIssueSearchForPage}
			onNextPage={onIssueSearchForNextPage}
			onPreviousPage={onIssueSearchForPreviousPage}
			firstIssuePosition={firstIssuePosition ?? null}
			lastIssuePosition={lastIssuePosition ?? null}
			firstIssueKeyFromNextPage={firstIssueKeyFromNextPage ?? null}
			lastIssueKeyFromPreviousPage={lastIssueKeyFromPreviousPage ?? null}
			{...props}
		/>
	);
};

// SELECTORS
export const useSelectedIssueKey = createStateHook(Store, {
	selector: ({ selectedIssue }) => selectedIssue[1],
});

export const useSelectedIssueIndex = createStateHook(Store, {
	selector: ({ selectedIssue }) => selectedIssue[0],
});

export const useFocusedIssueIndex = createHook(Store, {
	selector: ({ focusedIssue }) => focusedIssue,
});

export const useSelectedIssueDebouncedState = createStateHook(Store, {
	selector: createRememoizeSelector(
		(state) => state,
		({ selectedIssue }) => selectedIssue[1],
	),
});

export const useSelectedIssueStateOldActions = createActionsHook<StoreType, ActionType>(Store);

export const useIssueSelectionState = createHook<StoreType, ActionType, SelectionState>(Store, {
	selector: ({ selectedIssue }) => {
		if (selectedIssue[0] !== null && selectedIssue[1].length > 0) {
			return selectionState.ISSUE_SELECTED;
		}
		if (selectedIssue[0] !== null && selectedIssue[1].length === 0) {
			return selectionState.BLANK_ISSUE_SELECTED;
		}

		return selectionState.NO_SELECTION;
	},
});

export const useIsForcedSelectionOnReset = createStateHook(Store, {
	selector: ({ nextResetStrategy }) =>
		nextResetStrategy === FORCE_FIRST || nextResetStrategy === FORCE_LAST,
});

export const useIsFullPageIssueAppMode = createStateHook(Store, {
	selector: ({ isFullPageIssueAppMode }) => isFullPageIssueAppMode,
});
