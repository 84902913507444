import React, { useEffect, type ReactNode, type RefObject } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createContainer,
	createStateHook,
	createStore,
	type Action,
} from '@atlassian/react-sweet-state';

/**
 * Height, scroll position and scrolling state of an element.
 */
export interface State {
	height: number;
	width: number;
	scrollHeight: number;
	scrollWidth: number;
	scrollTop: number;
	scrollLeft: number;
	isScrolling: boolean;
	scrollElement: HTMLElement | null;
}

/**
 * Time in milliseconds between scroll events for `isScrolling` to reset to false.
 */
export const SCROLLING_TIMEOUT = 200;

export const ScrollStateContainer = createContainer();

const INITIAL_SCROLL_STATE: State = {
	height: 0,
	width: 0,
	scrollHeight: 0,
	scrollWidth: 0,
	scrollTop: 0,
	scrollLeft: 0,
	isScrolling: false,
	scrollElement: null,
};

const actions = {
	update:
		(newState: Partial<State>): Action<State> =>
		({ setState, getState }) => {
			const state = getState();
			setState({
				...state,
				...newState,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	containedBy: ScrollStateContainer,
	initialState: INITIAL_SCROLL_STATE,
	name: 'ScrollStateManager',
	actions,
});

const useScrollStateActions = createActionsHook(Store);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const useScrollStateSelector = createStateHook(Store, {
	selector: (state, cb: Function) => cb(state),
}) as unknown as <T>(cb: (state: State) => T) => T;

export const useScrollElement = createStateHook(Store, {
	selector: (state) => state.scrollElement,
});

export interface ScrollProviderProps {
	scrollRef: RefObject<HTMLElement>;
	children: ReactNode;
}

const attachResizeObserver = (callback: () => void, targetEl: HTMLElement | Window | null) => {
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	if (!targetEl) return () => {};

	let resizeObserver: ResizeObserver | null = null;
	// SSR fix. Do not instantiate for SSR
	if (typeof ResizeObserver !== 'undefined') {
		resizeObserver = new ResizeObserver(() => callback());
	}

	// When mounting our listener, if its the Window object, treat it as such.
	// This can happen in Storybook but not in any other scenario
	if (targetEl instanceof Window) {
		targetEl.addEventListener('resize', callback);
	} else {
		resizeObserver?.observe(targetEl);
	}
	return () => {
		// Cleanup the resize callback we have attached
		if (targetEl instanceof Window) {
			targetEl.removeEventListener('resize', callback);
		} else {
			resizeObserver?.disconnect();
		}
	};
};

/**
 * Listens for scroll events on the `HTMLElement` `scrollRef.current` and updates a state
 * variable with the scroll state, which is returned.
 */
const useElementScrollState = (scrollRef: { current: HTMLElement | null }) => {
	const { update } = useScrollStateActions();

	useEffect(() => {
		const targetEl = scrollRef.current;
		// The element should always exists as the useEffect triggers after the reference is assigned.
		if (!targetEl) {
			return undefined;
		}

		let clearScrollingTimeoutId: ReturnType<typeof setTimeout>;
		const onScroll = () => {
			update({
				scrollTop: targetEl.scrollTop,
				scrollLeft: targetEl.scrollLeft,
				isScrolling: true,
			});

			clearTimeout(clearScrollingTimeoutId);
			clearScrollingTimeoutId = setTimeout(() => {
				update({
					scrollTop: targetEl.scrollTop,
					scrollLeft: targetEl.scrollLeft,
					isScrolling: false,
				});
			}, SCROLLING_TIMEOUT);
		};

		const onResize = () => {
			update({
				scrollTop: targetEl.scrollTop,
				scrollLeft: targetEl.scrollLeft,
				height: targetEl.clientHeight,
				width: targetEl.clientWidth,
				scrollHeight: targetEl.scrollHeight,
				scrollWidth: targetEl.scrollWidth,
				isScrolling: true,
			});

			clearTimeout(clearScrollingTimeoutId);
			clearScrollingTimeoutId = setTimeout(() => {
				update({
					scrollTop: targetEl.scrollTop,
					scrollLeft: targetEl.scrollLeft,
					isScrolling: false,
				});
			}, SCROLLING_TIMEOUT);
		};

		// We want to know when the scroll element/container itself resizes as this impacts
		// the number of rows we show
		const cleanupResizeObserver = attachResizeObserver(onResize, targetEl);
		targetEl.addEventListener('scroll', onScroll);

		update({
			height: targetEl.clientHeight,
			width: targetEl.clientWidth,
			scrollHeight: targetEl.scrollHeight,
			scrollWidth: targetEl.scrollWidth,
			scrollTop: targetEl.scrollTop,
			scrollLeft: targetEl.scrollLeft,
			isScrolling: false,
			scrollElement: targetEl,
		});

		return () => {
			targetEl.removeEventListener('scroll', onScroll);

			cleanupResizeObserver();

			clearTimeout(clearScrollingTimeoutId);
		};
	}, [scrollRef, update]);
};

const ScrollStateProviderInner = ({ scrollRef, children }: ScrollProviderProps) => {
	useElementScrollState(scrollRef);
	return <>{children}</>;
};

/**
 * Provides a scroll element via context to this sub-tree. This is required by
 * `useVirtual`.
 */
export const ScrollStateProvider = (props: ScrollProviderProps) => {
	return (
		<ScrollStateContainer>
			<ScrollStateProviderInner {...props} />
		</ScrollStateContainer>
	);
};
