import React, { forwardRef, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { CardBaseStyle } from '../../../../../../common/ui/styled.tsx';
import messages from './messages.tsx';

type Props = {
	isSelected?: boolean;
	onClick: (event: MouseEvent) => void;
	onFocus: (() => void) | undefined;
};

const EmptyCard = forwardRef<HTMLAnchorElement, Props>(
	({ isSelected = false, onClick, onFocus }: Props, ref) => {
		const { formatMessage } = useIntl();
		return (
			<Box as="li" xcss={ListItemStyle}>
				<EmptyCardContainer
					tabIndex={isSelected ? 0 : -1}
					ref={ref ?? undefined}
					isSelected={isSelected}
					onClick={onClick}
					href="#"
					onFocus={onFocus}
					visualRefresh={isVisualRefreshEnabled()}
				>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.emptyCardTextIssueTermRefresh
							: messages.emptyCardText,
					)}
					<FireUiAnalytics action="viewed" actionSubject="blankIssue" />
				</EmptyCardContainer>
			</Box>
		);
	},
);

export default EmptyCard;

const textColorActiveState = token('color.text.selected');
const textColor = token('color.text');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyCardContainer = styled(CardBaseStyle)<{ isSelected: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.isSelected ? textColorActiveState : textColor),
	font: token('font.body.small'),
	cursor: 'pointer',
	'&:hover, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) => (props.isSelected ? textColorActiveState : textColor),
	},
});

const ListItemStyle = xcss({
	marginBlockStart: 'space.0',
});
