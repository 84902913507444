import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warning: {
		id: 'issue-navigator.sidebar.experience-registry.error-state.warning-icon-label',
		defaultMessage: 'Warning',
		description:
			'Label for the warning icon that appears when there is an error in the sidebar experience.',
	},
	close: {
		id: 'issue-navigator.sidebar.experience-registry.error-state.close',
		defaultMessage: 'Close',
		description: 'Tells the user what will happen upon clicking the cross icon',
	},
	errorTitle: {
		id: 'issue-navigator.sidebar.experience-registry.error-state.error-title',
		defaultMessage: 'Something went wrong',
		description: 'Title for the error state message',
	},
	errorBody: {
		id: 'issue-navigator.sidebar.experience-registry.error-state.error-body',
		defaultMessage: 'Try refreshing the page, or check your connection.',
		description: 'Description for the error state message',
	},
});
