import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledBox = styled.div<{
	height: number;
	iconSize?: number;
}>(
	{
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: `${(props: any) => props.height}px`,
		justifyContent: 'center',
		marginTop: token('space.200'),
		marginRight: token('space.300'),
		marginBottom: token('space.200'),
		marginLeft: token('space.300'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ iconSize }) =>
		iconSize
			? `svg {
                   width: ${iconSize}px;
                   height: ${iconSize}px;
               }`
			: '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTextComponent = styled.div({
	marginTop: token('space.100'),
});

export const StyledText = (props: ComponentPropsWithoutRef<typeof StyledTextComponent>) => (
	<Heading size="xsmall">
		<StyledTextComponent {...props} />
	</Heading>
);
