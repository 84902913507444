/**
 * @generated SignedSource<<cfdc251a45a6ba9367cdb9286affd185>>
 * @relayHash 4fd5117c0ba4d92cede9b6c1dfa92c6b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e600d24dcb8704bf20d6cb8c9dbf7c82f5fa63b3bd3c36a0f60c0e30c7b4e4ec

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateHideDoneToggleMutation$variables = {
  isHideDoneEnabled: boolean;
  viewId: string;
};
export type updateHideDoneToggleMutation$data = {
  readonly jira: {
    readonly updateIssueSearchHideDonePreference: {
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type updateHideDoneToggleMutation = {
  response: updateHideDoneToggleMutation$data;
  variables: updateHideDoneToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHideDoneEnabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v2 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isHideDoneEnabled",
            "variableName": "isHideDoneEnabled"
          },
          {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
          }
        ],
        "concreteType": "JiraIssueSearchHideDonePreferenceMutationPayload",
        "kind": "LinkedField",
        "name": "updateIssueSearchHideDonePreference",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "updateHideDoneToggleMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateHideDoneToggleMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "e600d24dcb8704bf20d6cb8c9dbf7c82f5fa63b3bd3c36a0f60c0e30c7b4e4ec",
    "metadata": {},
    "name": "updateHideDoneToggleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6002741e1011116c8619fa08aefe226e";

export default node;
